import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "../assets/css/jquery.datetimepicker.css";
import "../assets/css/common.css";
import "../assets/css/animations.css";
import "../assets/css/welcome.css";
import "../assets/css/datetimepicker.css";
import "../assets/css/details.css";
import arrowLeftBlack from "../assets/svg/arrow-left-black.svg";
import mapMarker from "../assets/svg/map-marker.svg";
import timeMarker from "../assets/svg/time-marker.svg";
import starYellow from "../assets/svg/star-yellow.svg";
import heartRedLight from "../assets/svg/heart-black.svg";
// import guide1 from "../assets/images/home/guide-3.png";
// import guide2 from "../assets/images/home/guide-4.png";
// import reviewAvatar from "../assets/images/details/avatar-1.png";
// import mapBlack from "../assets/svg/map-black.svg";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Import Mapbox GL JS CSS

mapboxgl.accessToken =
  "pk.eyJ1IjoicmFtZXhkZWx0YSIsImEiOiJjbGZrNmlzd28wN3pzM3JtamczNTBrcGhuIn0.5rjJ9svPUmRtT7_NTwdUDg";

var avion = [
  {
    Nom: "LA REUNION-Roland Garros",
    Latitude: -20.89,
    Longitude: 55.516389,
    Clubs: [
      {
        "Club Name": "Aéroclub Roland Garros",
        "Club Address":
          "Zone d'Aviation Générale Route de Maperine, 97438 SAINTE MARIE / LA RÉUNION",
        "Tarif passeport 1h30 (€)": 465,
        "Tarif passeport 3h (€)": 915,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=14061",
        "Phone Number": "02.62.53.45.85",
      },
    ],
  },
  {
    Nom: "ST PIERRE PIERREFONDS",
    Latitude: -21.321667,
    Longitude: 55.425556,
    Clubs: [
      {
        "Club Name": "Aéroclub du Sud E. Adam de Villiers",
        "Club Address":
          "Aérodrome de Pierrefonds, 97410 ST PIERRE / LA RÉUNION",
        "Tarif passeport 1h30 (€)": 338,
        "Tarif passeport 3h (€)": 660,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=14062",
        "Phone Number": "02 62 55 78 02",
      },
    ],
  },
  {
    Nom: "LUCON CHASNAIS - Privé",
    Latitude: 46.4625,
    Longitude: -1.238056,
    Clubs: [
      {
        "Club Name": "Aéroclub Luçon-Chasnais",
        "Club Address": "3bis rue de L'Oulerie, 85400 CHASNAIS",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06034",
        "Phone Number": "02 51 97 77 31",
      },
    ],
  },
  {
    Nom: "DIEPPE / St Aubin",
    Latitude: 49.8825,
    Longitude: 1.08527777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Cdt Fayolle - Dieppe",
        "Club Address": "Aérodrome de Dieppe, 76550 OFFRANVILLE",
        "Tarif passeport 1h30 (€)": 303,
        "Tarif passeport 3h (€)": 591,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05014",
        "Phone Number": "02.35.84.86.55",
      },
    ],
  },
  {
    Nom: "CALAIS / DUNKERQUE",
    Latitude: 50.9608333333333,
    Longitude: 1.95138888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub de la Côte d'Opale",
        "Club Address": "Aérogare  Aéroport Calais-Marck, 62730 MARCK",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03048",
        "Phone Number": "03.21.97.40.98",
      },
    ],
  },
  {
    Nom: "PERONNE / St-Quentin",
    Latitude: 49.8688888888889,
    Longitude: 3.02972222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub de Péronne",
        "Club Address":
          "Aérodrome de Peronne Saint Quent Rue La Bosse de Laby, 80200 ESTREES-MONS",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03016",
        "Phone Number": "03 22 85 67 80",
      },
    ],
  },
  {
    Nom: "NANGIS / Les Loges",
    Latitude: 48.5958333333333,
    Longitude: 3.01416666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub Marcel Dassault de Melun Villaroche",
        "Club Address":
          "Aérodrome Nangis les Loges 13 rue de l’Aérodrome, 77720 Grandpuits-Bailly-Carrois",
        "Tarif passeport 1h30 (€)": 225,
        "Tarif passeport 3h (€)": 435,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04152",
        "Phone Number": "01.64.08.30.34",
      },
    ],
  },
  {
    Nom: "ARGENTAN",
    Latitude: 48.7105555555556,
    Longitude: 0.00388888888888889,
    Clubs: [
      {
        "Club Name": "Les Ailes Argentanaises",
        "Club Address": "Hotel de ville, 61200 ARGENTAN",
        "Tarif passeport 1h30 (€)": 240,
        "Tarif passeport 3h (€)": 465,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05036",
        "Phone Number": "02.33.36.78.11",
      },
    ],
  },
  {
    Nom: "BERCK-SUR-MER",
    Latitude: 50.4238888888889,
    Longitude: 1.59333333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub de Berck sur mer",
        "Club Address": "Aérodrome de Berck D 940, 62600 BERCK sur MER",
        "Tarif passeport 1h30 (€)": 227,
        "Tarif passeport 3h (€)": 438,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03041",
        "Phone Number": null,
      },
    ],
  },
  {
    Nom: "ALBERT / Bray",
    Latitude: 49.9725,
    Longitude: 2.69138888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Albert Meaulte",
        "Club Address": "Hôtel de Ville, 80300 ALBERT",
        "Tarif passeport 1h30 (€)": 236,
        "Tarif passeport 3h (€)": 456,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03013",
        "Phone Number": "03.22.75.34.24",
      },
    ],
  },
  {
    Nom: "FALAISE / Mont-d'Eraines",
    Latitude: 48.9272222222222,
    Longitude: -0.144722222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub de Falaise",
        "Club Address": "Hôtel de ville, 14700 FALAISE",
        "Tarif passeport 1h30 (€)": 233,
        "Tarif passeport 3h (€)": 450,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05025",
        "Phone Number": "02.31.90.06.54",
      },
    ],
  },
  {
    Nom: "AGEN-La-Garenne",
    Latitude: 44.1747222222222,
    Longitude: 0.590555555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub de l'Agenais",
        "Club Address": "Aérodrome Agen la Garenne, 47520 LE PASSAGE",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08050",
        "Phone Number": "05.53.96.36.67",
      },
    ],
  },
  {
    Nom: "BERGERAC / Roumanière",
    Latitude: 44.8244444444444,
    Longitude: 0.520555555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub de Bergerac",
        "Club Address": "Aérodrome de Roumanières, 24100 BERGERAC",
        "Tarif passeport 1h30 (€)": 309,
        "Tarif passeport 3h (€)": 603,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08014",
        "Phone Number": "05.53.57.31.36",
      },
    ],
  },
  {
    Nom: "COGNAC / ChateauBernard",
    Latitude: 45.6583333333333,
    Longitude: -0.3175,
    Clubs: [
      {
        "Club Name": "Les Ailes Cognaçaises - Vol moteur",
        "Club Address":
          "BP 30091 Route de Segonzac - Le parveau, 16103 COGNAC Cedex",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12030",
        "Phone Number": "05.45.82.13.51",
      },
    ],
  },
  {
    Nom: "LA ROCHELLE / Ile de Ré",
    Latitude: 46.1791666666667,
    Longitude: -1.19527777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub de la Rochelle et Charente-Maritime",
        "Club Address":
          "Aérodrome de La Rochelle Ile de Ré - Rue du Jura, 17000 LA ROCHELLE",
        "Tarif passeport 1h30 (€)": 302,
        "Tarif passeport 3h (€)": 588,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12037",
        "Phone Number": "05.46.42.54.74",
      },
    ],
  },
  {
    Nom: "POITIERS / Biard",
    Latitude: 46.5875,
    Longitude: 0.306666666666667,
    Clubs: [
      {
        "Club Name": "ASPTT de Poitiers section vol moteur",
        "Club Address": "83 avenue de Nantes, 86000 POITIERS",
        "Tarif passeport 1h30 (€)": 225,
        "Tarif passeport 3h (€)": 435,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12051",
        "Phone Number": "05.49.58.47.10",
      },
    ],
  },
  {
    Nom: "NIORT / Souché",
    Latitude: 46.3133333333333,
    Longitude: -0.394444444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub des Deux Sèvres",
        "Club Address":
          "Aérodrome Niort Marais Poitevin 578, avenue de Limoges, 79000 NIORT",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12042",
        "Phone Number": null,
      },
    ],
  },
  {
    Nom: "PAU-PYRÉNÉES",
    Latitude: 43.38,
    Longitude: -0.418611111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub du Béarn",
        "Club Address": "3, rue Saint Exupéry, 64230 SAUVAGNON",
        "Tarif passeport 1h30 (€)": 299,
        "Tarif passeport 3h (€)": 582,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08013",
        "Phone Number": "05.59.33.22.00",
      },
    ],
  },
  {
    Nom: "BISCAROSSE / Parentis",
    Latitude: 44.3694444444444,
    Longitude: -1.13055555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub de Biscarrosse des personnels de l'ENAC",
        "Club Address": "150 rue Jean Chasac, 40600 BISCARROSSE",
        "Tarif passeport 1h30 (€)": 231,
        "Tarif passeport 3h (€)": 447,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08085",
        "Phone Number": "06 68 13 28 74",
      },
    ],
  },
  {
    Nom: "TARBES-LOURDES-PYRENÉES",
    Latitude: 43.1855555555556,
    Longitude: -0.00277777777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Léon Morane",
        "Club Address": "route de l’aéroport, 65290 JUILLAN",
        "Tarif passeport 1h30 (€)": 201,
        "Tarif passeport 3h (€)": 387,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09014",
        "Phone Number": "05.62.32.90.36",
      },
    ],
  },
  {
    Nom: "ANGOULÊME / Brie-Champniers",
    Latitude: 45.7294444444444,
    Longitude: 0.219166666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Angoulême",
        "Club Address": "Aéroport Rue Jean Mermoz, 16430 CHAMPNIERS",
        "Tarif passeport 1h30 (€)": 240,
        "Tarif passeport 3h (€)": 465,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12032",
        "Phone Number": "05.45.69.88.22",
      },
    ],
  },
  {
    Nom: "CHATELLERAULT / Targé",
    Latitude: 46.7813888888889,
    Longitude: 0.551944444444444,
    Clubs: [
      {
        "Club Name": "Les Ailes Châtelleraudaises",
        "Club Address": "Aérodrome de Chatellerault, 86100 CHATELLERAULT",
        "Tarif passeport 1h30 (€)": 204,
        "Tarif passeport 3h (€)": 393,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12002",
        "Phone Number": "05.49.21.00.82",
      },
    ],
  },
  {
    Nom: "FIGEAC / Livernon",
    Latitude: 44.6733333333333,
    Longitude: 1.78916666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub de Figeac Livernon",
        "Club Address": "Aérodrome Figeac Livernon, 46320 DURBANS",
        "Tarif passeport 1h30 (€)": 213,
        "Tarif passeport 3h (€)": 411,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09049",
        "Phone Number": "05.65.40.57.04",
      },
    ],
  },
  {
    Nom: "ALBI / Le Séquestre",
    Latitude: 43.9133333333333,
    Longitude: 2.11666666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Albi",
        "Club Address": "Aérodrome d'Albi le Séquestre, 81990 LE SEQUESTRE",
        "Tarif passeport 1h30 (€)": 231,
        "Tarif passeport 3h (€)": 447,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09002",
        "Phone Number": "07-81-77-01-36",
      },
    ],
  },
  {
    Nom: "JONZAC / Neulles",
    Latitude: 45.4841666666667,
    Longitude: -0.421388888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub de Jonzac en Haute Saintonge",
        "Club Address": "Aérodrome de Jonzac, 17500 ST GERMAIN DE LUZIGNAN",
        "Tarif passeport 1h30 (€)": 276,
        "Tarif passeport 3h (€)": 537,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12058",
        "Phone Number": "06 26 44 29 27",
      },
    ],
  },
  {
    Nom: "MILLAU / Larzac",
    Latitude: 43.9891666666667,
    Longitude: 3.18333333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub Millau-Larzac",
        "Club Address": "Aérodrome de Millau Larzac, 12230 LA CAVALERIE",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09042",
        "Phone Number": "05.65.62.72.91",
      },
    ],
  },
  {
    Nom: "NOGARO",
    Latitude: 43.7697222222222,
    Longitude: -0.0327777777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub du Bas Armagnac",
        "Club Address": "Aérodrome, 32110 NOGARO",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09008",
        "Phone Number": "05.62.08.80.82",
      },
    ],
  },
  {
    Nom: "OLORON / Herrère",
    Latitude: 43.1647222222222,
    Longitude: -0.560277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Oloron Ste Marie",
        "Club Address": "Route de l'Aérodrome, 64680 HERRERE",
        "Tarif passeport 1h30 (€)": 240,
        "Tarif passeport 3h (€)": 465,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08034",
        "Phone Number": "06.03.21.86.71",
      },
    ],
  },
  {
    Nom: "GRAULHET / Montdragon",
    Latitude: 43.77,
    Longitude: 2.00888888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub de Graulhet",
        "Club Address": "L' Aérodrome, 81300 GRAULHET",
        "Tarif passeport 1h30 (€)": 165,
        "Tarif passeport 3h (€)": 315,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09017",
        "Phone Number": "05.63.34.51.07",
      },
    ],
  },
  {
    Nom: "BORDEAUX / Léognan-Saucats",
    Latitude: 44.7002777777778,
    Longitude: -0.595555555555556,
    Clubs: [
      {
        "Club Name": "Dassault Aéroclub Aquitaine",
        "Club Address":
          "Aérodrome de Leognan-Saucat 303 avenue de Mont de Marsan, 33850  LEOGNAN",
        "Tarif passeport 1h30 (€)": 243,
        "Tarif passeport 3h (€)": 471,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08030",
        "Phone Number": "06 74 65 21 94",
      },
    ],
  },
  {
    Nom: "EGLETONS",
    Latitude: 45.4213888888889,
    Longitude: 2.06888888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub Egletonnais",
        "Club Address": "Mairie, 19300 EGLETONS",
        "Tarif passeport 1h30 (€)": 284,
        "Tarif passeport 3h (€)": 552,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12011",
        "Phone Number": "05.55.93.09.50",
      },
    ],
  },
  {
    Nom: "SAINTE-FOY-La-Grande",
    Latitude: 44.8536111111111,
    Longitude: 0.176666666666667,
    Clubs: [
      {
        "Club Name": "Cercle Aéronautique Foyen",
        "Club Address": "Aérodrome Fougueyrolles, 33220 SAINTE FOY LA GRANDE",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08016",
        "Phone Number": "06 75 39 40 74",
      },
    ],
  },
  {
    Nom: "LOUDUN",
    Latitude: 47.0372222222222,
    Longitude: 0.101388888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub Loudunais",
        "Club Address": "Aérodrome de Véniers »bellevue », 86200 LOUDUN",
        "Tarif passeport 1h30 (€)": 225,
        "Tarif passeport 3h (€)": 435,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12015",
        "Phone Number": "07 66 57 42 61",
      },
    ],
  },
  {
    Nom: "SARLAT / Domme",
    Latitude: 44.7933333333333,
    Longitude: 1.24472222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub du Sarladais",
        "Club Address": "Aérodrome Sarlat Domme, 24250 DOMME",
        "Tarif passeport 1h30 (€)": 270,
        "Tarif passeport 3h (€)": 525,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08048",
        "Phone Number": null,
      },
    ],
  },
  {
    Nom: "TARBES / Laloubère",
    Latitude: 43.2161111111111,
    Longitude: 0.0786111111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub de Bigorre",
        "Club Address": "Aérodrome, 65310 LALOUBERE",
        "Tarif passeport 1h30 (€)": 225,
        "Tarif passeport 3h (€)": 435,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09009",
        "Phone Number": "05.62.93.22.16",
      },
    ],
  },
  {
    Nom: "LESPARRE / Saint-Laurent-Médoc",
    Latitude: 45.1977777777778,
    Longitude: -0.882222222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub du Médoc",
        "Club Address": "4 route de Semignan, 33112 ST LAURENT MEDOC",
        "Tarif passeport 1h30 (€)": 291,
        "Tarif passeport 3h (€)": 567,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08031",
        "Phone Number": "05.56.59.42.25",
      },
    ],
  },
  {
    Nom: "CHAUVIGNY",
    Latitude: 46.5836111111111,
    Longitude: 0.6425,
    Clubs: [
      {
        "Club Name": "Aéroclub de Chauvigny",
        "Club Address": "Hotel de Ville, 86300 CHAUVIGNY",
        "Tarif passeport 1h30 (€)": 195,
        "Tarif passeport 3h (€)": 375,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12008",
        "Phone Number": "05.49.46.31.48",
      },
    ],
  },
  {
    Nom: "BORDEAUX / Yvrac",
    Latitude: 44.8772222222222,
    Longitude: -0.479166666666667,
    Clubs: [
      {
        "Club Name": "Bordeaux Yvrac Aéroclub",
        "Club Address": "3 chemin de Mirefleur, 33370 YVRAC",
        "Tarif passeport 1h30 (€)": 254,
        "Tarif passeport 3h (€)": 492,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08015",
        "Phone Number": "05.56.06.68.14",
      },
    ],
  },
  {
    Nom: "AMBOISE / Dierre",
    Latitude: 47.3413888888889,
    Longitude: 0.9425,
    Clubs: [
      {
        "Club Name": "Les Ailes Tourangelles",
        "Club Address": "Aérodrome de Dierre, 37150 DIERRE",
        "Tarif passeport 1h30 (€)": 237,
        "Tarif passeport 3h (€)": 459,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07004",
        "Phone Number": "07.83.62.24.02",
      },
    ],
  },
  {
    Nom: "BRIARE / Châtillon",
    Latitude: 47.6144444444444,
    Longitude: 2.78194444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub du Giennois",
        "Club Address": "Aérodrome Briare Chatillon, 45250 BRIARE",
        "Tarif passeport 1h30 (€)": 275,
        "Tarif passeport 3h (€)": 534,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07008",
        "Phone Number": "02.38.31.18.70",
      },
    ],
  },
  {
    Nom: "MONTARGIS / Vimory",
    Latitude: 47.9605555555556,
    Longitude: 2.68583333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub du Gatinais",
        "Club Address": "11, rue de l’Aérodrome, 45700 VIMORY",
        "Tarif passeport 1h30 (€)": 270,
        "Tarif passeport 3h (€)": 525,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07002",
        "Phone Number": "02.38.85.03.90",
      },
    ],
  },
  {
    Nom: "POUILLY / Maconge",
    Latitude: 47.2213888888889,
    Longitude: 4.56111111111111,
    Clubs: [
      {
        "Club Name": "Les Ailes de Pouilly Maconge",
        "Club Address":
          "Aérodrome de Pouilly Maconge, 21320 MEILLY SUR ROUVRES",
        "Tarif passeport 1h30 (€)": 272,
        "Tarif passeport 3h (€)": 528,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02034",
        "Phone Number": null,
      },
    ],
  },
  {
    Nom: "QUIBERON",
    Latitude: 47.4822222222222,
    Longitude: -3.1,
    Clubs: [
      {
        "Club Name": "Quiberon Air Club",
        "Club Address": "rue de l'aérodrome, 56170 QUIBERON",
        "Tarif passeport 1h30 (€)": 225,
        "Tarif passeport 3h (€)": 435,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06019",
        "Phone Number": "02.97.50.11.05",
      },
    ],
  },
  {
    Nom: "BAR-LE-DUC",
    Latitude: 48.8683333333333,
    Longitude: 5.18583333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub du Sud-Meusien",
        "Club Address": "BP 10184, 55005 BAR LE DUC CEDEX",
        "Tarif passeport 1h30 (€)": 297,
        "Tarif passeport 3h (€)": 579,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01048",
        "Phone Number": "10184\n5500",
      },
    ],
  },
  {
    Nom: "GRAY / St-Adrien",
    Latitude: 47.4333333333333,
    Longitude: 5.62277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub de Gray et de sa Région",
        "Club Address": "Aérodrome de GRAY ST ADRIEN, 70100 GRAY",
        "Tarif passeport 1h30 (€)": 254,
        "Tarif passeport 3h (€)": 492,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02014",
        "Phone Number": "03.84.65.00.84",
      },
    ],
  },
  {
    Nom: "ENGHIEN / Moisselles",
    Latitude: 49.0463888888889,
    Longitude: 2.35305555555556,
    Clubs: [
      {
        "Club Name": "Les Ailerons d'Enghien - Moisselles",
        "Club Address": "Aérodrome Rue du Moutier, 95570 MOISSELLES",
        "Tarif passeport 1h30 (€)": 230,
        "Tarif passeport 3h (€)": 444,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04006",
        "Phone Number": "01.39.91.05.60",
      },
    ],
  },
  {
    Nom: "BRIENNE-LE-CHATEAU",
    Latitude: 48.4308333333333,
    Longitude: 4.4825,
    Clubs: [
      {
        "Club Name": "Association Aéronautique Briennoise",
        "Club Address":
          "Aérodrome Brienne Le Château ZI St Christophe, 10500 BRIENNE LE CHÂTEAU",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01057",
        "Phone Number": "06.14.67.60.50",
      },
    ],
  },
  {
    Nom: "BEAUVOIR - Fromentine",
    Latitude: 46.89,
    Longitude: -2.087,
    Clubs: [
      {
        "Club Name": "Aéroclub Beauvoir-Fromentine",
        "Club Address":
          "Aérodrome de Beauvoir-Fromentine Route départementale 22, 85230 BEAUVOIR SUR MER",
        "Tarif passeport 1h30 (€)": 270,
        "Tarif passeport 3h (€)": 525,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06058",
        "Phone Number": "06 22 43 38 51",
      },
    ],
  },
  {
    Nom: "CHATEAUNEUF-SUR-CHER",
    Latitude: 46.8711111111111,
    Longitude: 2.37694444444444,
    Clubs: [
      {
        "Club Name": "Union Aérienne de Châteauneuf sur Cher",
        "Club Address": "Hotel de Ville, 18190 CHATEAUNEUF SUR CHER",
        "Tarif passeport 1h30 (€)": 239,
        "Tarif passeport 3h (€)": 462,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07019",
        "Phone Number": "02.36.24.56.30",
      },
    ],
  },
  {
    Nom: "COLMAR / Houssen",
    Latitude: 48.1102777777778,
    Longitude: 7.35916666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub de Colmar ",
        "Club Address": "Aérodrome Colmar Houssen, 68000 COLMAR",
        "Tarif passeport 1h30 (€)": 291,
        "Tarif passeport 3h (€)": 567,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01013",
        "Phone Number": "0962160167",
      },
    ],
  },
  {
    Nom: "MULHOUSE / Habsheim",
    Latitude: 47.7380555555556,
    Longitude: 7.43222222222222,
    Clubs: [
      {
        "Club Name": "Air Alsace",
        "Club Address":
          "26 RUE DE L AERODROME AERODROME DE HABSHEIM, 68170 RIXHEIM",
        "Tarif passeport 1h30 (€)": 240,
        "Tarif passeport 3h (€)": 465,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01079",
        "Phone Number": "06 20 53 13 71",
      },
    ],
  },
  {
    Nom: "BEAUNE / Challanges",
    Latitude: 47.0111111111111,
    Longitude: 4.8975,
    Clubs: [
      {
        "Club Name": "Aéroclub Beaunois",
        "Club Address": "Aérodrome Route de Seurre, 21200 BEAUNE",
        "Tarif passeport 1h30 (€)": 297,
        "Tarif passeport 3h (€)": 579,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02006",
        "Phone Number": "03.80.22.21.93",
      },
    ],
  },
  {
    Nom: "BELFORT / Chaux",
    Latitude: 47.7022222222222,
    Longitude: 6.8325,
    Clubs: [
      {
        "Club Name": "Association Belfortaine de Vol Moteur",
        "Club Address": "52 Grande Rue, 90300 SERMAMAGNY",
        "Tarif passeport 1h30 (€)": 272,
        "Tarif passeport 3h (€)": 528,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02007",
        "Phone Number": "03.84.29.21.44",
      },
    ],
  },
  {
    Nom: "DIJON / Darois",
    Latitude: 47.3869444444444,
    Longitude: 4.94805555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub de la Côte d'Or",
        "Club Address":
          "Aérodrome de Dijon Darois 2 route de Troyes, 21121 DAROIS",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02012",
        "Phone Number": "03.80.35.61.09",
      },
    ],
  },
  {
    Nom: "JOIGNY",
    Latitude: 47.995,
    Longitude: 3.39194444444444,
    Clubs: [
      {
        "Club Name": "Les Ailes Joviniennes",
        "Club Address":
          "Aérodrome de Joigny Route de Dixmont  B. P. 34, 89301 JOIGNY CEDEX",
        "Tarif passeport 1h30 (€)": 315,
        "Tarif passeport 3h (€)": 615,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02002",
        "Phone Number": "03.86.62.11.82",
      },
    ],
  },
  {
    Nom: "PONT-SUR-YONNE",
    Latitude: 48.2905555555556,
    Longitude: 3.25083333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub de Sens",
        "Club Address":
          "Aérodrome de Gisy Les Nobles 3 rue de l'aérodrome, 89140 GISY LES NOBLES",
        "Tarif passeport 1h30 (€)": 287,
        "Tarif passeport 3h (€)": 558,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02035",
        "Phone Number": "03.86.67.04.73",
      },
    ],
  },
  {
    Nom: "SEMUR-EN-AUXOIS",
    Latitude: 47.4819444444444,
    Longitude: 4.34416666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub de Semur en Auxois",
        "Club Address": "avenue Jean Mermoz, 21140 SEMUR-EN-AUXOIS",
        "Tarif passeport 1h30 (€)": 233,
        "Tarif passeport 3h (€)": 450,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02025",
        "Phone Number": "03.80.97.12.04",
      },
    ],
  },
  {
    Nom: "DONCOURT LES-CONFLANS",
    Latitude: 49.1527777777778,
    Longitude: 5.93277777777778,
    Clubs: [
      {
        "Club Name": "Centre d'Aviation du Bassin de Briey",
        "Club Address": "Aérodrome, 54800 DONCOURT LES CONFLANS",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01009",
        "Phone Number": "06 16 91 14 20",
      },
    ],
  },
  {
    Nom: "SARREBOURG / Buhl",
    Latitude: 48.7188888888889,
    Longitude: 7.07944444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub Région de Sarrebourg",
        "Club Address": "1 rue du Moulin, 57400 BUHL",
        "Tarif passeport 1h30 (€)": 323,
        "Tarif passeport 3h (€)": 630,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01036",
        "Phone Number": "03.87.07.10.27",
      },
    ],
  },
  {
    Nom: "NUITS-SAINT-GEORGES",
    Latitude: 47.1430555555556,
    Longitude: 4.96916666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub de Nuits St Georges",
        "Club Address": "Route de Boncourt, 21700 NUITS ST GEORGES",
        "Tarif passeport 1h30 (€)": 245,
        "Tarif passeport 3h (€)": 474,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02018",
        "Phone Number": "03.80.61.05.16",
      },
    ],
  },
  {
    Nom: "PEROUGES / Meximieux",
    Latitude: 45.8697222222222,
    Longitude: 5.18722222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub de Perouges Plaine de l'Ain \"Robert et He",
        "Club Address": "194 chemin de l’aérodrome, 01800 PEROUGES",
        "Tarif passeport 1h30 (€)": 308,
        "Tarif passeport 3h (€)": 600,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11013",
        "Phone Number": "06 29 50 83 55",
      },
    ],
  },
  {
    Nom: "PIERRELATTE",
    Latitude: 44.3988888888889,
    Longitude: 4.71805555555556,
    Clubs: [
      {
        "Club Name": "Les Ailes Tricastines",
        "Club Address": "505 Chemin du Bisolet, 26700 PIERRELATTE",
        "Tarif passeport 1h30 (€)": 249,
        "Tarif passeport 3h (€)": 483,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11094",
        "Phone Number": "04 75 04 01 49",
      },
    ],
  },
  {
    Nom: "BRIOUDE / Beaumont",
    Latitude: 45.325,
    Longitude: 3.35916666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub de Brioude",
        "Club Address":
          "Aérodrome de Brioude Beaumont Chomaget, 43100 BEAUMONT",
        "Tarif passeport 1h30 (€)": 270,
        "Tarif passeport 3h (€)": 525,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=13007",
        "Phone Number": "04.71.50.13.53",
      },
    ],
  },
  {
    Nom: "AMBERT / Le Poyet",
    Latitude: 45.5169444444444,
    Longitude: 3.74638888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub du Livradois-Forez",
        "Club Address": "2553 route de l’aérodrome, 63600 AMBERT",
        "Tarif passeport 1h30 (€)": 330,
        "Tarif passeport 3h (€)": 645,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=13013",
        "Phone Number": "04.73.82.01.64",
      },
    ],
  },
  {
    Nom: "VILLEFRANCHE / Tarare",
    Latitude: 45.92,
    Longitude: 4.635,
    Clubs: [
      {
        "Club Name": "Aéroclub du Beaujolais",
        "Club Address": "Aérodrome de Villefranche Tarare, 69620 FRONTENAS",
        "Tarif passeport 1h30 (€)": 366,
        "Tarif passeport 3h (€)": 717,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11037",
        "Phone Number": "04.74.71.66.44",
      },
    ],
  },
  {
    Nom: "RIBERAC / Saint-Aulaye",
    Latitude: 45.2402777777778,
    Longitude: 0.266944444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub Jean Mermoz",
        "Club Address": "Tourette, 24600 VANXHAINS",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=08026",
        "Phone Number": "05.53.90.10.41",
      },
    ],
  },
  {
    Nom: "SAINT-GAUDENS / Montréjeau",
    Latitude: 43.1086111111111,
    Longitude: 0.620277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub du Comminges",
        "Club Address": "Mairie, 31800 SAINT GAUDENS",
        "Tarif passeport 1h30 (€)": 210,
        "Tarif passeport 3h (€)": 405,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09015",
        "Phone Number": "05.61.89.13.08",
      },
    ],
  },
  {
    Nom: "PEYRESOURDE-BALESTAS",
    Latitude: 42.7969444444444,
    Longitude: 0.435555555555556,
    Clubs: [
      {
        "Club Name": "Peyragudes Air Club",
        "Club Address": "Altiport de Peyresourdes Balesta, 65240 LOUDERVIELLE",
        "Tarif passeport 1h30 (€)": 287,
        "Tarif passeport 3h (€)": 558,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09063",
        "Phone Number": "06.80.75.73.56",
      },
    ],
  },
  {
    Nom: "REVEL / Montgey",
    Latitude: 43.4813888888889,
    Longitude: 1.98,
    Clubs: [
      {
        "Club Name": "Aéroclub de Revel",
        "Club Address": "Aérodrome, 31250 REVEL",
        "Tarif passeport 1h30 (€)": 158,
        "Tarif passeport 3h (€)": 300,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09027",
        "Phone Number": "05.61.83.53.73",
      },
    ],
  },
  {
    Nom: "SAINT INGLEVERT / Les deux Cap",
    Latitude: 50.8825133,
    Longitude: 1.7314873,
    Clubs: [
      {
        "Club Name": "Aéroclub du Boulonnais",
        "Club Address": "49 route du Camp d'Aviation, 62250 SAINT INGLEVERT",
        "Tarif passeport 1h30 (€)": 248,
        "Tarif passeport 3h (€)": 480,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03004",
        "Phone Number": "03.21.33.74.91",
      },
    ],
  },
  {
    Nom: "SAINT-JEAN-D'ANGÉLY",
    Latitude: 45.9663888888889,
    Longitude: -0.525277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Angérien",
        "Club Address": "28 Les grandes béguines, 17400 ST JEAN D'ANGELY",
        "Tarif passeport 1h30 (€)": 267,
        "Tarif passeport 3h (€)": 519,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12034",
        "Phone Number": "05.46.32.19.38",
      },
    ],
  },
  {
    Nom: "CHAMBÉRY / Aix-Les-Bains",
    Latitude: 45.6391666666667,
    Longitude: 5.88,
    Clubs: [
      {
        "Club Name": "Aéroclub de Savoie",
        "Club Address": "Aéroport de Chambéry-Aix, 73420 VIVIERS DU LAC",
        "Tarif passeport 1h30 (€)": 345,
        "Tarif passeport 3h (€)": 675,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11032",
        "Phone Number": "04.79.54.41.81",
      },
    ],
  },
  {
    Nom: "CLERMONT FERRAND-AUVERGNE",
    Latitude: 45.7858333333333,
    Longitude: 3.1625,
    Clubs: [
      {
        "Club Name": 'Aéroclub d\'Auvergne "Gilbert Sardier"',
        "Club Address": "Aéroport Clermont-Ferrand- Auvergne, 63510 AULNAT",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=13006",
        "Phone Number": "04.73.92.00.56",
      },
    ],
  },
  {
    Nom: "OYONNAX / Arbent",
    Latitude: 46.2791666666667,
    Longitude: 5.6675,
    Clubs: [
      {
        "Club Name": "Aéroclub Jean Coutty",
        "Club Address": "Aérodrome, 01100 OYONNAX",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11019",
        "Phone Number": "04.74.77.21.00",
      },
    ],
  },
  {
    Nom: "MACON / Charnay",
    Latitude: 46.2958333333333,
    Longitude: 4.79583333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub du Maconnais Louis Berrard",
        "Club Address": "Hôtel de Ville, 71000 MACON",
        "Tarif passeport 1h30 (€)": 249,
        "Tarif passeport 3h (€)": 483,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02039",
        "Phone Number": "03.85.34.18.54",
      },
    ],
  },
  {
    Nom: "SAINT-YAN",
    Latitude: 46.4063888888889,
    Longitude: 4.02111111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub Louis Notteghem St Yan",
        "Club Address": "Aérodrome, 71600 SAINT YAN",
        "Tarif passeport 1h30 (€)": 257,
        "Tarif passeport 3h (€)": 498,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02020",
        "Phone Number": "03 85 84 94 37",
      },
    ],
  },
  {
    Nom: "ANNECY / Meythet",
    Latitude: 45.9308333333333,
    Longitude: 6.10638888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Annecy Haute Savoie",
        "Club Address": ", ",
        "Tarif passeport 1h30 (€)": 321,
        "Tarif passeport 3h (€)": 627,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11018",
        "Phone Number": "04.50.22.04.25",
      },
    ],
  },
  {
    Nom: "MONTELIMAR / Ancône",
    Latitude: 44.5836111111111,
    Longitude: 4.74055555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub de Montélimar Porte de Provence",
        "Club Address": "ACMPP  Chemin de l'aérodrome, 26200 MONTELIMAR",
        "Tarif passeport 1h30 (€)": 330,
        "Tarif passeport 3h (€)": 645,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11090",
        "Phone Number": "06 16 79 92 71",
      },
    ],
  },
  {
    Nom: "GRENOBLE / Saint-Geoirs",
    Latitude: 45.3630555555556,
    Longitude: 5.33277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub de Grenoble Isère et sa Région - AGIR",
        "Club Address": "28 rue Léon Perrier, 38500 VOIRON",
        "Tarif passeport 1h30 (€)": 293,
        "Tarif passeport 3h (€)": 570,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11092",
        "Phone Number": "07 86 51 39 83",
      },
    ],
  },
  {
    Nom: "AURILLAC",
    Latitude: 44.8975,
    Longitude: 2.41666666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub du Cantal",
        "Club Address": "Aérodrome de Tronquières, 15000 AURILLAC",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=13008",
        "Phone Number": "04.71.64.22.00",
      },
    ],
  },
  {
    Nom: "FEURS / Chambéon",
    Latitude: 45.7036111111111,
    Longitude: 4.20111111111111,
    Clubs: [
      {
        "Club Name": "Air Club du Forez",
        "Club Address": "Aérodrome Feurs Chambeon, 42110 FEURS",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11055",
        "Phone Number": "04.77.27.81.25",
      },
    ],
  },
  {
    Nom: "AIX / Les-Milles",
    Latitude: 43.5016666666667,
    Longitude: 5.37083333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub du Soleil XIII",
        "Club Address": "815 chemin de la Badesse, 13290 AIX LES MILLES",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10107",
        "Phone Number": "04.42.24.34.19",
      },
    ],
  },
  {
    Nom: "CANNES / Mandelieu",
    Latitude: 43.5463888888889,
    Longitude: 6.95416666666667,
    Clubs: [
      {
        "Club Name": "Union Aéronautique de la Côte d'Azur - UACA",
        "Club Address": "Aéroport Cannes Mandelieu, 06150 CANNES LA BOCCA",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10010",
        "Phone Number": "04.93.47.20.27",
      },
    ],
  },
  {
    Nom: "NÎMES / Courbessac",
    Latitude: 43.8538888888889,
    Longitude: 4.41361111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub du Gard",
        "Club Address": "70 RUE DE L EPI, 34130 VALERGUES",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10018",
        "Phone Number": "04.66.26.19.63",
      },
    ],
  },
  {
    Nom: "SAINT ETIENNE / Bouthéon",
    Latitude: 45.5341666666667,
    Longitude: 4.29722222222222,
    Clubs: [
      {
        "Club Name": "Les Ailes Foréziennes",
        "Club Address": "Aérodrome de St-Etienne, 42160 ANDREZIEUX BOUTHEON",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11050",
        "Phone Number": "04.77.36.56.09",
      },
    ],
  },
  {
    Nom: "CARCASSONNE / Salvaza",
    Latitude: 43.2158333333333,
    Longitude: 2.30861111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub de Pilotes Audois",
        "Club Address":
          "Aéorport de Carcassonne Salvaza Hangar n°1, 11000 CARCASSONNE",
        "Tarif passeport 1h30 (€)": 266,
        "Tarif passeport 3h (€)": 516,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10095",
        "Phone Number": "04.68.71.38.78",
      },
    ],
  },
  {
    Nom: "MARSEILLE-PROVENCE",
    Latitude: 43.4366666666667,
    Longitude: 5.215,
    Clubs: [
      {
        "Club Name": "Aéroclub Marseille Provence",
        "Club Address":
          "Aviation Générale Hall 4 Aéroport Marseille Provence, 13700 MARIGNANE",
        "Tarif passeport 1h30 (€)": 339,
        "Tarif passeport 3h (€)": 663,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10002",
        "Phone Number": "07 66 87 24 95",
      },
    ],
  },
  {
    Nom: "AVIGNON / Caumont",
    Latitude: 43.9066666666667,
    Longitude: 4.90194444444445,
    Clubs: [
      {
        "Club Name": "Aéroclub Vauclusien",
        "Club Address": "170, Impasse Roland Garros, 84140 MONTFAVET",
        "Tarif passeport 1h30 (€)": 248,
        "Tarif passeport 3h (€)": 480,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10040",
        "Phone Number": "04.90.84.17.17",
      },
    ],
  },
  {
    Nom: "LEZIGNAN / Corbières",
    Latitude: 43.1758333333333,
    Longitude: 2.73361111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub de Lézignan",
        "Club Address":
          "Route de Ferrals Aérodrome, 11200 LEZIGNAN - CORBIERES",
        "Tarif passeport 1h30 (€)": 266,
        "Tarif passeport 3h (€)": 516,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10110",
        "Phone Number": "06.98.77.76.84",
      },
    ],
  },
  {
    Nom: "MONTPELLIER / Candillargues",
    Latitude: 43.6102777777778,
    Longitude: 4.07027777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub de Camargue",
        "Club Address": "Aérodrome Montpellier l'Or, 34130 CANDILLARGUES",
        "Tarif passeport 1h30 (€)": 266,
        "Tarif passeport 3h (€)": 516,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10091",
        "Phone Number": "04.67.29.57.60",
      },
    ],
  },
  {
    Nom: "UZES",
    Latitude: 44.0861111111111,
    Longitude: 4.39444444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Uzes",
        "Club Address": "Aérodrome de Uzés Camp Roux, 30700 UZES",
        "Tarif passeport 1h30 (€)": 209,
        "Tarif passeport 3h (€)": 402,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10037",
        "Phone Number": "07 83 03 84 96",
      },
    ],
  },
  {
    Nom: "BEAUVAIS / Tillé",
    Latitude: 49.4544444444444,
    Longitude: 2.11277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Beauvais-Tillé",
        "Club Address":
          "AVION PASSION Zone AEROPLAISANCE Aéroport, 60000 BEAUVAIS",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03038",
        "Phone Number": "03.44.48.28.52",
      },
    ],
  },
  {
    Nom: "SAUMUR / Saint-Florent",
    Latitude: 47.2566666666667,
    Longitude: -0.113611111111111,
    Clubs: [
      {
        "Club Name": "Saumur Air Club",
        "Club Address":
          "Aérodrome Rte Marson-St Hilaire St Florent, 49400 SAUMUR",
        "Tarif passeport 1h30 (€)": 279,
        "Tarif passeport 3h (€)": 543,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06012",
        "Phone Number": "02.41.50.20.27",
      },
    ],
  },
  {
    Nom: "ALENÇON / Valframbert",
    Latitude: 48.4475,
    Longitude: 0.109166666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Alençon et sa Région",
        "Club Address": "Aérodrome d'Alençon Valframbert, 61000 ALENCON",
        "Tarif passeport 1h30 (€)": 200,
        "Tarif passeport 3h (€)": 384,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05028",
        "Phone Number": "02.33.29.25.86",
      },
    ],
  },
  {
    Nom: "LE HAVRE / Octeville",
    Latitude: 49.5338888888889,
    Longitude: 0.0880555555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub du Havre Jean Maridor",
        "Club Address":
          "Aérodrome du Havre 12 rue Louis Blériot, 76620 LE HAVRE",
        "Tarif passeport 1h30 (€)": 245,
        "Tarif passeport 3h (€)": 474,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05024",
        "Phone Number": "02.35.48.35.91",
      },
    ],
  },
  {
    Nom: "ABBEVILLE",
    Latitude: 50.1430555555556,
    Longitude: 1.8325,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Abbeville-Buigny-Baie de Somme",
        "Club Address": "Aérodrome d'Abbeville RN1, 80132 BUIGNY SAINT MACLOU",
        "Tarif passeport 1h30 (€)": 303,
        "Tarif passeport 3h (€)": 591,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03021",
        "Phone Number": "03.22.24.08.48",
      },
    ],
  },
  {
    Nom: "DREUX / Vernouillet",
    Latitude: 48.7066666666667,
    Longitude: 1.36277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub de Dreux",
        "Club Address":
          "Aérodrome Vernouillet Route de Chateauneuf, 28500 VERNOUILLET",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07006",
        "Phone Number": "02.37.46.26.49",
      },
    ],
  },
  {
    Nom: "ROUEN / Vallée de Seine",
    Latitude: 49.3908333333333,
    Longitude: 1.18388888888889,
    Clubs: [
      {
        "Club Name": "Aéro-Club de Rouen Normandie ",
        "Club Address":
          "Aéroport Rouen Vallée de Seine rue Maryse Bastié, 76520 BOOS",
        "Tarif passeport 1h30 (€)": 300,
        "Tarif passeport 3h (€)": 585,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05027",
        "Phone Number": "02.35.79.18.26",
      },
    ],
  },
  {
    Nom: "CHARTRES Métropole",
    Latitude: 48.4588888888889,
    Longitude: 1.52388888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Eure et Loir",
        "Club Address":
          "Aérodrome de Chartres-Métropole 41, chemin du grand gibet, 28000 CHARTRES",
        "Tarif passeport 1h30 (€)": 293,
        "Tarif passeport 3h (€)": 570,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07007",
        "Phone Number": "02.37.34.43.48",
      },
    ],
  },
  {
    Nom: "SAINT VALERY / Vittefleur",
    Latitude: 49.8352777777778,
    Longitude: 0.655833333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub Cauchois",
        "Club Address": "Aérodrome St Valéry  Vittefleur, 76460 ST SYLVAIN",
        "Tarif passeport 1h30 (€)": 200,
        "Tarif passeport 3h (€)": 384,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05008",
        "Phone Number": "0686973838",
      },
    ],
  },
  {
    Nom: "ORLEANS / St-Denis-de-L'Hôtel",
    Latitude: 47.8975,
    Longitude: 2.16416666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Orléans Loiret Col. Jean Demozay",
        "Club Address": "46 Ter rue Ste Catherine, 45000 ORLEANS",
        "Tarif passeport 1h30 (€)": 270,
        "Tarif passeport 3h (€)": 525,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=07009",
        "Phone Number": "02.38.59.18.34",
      },
    ],
  },
  {
    Nom: "LOGNES / Emerainville",
    Latitude: 48.8230555555556,
    Longitude: 2.62388888888889,
    Clubs: [
      {
        "Club Name": "Plane Air Club",
        "Club Address": "Aérodrome de Lognes-Emerainville, 77185 LOGNES",
        "Tarif passeport 1h30 (€)": 308,
        "Tarif passeport 3h (€)": 600,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04163",
        "Phone Number": "01.60.05.44.36",
      },
    ],
  },
  {
    Nom: "LE PLESSIS-BELLEVILLE",
    Latitude: 49.11,
    Longitude: 2.73805555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub Air France Nord",
        "Club Address": "Aérodrome du Plessis Belleville, 60950 ERMENONVILLE",
        "Tarif passeport 1h30 (€)": 275,
        "Tarif passeport 3h (€)": 534,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03035",
        "Phone Number": "03.44.60.53.49",
      },
    ],
  },
  {
    Nom: "FONTENAY TRESIGNY",
    Latitude: 48.7072222222222,
    Longitude: 2.90444444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub de Chaubuisson",
        "Club Address": "Aérodrome, 77610 FONTENAY TRESIGNY",
        "Tarif passeport 1h30 (€)": 248,
        "Tarif passeport 3h (€)": 480,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04104",
        "Phone Number": "01.64.25.91.45",
      },
    ],
  },
  {
    Nom: "ORANGE / Plan de Dieu",
    Latitude: 44.179927,
    Longitude: 4.918656,
    Clubs: [
      {
        "Club Name": "Aéroclub du Plan de Dieu",
        "Club Address":
          "Aérodrome Plan de Dieu Route de Sablet, 84850 TRAVAILLAN",
        "Tarif passeport 1h30 (€)": 242,
        "Tarif passeport 3h (€)": 468,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10028",
        "Phone Number": "04.90.70.96.33",
      },
    ],
  },
  {
    Nom: "PONTOISE / Cormeilles-en-Vexin",
    Latitude: 49.0966666666667,
    Longitude: 2.04083333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub Hispano Suiza",
        "Club Address": "2 rue de l'Ecole de Mars, 92200 NEUILLY SUR SEINE",
        "Tarif passeport 1h30 (€)": 285,
        "Tarif passeport 3h (€)": 555,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04046",
        "Phone Number": "01.34.41.77.00",
      },
    ],
  },
  {
    Nom: "SAINT-CYR-L'ÉCOLE",
    Latitude: 48.8102777777778,
    Longitude: 2.07333333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub les Alcyons d'Issy les Moulineaux",
        "Club Address": "Mairie, 92130 ISSY LES MOULINEAUX",
        "Tarif passeport 1h30 (€)": 308,
        "Tarif passeport 3h (€)": 600,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04012",
        "Phone Number": "01.30.45.18.44",
      },
    ],
  },
  {
    Nom: "REIMS / Prunay",
    Latitude: 49.2086111111111,
    Longitude: 4.15666666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub du CRNA - Est",
        "Club Address":
          "Rue Alberto Santos Dumont BP 1033, 51687 REIMS Cedex 2",
        "Tarif passeport 1h30 (€)": 209,
        "Tarif passeport 3h (€)": 402,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01056",
        "Phone Number": "2\n03.26.07.28",
      },
    ],
  },
  {
    Nom: "CHATILLON-SUR-SEINE",
    Latitude: 47.8463888888889,
    Longitude: 4.58055555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub du Chatillonnais",
        "Club Address": "Aérodrome, 21400 CHATILLON SUR SEINE",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02011",
        "Phone Number": "03.80.91.05.02",
      },
    ],
  },
  {
    Nom: "CHALONS / Ecury-Sur-Coole",
    Latitude: 48.9061111111111,
    Longitude: 4.35416666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub Farman Clément",
        "Club Address": "Mairie, 51000 CHALONS sur CHAMPAGNE",
        "Tarif passeport 1h30 (€)": 215,
        "Tarif passeport 3h (€)": 414,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01071",
        "Phone Number": "03.26.67.61.01",
      },
    ],
  },
  {
    Nom: "LENS / Bénifontaine",
    Latitude: 50.4672222222222,
    Longitude: 2.82194444444444,
    Clubs: [
      {
        "Club Name": "Aéroclub de Lens",
        "Club Address": "Aérodrome de Lens Benifontaine, 62880 VENDIN LE VIEIL",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03012",
        "Phone Number": "03.21.67.72.60",
      },
    ],
  },
  {
    Nom: "MERVILLE / Calonne",
    Latitude: 50.6166666666667,
    Longitude: 2.64,
    Clubs: [
      {
        "Club Name": "Cercle Aérien de Lestrem",
        "Club Address": "60, Basse rue, 62136 LESTREM",
        "Tarif passeport 1h30 (€)": 282,
        "Tarif passeport 3h (€)": 549,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03027",
        "Phone Number": "06 21 01 28 75",
      },
    ],
  },
  {
    Nom: "CHARLEVILLE / Mézières",
    Latitude: 49.7858333333333,
    Longitude: 4.64416666666667,
    Clubs: [
      {
        "Club Name": "Les Ailes Ardennaises",
        "Club Address": "Aérodrome des Ardennes Étienne Riché, 08090 BELVAL",
        "Tarif passeport 1h30 (€)": 270,
        "Tarif passeport 3h (€)": 525,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01001",
        "Phone Number": "03.24.52.90.42",
      },
    ],
  },
  {
    Nom: "LE MANS / Arnage",
    Latitude: 47.9486111111111,
    Longitude: 0.201666666666667,
    Clubs: [
      {
        "Club Name": "Les Ailes du Maine Avion",
        "Club Address": "Aérodrome Le Mans-Arnage, 72100 LE MANS",
        "Tarif passeport 1h30 (€)": 257,
        "Tarif passeport 3h (€)": 498,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06029",
        "Phone Number": "02.43.84.10.23",
      },
    ],
  },
  {
    Nom: "RENNES / Saint-Jacques",
    Latitude: 48.0719444444444,
    Longitude: -1.73222222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub Rennes Ille et Vilaine",
        "Club Address": "Aéroport, 35136 SAINT JACQUES DE LA LANDE",
        "Tarif passeport 1h30 (€)": 335,
        "Tarif passeport 3h (€)": 654,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06011",
        "Phone Number": "02.99.31.91.81",
      },
    ],
  },
  {
    Nom: "NANTES-ATLANTIQUE",
    Latitude: 47.1569444444444,
    Longitude: -1.60777777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub de Loire Atlantique",
        "Club Address": "Zone Aéroportuaire, 44346 BOUGUENAIS Cedex",
        "Tarif passeport 1h30 (€)": 309,
        "Tarif passeport 3h (€)": 603,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06013",
        "Phone Number": "02.40.75.13.16",
      },
    ],
  },
  {
    Nom: "SAINT BRIEUC-ARMOR",
    Latitude: 48.5375,
    Longitude: -2.85666666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub St-Brieuc Armor",
        "Club Address": "Aéroport St Brieuc Armor, 22440 TREMUSON",
        "Tarif passeport 1h30 (€)": 248,
        "Tarif passeport 3h (€)": 480,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06006",
        "Phone Number": "02 90 03 23 71",
      },
    ],
  },
  {
    Nom: "MORLAIX / Ploujean",
    Latitude: 48.6008333333333,
    Longitude: -3.81666666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub de Morlaix",
        "Club Address": "Aéroport, 29600 MORLAIX",
        "Tarif passeport 1h30 (€)": 240,
        "Tarif passeport 3h (€)": 465,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=06016",
        "Phone Number": "02.98.88.32.80",
      },
    ],
  },
  {
    Nom: "EPINAL / Dogneville",
    Latitude: 48.2119444444444,
    Longitude: 6.44916666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub Vosgien",
        "Club Address": "160 Route de l'aviation, 88000 DOGNEVILLE",
        "Tarif passeport 1h30 (€)": 266,
        "Tarif passeport 3h (€)": 516,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01041",
        "Phone Number": "09.63.28.67.34",
      },
    ],
  },
  {
    Nom: "EPINAL / Mirecourt",
    Latitude: 48.325,
    Longitude: 6.06666666666667,
    Clubs: [
      {
        "Club Name": "Aéroclub du Xaintois",
        "Club Address": "Aéroport Epinal Mirecourt, 88500 MIRECOURT",
        "Tarif passeport 1h30 (€)": 345,
        "Tarif passeport 3h (€)": 675,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01040",
        "Phone Number": "03.29.37.15.41",
      },
    ],
  },
  {
    Nom: "BRIVE / Souillac",
    Latitude: 45.0353952,
    Longitude: 1.481531,
    Clubs: [
      {
        "Club Name": "Aéroclub de Brive",
        "Club Address": "Aéroport de Brive-Souillac, 19600 NESPOULES",
        "Tarif passeport 1h30 (€)": 269,
        "Tarif passeport 3h (€)": 522,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=12006",
        "Phone Number": "05.55.86.88.37",
      },
    ],
  },
  {
    Nom: "NANCY / Essey",
    Latitude: 48.6922222222222,
    Longitude: 6.22611111111111,
    Clubs: [
      {
        "Club Name": "Les Ailes Nancéiennes",
        "Club Address":
          "Aéropôle Grand-Nancy Tomblaine Avenue Nelson Mandela, 54510 TOMBLAINE",
        "Tarif passeport 1h30 (€)": 293,
        "Tarif passeport 3h (€)": 570,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=01081",
        "Phone Number": null,
      },
    ],
  },
  {
    Nom: "PONTARLIER",
    Latitude: 46.9094444444444,
    Longitude: 6.33,
    Clubs: [
      {
        "Club Name": "Aéroclub de Pontarlier",
        "Club Address": "Aérodrome Route de Salins, 25300 PONTARLIER",
        "Tarif passeport 1h30 (€)": 281,
        "Tarif passeport 3h (€)": 546,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02022",
        "Phone Number": "03.81.39.09.70",
      },
    ],
  },
  {
    Nom: "AMBERIEU",
    Latitude: 45.9797222222222,
    Longitude: 5.33777777777778,
    Clubs: [
      {
        "Club Name": "Club Aéronautique du Bugey",
        "Club Address": "1, Chemin de l'Aéro-Club, 01500 CHATEAU-GAILLARD",
        "Tarif passeport 1h30 (€)": 282,
        "Tarif passeport 3h (€)": 549,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=11012",
        "Phone Number": "06 09 06 84 82",
      },
    ],
  },
  {
    Nom: "CAMBRAI / Niergnies",
    Latitude: 50.1425,
    Longitude: 3.265,
    Clubs: [
      {
        "Club Name": "Union Aéronautique du Cambresis - Club L. Blériot",
        "Club Address": "Aérodrome Cambrai Niergnies, 59400 NIERGNIES",
        "Tarif passeport 1h30 (€)": 326,
        "Tarif passeport 3h (€)": 636,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03026",
        "Phone Number": "03.27.81.26.56",
      },
    ],
  },
  {
    Nom: "YVETOT / Baon le Comte",
    Latitude: 49.638889,
    Longitude: 0.731944,
    Clubs: [
      {
        "Club Name": "Aéroclub d'Yvetot Lucien Canu",
        "Club Address": "Route de Cany, 76190 BAONS LE COMTE",
        "Tarif passeport 1h30 (€)": 258,
        "Tarif passeport 3h (€)": 501,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05041",
        "Phone Number": "06.58.73.30.59",
      },
    ],
  },
  {
    Nom: "POINTE A PITRE / le Raizet",
    Latitude: 16.264167,
    Longitude: -61.525833,
    Clubs: [
      {
        "Club Name": "Les Ailes Guadeloupéennes",
        "Club Address": "Aéroport Raizet Sud, 97139 LES ABYMES / GUADELOUPE",
        "Tarif passeport 1h30 (€)": 330,
        "Tarif passeport 3h (€)": 645,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=14021",
        "Phone Number": "05.90.83.24.44",
      },
    ],
  },
  {
    Nom: "TOULOUSE / Lasbordes",
    Latitude: 43.5888888888889,
    Longitude: 1.49972222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub Toulouse Midi Pyrénées",
        "Club Address":
          "Aérodrome de Toulouse Lasbordes 34 av Jean-René Lagasse, 31130 BALMA",
        "Tarif passeport 1h30 (€)": 290,
        "Tarif passeport 3h (€)": 564,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09019",
        "Phone Number": "05.62.47.91.60",
      },
      {
        "Club Name": "Aéroclub Claude Chautemps",
        "Club Address":
          "Aérodrome de Toulouse Lasbordes 46 av J. René Lagasse, 31130 BALMA",
        "Tarif passeport 1h30 (€)": 222,
        "Tarif passeport 3h (€)": 429,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=09046",
        "Phone Number": "05.61.20.00.92",
      },
    ],
  },
  {
    Nom: "PERPIGNAN / Rivesaltes",
    Latitude: 42.7408333333333,
    Longitude: 2.86972222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub du Roussillon",
        "Club Address":
          "Aéroport Perpignan Rivesaltes Av Maurice Bellonte, 66000 PERPIGNAN",
        "Tarif passeport 1h30 (€)": 293,
        "Tarif passeport 3h (€)": 570,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10032",
        "Phone Number": "04 68 73 48 18",
      },
      {
        "Club Name": "Base Latécoère Catalane",
        "Club Address":
          "Aeroport de Perpignan-Rivesaltes 499, Ave. Maurice Bellonte, 66000 PERPIGNAN",
        "Tarif passeport 1h30 (€)": 263,
        "Tarif passeport 3h (€)": 510,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10112",
        "Phone Number": "06 69 98 89 92",
      },
    ],
  },
  {
    Nom: "BEZIERS / Vias",
    Latitude: 43.3233333333333,
    Longitude: 3.35333333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub de Béziers",
        "Club Address":
          "Aérodrome de Béziers Agde Vias RD 612, 34420 PORTIRAGNES",
        "Tarif passeport 1h30 (€)": 203,
        "Tarif passeport 3h (€)": 390,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10007",
        "Phone Number": "06.34.43.94.28",
      },
      {
        "Club Name": "Les Ailes du Canal du Midi",
        "Club Address": "rue Aéroport de Béziers Cap d'Agde, 34420 PORTIRAGNES",
        "Tarif passeport 1h30 (€)": 237,
        "Tarif passeport 3h (€)": 459,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10029",
        "Phone Number": "04 67 01 57 94",
      },
    ],
  },
  {
    Nom: "GAP / Tallard",
    Latitude: 44.455,
    Longitude: 6.03777777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Alpin",
        "Club Address": "520 rue Pierre Georges Latécoère, 05130 TALLARD",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10004",
        "Phone Number": "04.92.54.18.80",
      },
      {
        "Club Name": "Aéroclub Val de Durance",
        "Club Address": "910 rue Pierre Georges Latécoère, 05130 TALLARD",
        "Tarif passeport 1h30 (€)": 233,
        "Tarif passeport 3h (€)": 450,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10087",
        "Phone Number": "04.92.54.14.70",
      },
    ],
  },
  {
    Nom: "CARPENTRAS",
    Latitude: 44.0233333333333,
    Longitude: 5.09083333333333,
    Clubs: [
      {
        "Club Name": "Aéroclub du Comtat Venaissin",
        "Club Address":
          "Aérodrome Edgar Soumille 232 chemin de st gens, 84210 PERNES LES FONTAINES",
        "Tarif passeport 1h30 (€)": 293,
        "Tarif passeport 3h (€)": 570,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10011",
        "Phone Number": null,
      },
      {
        "Club Name": "Aero Formation Ventoux",
        "Club Address": "648 Route du Pont de Crillon, 84410 CRILLON LE BRAVE",
        "Tarif passeport 1h30 (€)": 300,
        "Tarif passeport 3h (€)": 585,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10120",
        "Phone Number": "06 84 63 57 20",
      },
    ],
  },
  {
    Nom: "MEAUX / Esbly",
    Latitude: 48.9277777777778,
    Longitude: 2.83527777777778,
    Clubs: [
      {
        "Club Name": "Aéronautique Club de France",
        "Club Address":
          "Aérodrome de Meaux - Esbly Route départementale 5, 77450 ISLES-LES-VILLENOY",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04002",
        "Phone Number": "01.60.04.24.40",
      },
      {
        "Club Name": "Union des Pilotes Civils de France - UPCF",
        "Club Address": "Aérodrome Meaux Esbly, 77450 ISLES LES VILLENOY",
        "Tarif passeport 1h30 (€)": 228,
        "Tarif passeport 3h (€)": 441,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04081",
        "Phone Number": "01.60.04.28.45",
      },
    ],
  },
  {
    Nom: "TOUSSUS-LE-NOBLE",
    Latitude: 48.7497222222222,
    Longitude: 2.11111111111111,
    Clubs: [
      {
        "Club Name": "Aéroclub Air France",
        "Club Address": "Aéroport de Toussus le Noble, 78117 TOUSSUS LE NOBLE",
        "Tarif passeport 1h30 (€)": 294,
        "Tarif passeport 3h (€)": 573,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04008",
        "Phone Number": "09.80.77.39.59",
      },
      {
        "Club Name": "Aéroclub des IPSA",
        "Club Address":
          "Aérodrome de Toussus-le-Noble Batiment 107, 78117 TOUSSUS LE NOBLE",
        "Tarif passeport 1h30 (€)": 240,
        "Tarif passeport 3h (€)": 465,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04047",
        "Phone Number": "09 77 53 27 33",
      },
    ],
  },
  {
    Nom: "CHAVENAY / Villepreux",
    Latitude: 48.8422222222222,
    Longitude: 1.98027777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Dassault Ile-de-France",
        "Club Address": "Aérodrome de Chavenay Villepreux, 78450 VILLEPREUX",
        "Tarif passeport 1h30 (€)": 210,
        "Tarif passeport 3h (€)": 405,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04056",
        "Phone Number": "01.34.62.30.60",
      },
      {
        "Club Name": "Cercle Aéron. du Ministere de l'Intérieur - CAMI",
        "Club Address": "Aérodrome de Chavenay, 78450 VILLEPREUX",
        "Tarif passeport 1h30 (€)": 195,
        "Tarif passeport 3h (€)": 375,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04097",
        "Phone Number": "01.30.56.28.02",
      },
    ],
  },
  {
    Nom: "BESANÇON / La Vèze",
    Latitude: 47.2052777777778,
    Longitude: 6.08055555555556,
    Clubs: [
      {
        "Club Name": "Aéroclub de Besançon la Veze",
        "Club Address": "Aérodrome de Besançon la Veze, 25660 LA VEZE",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02045",
        "Phone Number": "03.81.81.50.82",
      },
      {
        "Club Name": "Club Aéronautique Comtois - CAC",
        "Club Address": "Aérodrome de Besançon - La Vèze, 25660 LA VEZE",
        "Tarif passeport 1h30 (€)": 249,
        "Tarif passeport 3h (€)": 483,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=02051",
        "Phone Number": "07.69.03.46.03",
      },
    ],
  },
  {
    Nom: "CAEN / Carpiquet",
    Latitude: 49.1733333333333,
    Longitude: -0.45,
    Clubs: [
      {
        "Club Name": "Aéroclub Régional de Caen",
        "Club Address": "Aérodrome, 14650 CARPIQUET",
        "Tarif passeport 1h30 (€)": 284,
        "Tarif passeport 3h (€)": 552,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05007",
        "Phone Number": "02.31.26.52.00",
      },
      {
        "Club Name": "Les Ailes du Calvados",
        "Club Address":
          "Aéroport de Caen Carpiquet Le Haut Marcelet, 14740 ST MANVIEU NORREY",
        "Tarif passeport 1h30 (€)": 267,
        "Tarif passeport 3h (€)": 519,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=05050",
        "Phone Number": "02.31.80.67.22",
      },
    ],
  },
  {
    Nom: "CUERS / Pierrefeu",
    Latitude: 43.2475,
    Longitude: 6.12722222222222,
    Clubs: [
      {
        "Club Name": "Aéroclub du Soleil",
        "Club Address": "1029 Bd Bois Maurin, 83150 BANDOL",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10035",
        "Phone Number": "06.22.15.40.59",
      },
      {
        "Club Name": "Association Castel Mauboussin",
        "Club Address": "Aérodrome civil Cuers Pierrefeu, 83390 CUERS",
        "Tarif passeport 1h30 (€)": 300,
        "Tarif passeport 3h (€)": 585,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=10102",
        "Phone Number": "06 76 85 11 42",
      },
    ],
  },
  {
    Nom: "LES MUREAUX",
    Latitude: 48.9983333333333,
    Longitude: 1.94277777777778,
    Clubs: [
      {
        "Club Name": "Aéroclub Roger Janin",
        "Club Address":
          "Aérodrome zone civile route de Verneuil, 78130 LES MUREAUX cedex",
        "Tarif passeport 1h30 (€)": 275,
        "Tarif passeport 3h (€)": 534,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04072",
        "Phone Number": "01.34.74.12.32",
      },
      {
        "Club Name": "Aéroclub de Neuilly",
        "Club Address": "169 Av C. de Gaulle, 92200 NEUILLY",
        "Tarif passeport 1h30 (€)": 255,
        "Tarif passeport 3h (€)": 495,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04119",
        "Phone Number": "01.34.74.21.42",
      },
    ],
  },
  {
    Nom: "MARTINIQUE Aimé Césaire",
    Latitude: 14.592222,
    Longitude: -60.996389,
    Clubs: [
      {
        "Club Name": "Aéroclub Horizon Caraïbes",
        "Club Address":
          "Zone d'aviation générale Aéroport Martinique Aimé Césaire, 97232 LE LAMENTIN / MARTINIQUE",
        "Tarif passeport 1h30 (€)": 267,
        "Tarif passeport 3h (€)": 519,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=14089",
        "Phone Number": "05.96.96.18.56",
      },
      {
        "Club Name": "Martinique Easy Fly",
        "Club Address":
          "Zone d'Aviation Générale, 97232 LE LAMENTIN / MARTINIQUE",
        "Tarif passeport 1h30 (€)": 252,
        "Tarif passeport 3h (€)": 489,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=14095",
        "Phone Number": "06 96 11 20 58",
      },
    ],
  },
  {
    Nom: "ETAMPES / Montdésir",
    Latitude: 48.3811111111111,
    Longitude: 2.07388888888889,
    Clubs: [
      {
        "Club Name": "Aéroclub des Cheminots",
        "Club Address": "Aérodrome Etampes Mondésir, 91690 GUILLERVAL",
        "Tarif passeport 1h30 (€)": 239,
        "Tarif passeport 3h (€)": 462,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04031",
        "Phone Number": "01.60.80.99.57",
      },
      {
        "Club Name": "Centre de Pilotage Régional",
        "Club Address": "Aérodrome Etampes Mondésir, 91690 GUILLERVAL",
        "Tarif passeport 1h30 (€)": 258,
        "Tarif passeport 3h (€)": 501,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04110",
        "Phone Number": "06 95 32 59 57",
      },
      {
        "Club Name": "Aéroclub des Navigants",
        "Club Address": "Aérodrome Etampes Mondésir, 91690 GUILLERVAL SACLAS",
        "Tarif passeport 1h30 (€)": 278,
        "Tarif passeport 3h (€)": 540,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04146",
        "Phone Number": "01.60.80.98.31",
      },
      {
        "Club Name": "Aéroclub des Finances",
        "Club Address":
          "Aérodrome d'Etampes rue Nationale 20, 91690 GUILLERVAL",
        "Tarif passeport 1h30 (€)": 218,
        "Tarif passeport 3h (€)": 420,
        "Passeport Link":
          "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04147",
        "Phone Number": "01.60.80.99.26",
      },
    ],
  },
];

var helicoptere = [
  {
    name: "Aix Heli Pro",
    link: "https://helico.org/organisations/aix-heli-pro/",
    address: "0 Aix-en-Provence 13290",
    lat: "43.5026652",
    lng: "5.372569",
    price: "159",
  },
  {
    name: "SUD OUEST HELICO",
    link: "https://helico.org/organisations/sud-ouest-helico/",
    address: "Plaine de bord Domme 24250",
    lat: "44.78486189999999",
    lng: "1.249309",
    price: "159",
  },
  {
    name: "Aéro club de Propriano",
    link: "https://helico.org/organisations/aero-club-de-propriano/",
    address: "XXXX Propriano 20110",
    lat: "41.6588589",
    lng: "8.8933389",
    price: "159",
  },
  {
    name: "HELICLUB DU PLESSIS",
    link: "https://helico.org/organisations/heliclub-du-plessis/",
    address: "Aérodrome du Plessis - Belleville Ermenonville 60950",
    lat: "49.1101483",
    lng: "2.736022199999999",
    price: "159",
  },
  {
    name: "CIF-H",
    link: "https://helico.org/organisations/cif-h/",
    address: "ROUTE DE TERCIS Dax 40100",
    lat: "43.708608",
    lng: "-1.051945",
    price: "159",
  },
  {
    name: "Fly pictures",
    link: "https://helico.org/organisations/fly-pictures/",
    address: "Résidence du p4 Rue du 11 Novembre 1918 Saint-Tropez 83990",
    lat: "43.2714851",
    lng: "6.636921799999999",
    price: "159",
  },
  {
    name: "Hélico &amp;amp; Drone Training Academy",
    link: "https://helico.org/organisations/helico-drone-training-academy/",
    address: "2 Rue Georges Charpak Pornic 44210",
    lat: "47.1238958",
    lng: "-2.0856652",
    price: "159",
  },
  {
    name: "GREEN BEES",
    link: "https://helico.org/organisations/green-bees/",
    address: "245 Avenue Francis Toner Cannes 06150",
    lat: "43.5471788",
    lng: "6.954007700000001",
    price: "159",
  },
  {
    name: "CFPC GH",
    link: "https://helico.org/organisations/cfpc-gh/",
    address: "1 Chemin Beaurivage Saint-Pierre 97410",
    lat: "-21.325298",
    lng: "55.443944",
    price: "159",
  },
  {
    name: "HELISUD LR",
    link: "https://helico.org/organisations/helisud-lr/",
    address: "18 Chemin du Caire Lagamas 34150",
    lat: "43.676198",
    lng: "3.5238821",
    price: "159",
  },
  {
    name: "Heliberté Le Mans",
    link: "https://helico.org/organisations/heliberte-le-mans/",
    address: "Aéroport Le Mans Arnage, Route d'Angers Le Mans 72100",
    lat: "47.94664899999999",
    lng: "0.1965919999999999",
    price: "159",
  },
  {
    name: "Héli Club de Bourbon",
    link: "https://helico.org/organisations/heli-club-de-bourbon/",
    address: "Aérodrome de Pierrefonds Saint-Pierre 97410",
    lat: "-21.3091816",
    lng: "55.43263830000001",
    price: "159",
  },
  {
    name: "Aéroclub du Bas Armagnac",
    link: "https://helico.org/organisations/aeroclub-du-bas-armagnac/",
    address: "Aérodrome de Nogaro - BP 17 NOGARO 32110",
    lat: "43.766204",
    lng: "-0.0327891",
    price: "159",
  },
  {
    name: "Vol ensemb",
    link: "https://helico.org/organisations/vol-ensemb/",
    address:
      "Chemin de l'Aérodrome, Pierrefonds, Saint-Pierre, La Réunion Saint-Pierre 97410",
    lat: "-21.3166106",
    lng: "55.4226779",
    price: "159",
  },
  {
    name: "Toulouse Hélico Formations THF",
    link: "https://helico.org/organisations/toulouse-helico-formations-thf/",
    address: "10bis, avenue Jean René Lagasse Balma 31130",
    lat: "43.5895114",
    lng: "1.4997997",
    price: "159",
  },
  {
    name: "Sumo 44",
    link: "https://helico.org/organisations/sumo-44/",
    address: "50 rue Jules Vallès Saint-jacques-de-la-lande 35136",
    lat: "48.0637897",
    lng: "-1.7268218",
    price: "159",
  },
  {
    name: "Run Hélicoptères",
    link: "https://helico.org/organisations/run-helicopteres/",
    address: "Aérodrome de Pierrefond St-pierre-de-la-reunion 97410",
    lat: "-21.3203385",
    lng: "55.4285436",
    price: "159",
  },
  {
    name: "Rotor Maintenance",
    link: "https://helico.org/organisations/rotor-maintenance/",
    address: "21 rue du Moulin Izy 45480",
    lat: "48.1478918",
    lng: "2.0753476",
    price: "159",
  },
  {
    name: "Rotor Club de la Côte d'Opale",
    link: "https://helico.org/organisations/rotor-club-de-la-cote-dopale/",
    address: "Aérodrome du Touquet Le-touquet-paris-plage 62520",
    lat: "50.5143175",
    lng: "1.619297",
    price: "159",
  },
  {
    name: "Rotor Club Aixois",
    link: "https://helico.org/organisations/rotor-club-aixois/",
    address:
      "Aérodrome Aix Les Milles, Chemin de la Badesse Aix-en-Provence 13290",
    lat: "43.5026652",
    lng: "5.372569",
    price: "159",
  },
  {
    name: "Rotor &amp; Aircraft",
    link: "https://helico.org/organisations/rotor-aircraft/",
    address: "3 place du général de Gaulle Chartres 28000",
    lat: "48.4453393",
    lng: "1.4843156",
    price: "159",
  },
  {
    name: "Perpignan Hélico",
    link: "https://helico.org/organisations/perpignan-helico/",
    address: "Av Maurice Bellonte - Bt Hélittoral Perpignan 66000",
    lat: "42.7328852",
    lng: "2.8732798",
    price: "159",
  },
  {
    name: "Les Anciennes Pales",
    link: "https://helico.org/organisations/les-anciennes-pales/",
    address: "24 Les Perdriets Signy-signets 77640",
    lat: "48.9210128",
    lng: "3.0444622",
    price: "159",
  },
  {
    name: "Hélitel",
    link: "https://helico.org/organisations/helitel/",
    address: "21 rue de l'Espérance Auberive 51600",
    lat: "49.2045561",
    lng: "4.4160318",
    price: "159",
  },
  {
    name: "Hélisud",
    link: "https://helico.org/organisations/helisud/",
    address: "Aéroport de Nouméa-Magenta, Rue Roger Gervolino Nouméa 98800",
    lat: "-22.2591425",
    lng: "166.4728904",
    price: "159",
  },
  {
    name: "Hélisfair",
    link: "https://helico.org/organisations/helisfair/",
    address: "Aérodrome de Haguenau 18 rue Maryse-Bastié 67500 haguenau 67500",
    lat: "48.7962507",
    lng: "7.8135454",
    price: "159",
  },
  {
    name: "Hélimouv",
    link: "https://helico.org/organisations/helimouv/",
    address: "Aérodrome de Nancy-Essey Tomblaine 54510",
    lat: "48.693542",
    lng: "6.226561",
    price: "159",
  },
  {
    name: "Hélicoptères de Camargue",
    link: "https://helico.org/organisations/helicopteres-de-camargue/",
    address: "Route Du Camp D’aviation Candillargues 34130",
    lat: "43.611431",
    lng: "4.0701842",
    price: "159",
  },
  {
    name: "Hélicopt'Air",
    link: "https://helico.org/organisations/helicoptair/",
    address: "Rue Charles Linbergh Cholet 49300",
    lat: "47.0813777",
    lng: "-0.8794588",
    price: "159",
  },
  {
    name: "Héliclub de la Côte d'Azur",
    link: "https://helico.org/organisations/heliclub-de-la-cote-dazur/",
    address: "219 avenue des rossignols Cannes-mandelieu-la-napoule 06210",
    lat: "43.5362926",
    lng: "6.9158332",
    price: "159",
  },
  {
    name: "Héliclub de l'Ain",
    link: "https://helico.org/organisations/heliclub-de-lain/",
    address: "Aérodrome «Terre des Hommes» Ceyzeriat 01250",
    lat: "46.2024496",
    lng: "5.2919371",
    price: "159",
  },
  {
    name: "Héliclub de l'Adour",
    link: "https://helico.org/organisations/heliclub-de-ladour/",
    address: "323 route d'Aire Saint-loubouer 40320",
    lat: "43.6775184",
    lng: "-0.4138067",
    price: "159",
  },
  {
    name: "Héliclub de Compiègne",
    link: "https://helico.org/organisations/heliclub-de-compiegne/",
    address: "28 rue des Fossés Compiegne 60200",
    lat: "49.4141858",
    lng: "2.825932",
    price: "159",
  },
  {
    name: "Héliclub Cyril Lemercier",
    link: "https://helico.org/organisations/heliclub-cyril-lemercier/",
    address: "Aérodrome de Chambéry-Challes-les-Eaux Challes-les-eaux 73190",
    lat: "45.5586845",
    lng: "5.9747296",
    price: "159",
  },
  {
    name: "Hélicentre",
    link: "https://helico.org/organisations/helicentre/",
    address: "187 rue du Haut Noyers 45260",
    lat: "47.899081",
    lng: "2.5199911",
    price: "159",
  },
  {
    name: "Héli-Nord",
    link: "https://helico.org/organisations/heli-nord/",
    address: "Aérodrome de Valenciennes - Aérogare ZI n°2 Prouvy 59121",
    lat: "50.3326041",
    lng: "3.4588129",
    price: "159",
  },
  {
    name: "Héli-Club Hélicharentes",
    link: "https://helico.org/organisations/heli-club-helicharentes/",
    address: "Aéroport La Sauzaie Saint-aignant 17620",
    lat: "45.8897793",
    lng: "-0.9836213",
    price: "159",
  },
  {
    name: "Héli-Club de Franche-Comté",
    link: "https://helico.org/organisations/heli-club-de-franche-comte/",
    address: "Aérodrome de Monbéliard Courcelles-les-monbeliard 25420",
    lat: "47.4925964",
    lng: "6.7905826",
    price: "159",
  },
  {
    name: "Héli Rhin",
    link: "https://helico.org/organisations/heli-rhin-sas/",
    address: "43 Route de Strasbourg Colmar 68000",
    lat: "48.1093842",
    lng: "7.3625867",
    price: "159",
  },
  {
    name: "Héli Club du Sud-Ouest",
    link: "https://helico.org/organisations/heli-club-du-sud-ouest/",
    address: "Aérodrome Pau-Pyrénées Sauvagnon 64230",
    lat: "43.3811894",
    lng: "-0.4192235",
    price: "159",
  },
  {
    name: "Héli Club du Beaujolais",
    link: "https://helico.org/organisations/heli-club-du-beaujolais/",
    address: "Aérodrome de Villefranche-Tarare Frontenas 69620",
    lat: "45.9201902",
    lng: "4.6314778",
    price: "159",
  },
  {
    name: "ALBI-AEROCOPTER",
    link: "https://helico.org/organisations/heli-base-occitanie/",
    address: "Aérodrome d'ALBI Le Sequestre 81990",
    lat: "43.9157395",
    lng: "2.1172121",
    price: "159",
  },
  {
    name: "Héli 17",
    link: "https://helico.org/organisations/heli-17/",
    address: "Aérodrome de Royan Médis Medis 17600",
    lat: "45.6335458",
    lng: "-0.9751164",
    price: "159",
  },
  {
    name: "Golf Tango",
    link: "https://helico.org/organisations/golf-tango/",
    address: "Aérodrome de Toussus-le-Noble, 223 Hangar Toussus-le-noble 78117",
    lat: "48.7486375",
    lng: "2.109739",
    price: "159",
  },
  {
    name: "Gilexport SARL",
    link: "https://helico.org/organisations/gilexport-sarl/",
    address: "10 Haut-Vauthebault Pont-rean 35580",
    lat: "47.9913885",
    lng: "-1.7666329",
    price: "159",
  },
  {
    name: "France Copter",
    link: "https://helico.org/organisations/france-copter/",
    address: "Aérodrome de Cerny La ferte-alais 91590",
    lat: "48.496061",
    lng: "2.343156",
    price: "159",
  },
  {
    name: "Easy Hélico",
    link: "https://helico.org/organisations/easy-helico/",
    address: "335 Avenue Clément Ader Avignon 84140",
    lat: "43.9069336",
    lng: "4.897698099999999",
    price: "159",
  },
  {
    name: "Copt'Air",
    link: "https://helico.org/organisations/coptair/",
    address: "Aérodrome de Lyon-Bron Bron 69500",
    lat: "45.7282052",
    lng: "4.9421822",
    price: "159",
  },
  {
    name: "CELAG",
    link: "https://helico.org/organisations/celag/",
    address: "Aérodrome du Versoud Le versoud 38420",
    lat: "45.2169885",
    lng: "5.8497495",
    price: "159",
  },
  {
    name: "CEFH /IFPP",
    link: "https://helico.org/organisations/cefh-ifpp/",
    address: "Aérodrome de Glisy-Amiens - BP 80045 Glisy 80440",
    lat: "49.8709856",
    lng: "2.3839246",
    price: "159",
  },
  {
    name: "Association des Pilotes d'Hélicoptère du Finistère",
    link: "https://helico.org/organisations/association-des-pilotes-dhelicoptere-du-finistere/",
    address: "Hangar Brest Aéro - Aéroport Guipavas 29490",
    lat: "48.4428388",
    lng: "-4.4197781",
    price: "159",
  },
  {
    name: "Air Touraine Academy",
    link: "https://helico.org/organisations/air-touraine-academy/",
    address: "Héliport de Belleville Neuvy-le-roi 37370",
    lat: "47.5806278",
    lng: "0.603315",
    price: "159",
  },
  {
    name: "Aéroclub de la Côte d'Or",
    link: "https://helico.org/organisations/aeroclub-de-la-cote-dor/",
    address: "2 route de Troyes Darois 21121",
    lat: "47.3841358",
    lng: "4.9442543",
    price: "159",
  },
  {
    name: "Rotor Club Formation",
    link: "https://helico.org/organisations/aeroclub-de-la-cote-dor/",
    address: "Aérodrome de Lognes, 77185 Lognes",
    lat: "48.82158085607542",
    lng: "2.625550700800856",
    price: "159",
  },
];

var montgolfiere = [
  {
    nom: "MONTGOLFIÈRE DES PAYS DE L'AIN",
    ville: "PERONNAS",
    lng: 5.203589,
    lat: 46.179848,
  },
  {
    nom: "ASSOCIATION CULTURE LOISIRS MONTGOLFIÈRES",
    ville: "ANNONAY",
    lng: 4.667547,
    lat: 45.239964,
  },
  {
    nom: "MONTGOLFIÈRES ET CIE",
    ville: "QUINTENAS",
    lng: 4.687122,
    lat: 45.189281,
  },
  {
    nom: "AEROVOL MONTGOLFIÈRE",
    ville: "ANNOISIN CHATELANS",
    lng: 5.306056,
    lat: 45.773873,
  },
  {
    nom: "LES AILES FORÉZIENNES",
    ville: "ANDREZIEUX BOUTHEON",
    lng: 4.2570709,
    lat: 45.5253093,
  },
  {
    nom: "MONTGOLFIÈRES DE LA CÔTE ROANNAISE",
    ville: "ST HAON LE VIEUX",
    lng: 3.90811,
    lat: 46.074574,
  },
  {
    nom: "MONTGOLFIÈRE & DÉCOUVERTES",
    ville: "LE PUY EN VELAY",
    lng: 3.8835699,
    lat: 45.0430915,
  },
  {
    nom: "MONTGOLFIÈRE CLUB DU VELAY",
    ville: "SAINT JEURES",
    lng: 4.205091,
    lat: 45.095668,
  },
  {
    nom: "MONTGOLFIÈRE EN VELAY",
    ville: "VALS PRES LE PUY EN VELAY",
    lng: 3.880221,
    lat: 45.02874,
  },
  {
    nom: "MONTGOLFIÈRES DU HAUT ALLIER",
    ville: "BRIOUDE",
    lng: 3.385371,
    lat: 45.293897,
  },
  {
    nom: "ENTRE CIEL ET TERRE",
    ville: "CLERMONT FERRAND",
    lng: 3.081943,
    lat: 45.777455,
  },
  {
    nom: "LÂCHEZ TOUT",
    ville: "LYON",
    lng: 4.832011,
    lat: 45.757814,
  },
  {
    nom: "LES MONTGOLFIÈRES DE L’OUEST LYONNAIS",
    ville: "BRINDAS",
    lng: 103.817876,
    lat: 1.283998,
  },
  {
    nom: "MONTGOLFIÈRES CLUB DE SAVOIE",
    ville: "SAINT RANDOLPH",
    lng: -92.42541,
    lat: 39.42804,
  },
  {
    nom: "LE CIEL EST À VOUS",
    ville: "LES CARROZ",
    lng: 6.641133,
    lat: 46.0251655,
  },
  {
    nom: "AIRSHOW",
    ville: "VIVEROLS",
    lng: 3.883136,
    lat: 45.433927,
  },
  {
    nom: "BALLONS D'EMERAUDE",
    ville: "PLEURTUIT",
    lng: -2.05912,
    lat: 48.5815,
  },
  {
    nom: "BALLON VOLE",
    ville: "FONTAINE LES DIJON",
    lng: 5.021231,
    lat: 47.342743,
  },
  {
    nom: "FRANCE MONTGOLFIÈRES – SEMUR",
    ville: "EN",
    lng: 4.332187468539988,
    lat: 47.48840059332604,
  },
  {
    nom: "CERCLE AÉROSTATIQUE DU PAYS DE MONTBÉLIARD",
    ville: "ABBÉVILLERS",
    lng: 6.918577,
    lat: 47.431408,
  },
  {
    nom: "DOUBS'R BALLOONS",
    ville: "ROCHEJEAN",
    lng: 6.291683,
    lat: 46.745166,
  },
  {
    nom: "VENTS DU FUTUR",
    ville: "ARC ET SENANS",
    lng: 5.781817,
    lat: 47.033233,
  },
  {
    nom: "LES BULLES LIBRES",
    ville: "PLANCHER BAS",
    lng: 6.732265,
    lat: 47.721086,
  },
  {
    nom: "MONTGOLFIÈRES 70",
    ville: "ECHENOZ LA MELINE",
    lng: 6.137912,
    lat: 47.600852,
  },
  {
    nom: "AÉRONAUTE DE L'AÉROCLUB DE BOURGOGNE",
    ville: "CHAMPFORGEUIL",
    lng: 4.835029,
    lat: 46.820373,
  },
  {
    nom: "SAÔNE MONTGOLFIÈRE PASSION",
    ville: "GERGY",
    lng: 4.947075,
    lat: 46.876666,
  },
  {
    nom: "MONTGOLFIERES EN CHAROLAIS",
    ville: "CHAROLLES",
    lng: 4.274316,
    lat: 46.435311,
  },
  {
    nom: "AIR ESCARGOT",
    ville: "REMIGNY",
    lng: 3.289189,
    lat: 49.720141,
  },
  {
    nom: "BALLOONING ADVENTURES",
    ville: "BAVILLIERS",
    lng: 6.834759,
    lat: 47.62207,
  },
  {
    nom: "CLUB AÉROSTATIQUE DE FRANCHE",
    ville: "COMTÉ",
    lng: 3.603849,
    lat: 50.842339,
  },
  {
    nom: "TERRITOIRE MONTGOLFIÈRES",
    ville: "MONTREUX CHÂTEAU",
    lng: 7.003146,
    lat: 47.608029,
  },
  {
    nom: "VOLS PASSION",
    ville: "BELFORT",
    lng: 6.862894,
    lat: 47.63796,
  },
  {
    nom: "CLUB AÉROSTATIQUE DE L'ILE DE FRANCE",
    ville: "MAINTENON",
    lng: 1.577331,
    lat: 48.586552,
    restaurant: 1,
  },
  {
    nom: "AIR PEGASUS MONTGOLFIÈRES",
    ville: "BAILLEAU ARMENONVILLE – NACELLE ACCESSIBLE",
    lng: 1.646284,
    lat: 48.543264,
    restaurant: 1,
  },
  {
    nom: "CERCLE ROBUR",
    ville: "ST CYR SUR LOIRE",
    lng: 0.66582,
    lat: 47.405245,
  },
  {
    nom: "SPHÈRE",
    ville: "LERNE",
    lng: -73.964022,
    lat: 40.806964,
  },
  {
    nom: "TOP BALLOON",
    ville: "MARRAY",
    lng: 0.699191,
    lat: 47.621183,
  },
  {
    nom: "AMBOISE MONTGOLFIÈRE",
    ville: "BALLOON REVOLUTION",
    lng: -80.879542,
    lat: 35.212932,
  },
  {
    nom: "TOURAINE MONTGOLFIÈRE",
    ville: "NAZELLE NEGRON",
    lng: 0.953293,
    lat: 47.431987,
  },
  {
    nom: "AÉROCOM MONTGOLFIÈRES",
    ville: "ONZAIN – NACELLE ACCESSIBLE",
    lng: -149.864717,
    lat: 61.148948,
  },
  {
    nom: "COMPAGNONS DU VENT",
    ville: "VINEUIL",
    lng: 1.389284,
    lat: 47.5858,
  },
  {
    nom: "ART MONTGOLFIÈRES",
    ville: "SAINT GEORGES SUR CHER",
    lng: 1.130478,
    lat: 47.325869,
  },
  {
    nom: "AIR GÂTINAIS",
    ville: "CHALETTE SUR LOING",
    lng: 2.732023,
    lat: 48.014464,
  },
  {
    nom: "LES BALLONS DE LOIRE",
    ville: "JOUY LE POTIER",
    lng: 1.809473,
    lat: 47.746693,
  },
  {
    nom: "LES MONTGOLFIÈRES CHAMPENOISES",
    ville: "PROSNES",
    lng: 4.29298,
    lat: 49.191356,
  },
  {
    nom: "CHAMBLEY ASSOCIATION MONTGOLFIÈRES",
    ville: "CHAMBLEY",
    lng: 5.864485,
    lat: 49.024487,
  },
  {
    nom: "ECLIPSE MONTGOLFIÈRE CLUB",
    ville: "AGINCOURT",
    lng: -79.278549,
    lat: 43.785353,
  },
  {
    nom: "CLUB MONTGOLFIÈRE ICARE",
    ville: "VAL DE BRIEY",
    lng: 5.935173,
    lat: 49.254801,
  },
  {
    nom: "LES ZÉPHYRS",
    ville: "DOMBASLE SUR MEURTHE",
    lng: 6.34938,
    lat: 48.623128,
  },
  {
    nom: "A AIR CHAUD",
    ville: "THIONVILLE",
    lng: 6.167587,
    lat: 49.357927,
  },
  {
    nom: "APIMM",
    ville: "AMNÉVILLE",
    lng: 6.142074,
    lat: 49.260361,
  },
  {
    nom: "LIBRES BALLONS DU BASTBERG",
    ville: "PHALSBOURG – NACELLE ACCESSIBLE",
    lng: -149.864717,
    lat: 61.148948,
  },
  {
    nom: "YUTZ TERRE ET CIEL",
    ville: "YUTZ",
    lng: 6.191928,
    lat: 49.361506,
  },
  {
    nom: "MONTGOLFIÈRE DU PAYS DE LA ZORN",
    ville: "GRASSENDORF",
    lng: 7.614116,
    lat: 48.81927,
  },
  {
    nom: "ASSOCIATION MONTGOLFIÈRE AU GRÉ DU VENT",
    ville: "SAUCY SUR MEURTHE",
    lng: 6.96043,
    lat: 48.237694,
  },
  {
    nom: "AÉROSPORTS",
    ville: "HAZEBROUCK",
    lng: 2.536033,
    lat: 50.722611,
  },
  {
    nom: "AQUILON",
    ville: "AVELIN",
    lng: -46.265023,
    lat: -24.001783,
  },
  {
    nom: "CLUB BALLONS LIBRES DU NORD",
    ville: "VILLENEUSE D'ASCQ",
    lng: 3.1314,
    lat: 50.619317,
  },
  {
    nom: "CLUB MONTGOLFIÈRES PASSION",
    ville: "HAZEBROUCK",
    lng: 2.536033,
    lat: 50.722611,
  },
  {
    nom: "MONTGOLFIÈRE DU NORD",
    ville: "FRETIN",
    lng: 3.135052,
    lat: 50.559596,
  },
  {
    nom: "VALENCIENNES DYNAMITE",
    ville: "VALENCIENNES",
    lng: 3.523485,
    lat: 50.357932,
  },
  {
    nom: "VUE DU CIEL MONTGOLFIÈRES",
    ville: "PIERREFONDS",
    lng: -73.850826,
    lat: 45.485496,
  },
  {
    nom: "AIR OPALE",
    ville: "BOULOGNE SUR MER",
    lng: 1.611877,
    lat: 50.725999,
  },
  {
    nom: "LES BALLONS DE WIMEREUX",
    ville: "WIMEREUX",
    lng: 1.611937,
    lat: 50.769653,
  },
  {
    nom: "LES MONTGOLFIÈRES DE L'ARTOIS",
    ville: "BEAURAINS",
    lng: 2.788699,
    lat: 50.263263,
  },
  {
    nom: "AMIENS BALLOON",
    ville: "SAINS EN AMIENOIS",
    lng: 2.319747,
    lat: 49.815778,
  },
  {
    nom: "ASSOCIATION MONTGOLFIÈRE EN BASSÉE MONTOIS",
    ville: "CHATENAY SUR SEINE",
    lng: 3.096147,
    lat: 48.417681,
  },
  {
    nom: "BALLON PASSION",
    ville: "ESBLY",
    lng: 2.813073,
    lat: 48.900965,
  },
  {
    nom: "MONTGOLFIÈRE EN SEINE ET LOING",
    ville: "MORET SUR LOING",
    lng: 2.588631,
    lat: 48.579468,
  },
  {
    nom: "ASSOCIATION MONTGOLFIÈRE.COM",
    ville: "VERNOU LA CELLE SUR SEINE",
    lng: 2.847017,
    lat: 48.388917,
  },
  {
    nom: "MONTGOLFIÈRE CLUB DE CAEN",
    ville: "CAEN",
    lng: -0.363562,
    lat: 49.18134,
  },
  {
    nom: "AIRON'AIR",
    ville: "ST HILAIRE DU HARCOUET",
    lng: -1.092736,
    lat: 48.576771,
  },
  {
    nom: "SELUNE MONTGOLFIÈRES",
    ville: "POILLEY",
    lng: -1.309957,
    lat: 48.618365,
  },
  {
    nom: "MONTGOLFIÈRES CLUB DE L'ORNE",
    ville: "CONDE SUR SARTHE",
    lng: 0.034101,
    lat: 48.432537,
  },
  {
    nom: "CLUB AÉROSTATIQUE DU PAYS DE CAUX",
    ville: "FECAMP",
    lng: 0.374891,
    lat: 49.757773,
  },
  {
    nom: "MONTGOLFIÈRE ÉVASION",
    ville: "PLASSAC ROUFFIAC",
    lng: 0.064472,
    lat: 45.527223,
  },
  {
    nom: "CHARENTE MONTGOLFIÈRES",
    ville: "CÔTEAUX DU BLANCAZAIS",
    lng: -74.178201,
    lat: 45.296396,
  },
  {
    nom: "FRANÇOIS RIVIÈRE AÉROSTAT CLUB",
    ville: "ST JEAN D'ANGLE",
    lng: -0.945556,
    lat: 45.82,
  },
  {
    nom: "ENVOL MONTGOLFIÈRE",
    ville: "SAINTES",
    lng: -0.630067,
    lat: 45.746066,
  },
  {
    nom: "MONTGOLFIÈRES DU PÉRIGORD",
    ville: "LA ROQUE GAGEAC",
    lng: 1.183415,
    lat: 44.825574,
  },
  {
    nom: "PÉRIGORD DORDOGNE MONTGOLFIÈRES",
    ville: "CASTELNAUD LA CHAPELLE",
    lng: 1.151202,
    lat: 44.813797,
  },
  {
    nom: "LE CIEL EST À TOI",
    ville: "LARUSCADE",
    lng: -0.34118,
    lat: 45.10735,
  },
  {
    nom: "BORDEAUX MONTGOLFIÈRE",
    ville: "MERIGNAS",
    lng: -0.08701,
    lat: 44.784663,
  },
  {
    nom: "MONTGOLFIÈRES EN THOUARSAIS",
    ville: "ST JEAN DE THOUARS",
    lng: -0.213894,
    lat: 46.964463,
  },
  {
    nom: "AIR ET NATURE 86",
    ville: "SAINT CHRISTOPHE",
    lng: -75.124541,
    lat: 40.006705499999995,
  },
  {
    nom: "ATELIER DE L'AÉROSTATION",
    ville: "CENON SUR VIENNE",
    lng: 0.535753,
    lat: 46.774295,
  },
  {
    nom: "BALADE EN MONTGOLFIÈRE",
    ville: "CHATELLERAULT",
    lng: 0.545812,
    lat: 46.818005,
  },
  {
    nom: "COUPE D'EUROPE DE MONTGOLFIÈRES",
    ville: "CFM",
    lng: -47.948119,
    lat: -15.798494,
  },
  {
    nom: "LE VENT DE LA LIBERTÉ",
    ville: "SENILLE SAINT SAUVEUR",
    lng: 0.623594,
    lat: 46.808424,
  },
  {
    nom: "PLUS LÉGER QUE L'AIR",
    ville: "MARIGNY BRIZAY",
    lng: -90.058456,
    lat: 29.967492,
  },
  {
    nom: "MONTGOLFIÈRE CENTRE ATLANTIQUE",
    ville: "THURÉ – NACELLE ACCESSIBLE",
    lng: -149.864717,
    lat: 61.148948,
  },
  {
    nom: "HAUTE VIENNE MONTGOLFIÈRE CLUB",
    ville: "CHATEAUPONSAC",
    lng: 1.276409,
    lat: 46.134024,
  },
  {
    nom: "LA MONTGOLFIERE POUR TOUS",
    ville: "JONZAC",
    lng: -0.432098,
    lat: 45.445447,
  },
  {
    nom: "LES BALLONS D'AUTAN",
    ville: "SALLES COURBATIES",
    lng: 2.07887,
    lat: 44.473,
  },
  {
    nom: "VOL LIBRE EN BALLON",
    ville: "LA ROQUETTE",
    lng: 4.622532,
    lat: 43.675382,
  },
  {
    nom: "COMPAGNIE CÉLESTE",
    ville: "BLAUZAC",
    lng: 4.3690809999999995,
    lat: 43.963214,
  },
  {
    nom: "MONTGOLFIÈRE CLUB DE LA MÉDITERRANÉE",
    ville: "NÎMES",
    lng: 4.3595932,
    lat: 43.8348712,
  },
  {
    nom: "LES MONTGOLFIÈRES OCCITANES DE TOULOUSE",
    ville: "VIEILLE TOULOUSE",
    lng: 1.445525,
    lat: 43.527146,
  },
  {
    nom: "LIB'HÉLIOS",
    ville: "MIRANDE",
    lng: 0.40515921586828396,
    lat: 43.51547895179947,
  },
  {
    nom: "QUERCY MONTGOLFIÈRE",
    ville: "CASTELNAU MONTRATIER SAINTE ALAUZE",
    lng: 1.35433,
    lat: 44.270504,
  },
  {
    nom: "ROCAMADOUR AÉROSTAT",
    ville: "ROCAMADOUR",
    lng: 1.618352,
    lat: 44.799188,
  },
  {
    nom: "MONTGOLFIERES D'OCCITANIE & D'AILLEURS",
    ville: "BLAGNAC",
    lng: 1.37799,
    lat: 43.6434,
  },
  {
    nom: "ATMOSPH'AIR MONTGOLFIÈRES OCCITANES",
    ville: "ALBI – NACELLE ACCESSIBLE",
    lng: -149.864717,
    lat: 61.148948,
  },
  {
    nom: "CIEL DE LOIRE",
    ville: "DIVATTE SUR LOIRE",
    lng: -1.335123,
    lat: 47.274775,
  },
  {
    nom: "ASSOCIATION ARC EN CIEL D'ANJOU",
    ville: "BRISSAC QUINCÉ",
    lng: -0.448344,
    lat: 47.356357,
  },
  {
    nom: "ASSOCIATION AÉROSTATIQUE DE SABLÉ SUR SARTHE",
    ville: "SABLÉ SUR SARTHE",
    lng: -0.335205,
    lat: 47.840071,
  },
  {
    nom: "NEW SPIRIT SARL",
    ville: "ALTWIES",
    lng: 6.106392,
    lat: 49.615347,
  },
  {
    nom: "EASYBALLOON.CH",
    ville: "SORENS",
    lng: -93.354983,
    lat: 44.943466,
  },
];

var parachute = [
  {
    name: "CERPS DE TALLARD",
    link: "http://parachutismegap.fr",
    address: "PIERRE GEORGES LATECOERE, 05130 TALLARD",
    lat: "44.4557158236886140",
    lng: "6.0342126506347995",
    price: "349",
  },
  {
    name: "SKYDIVE CENTER",
    link: "http://www.skydivecenter.fr",
    address: "PIERRE GEORGES LATECOERE, 05130 TALLARD",
    lat: "44.4583233679430660",
    lng: "6.0371845382233005",
    price: "349",
  },
  {
    name: "CEPS DE L'ARIEGE",
    link: "http://www.parachutisme-pamiers.fr",
    address: "AERODROME DE PAMIERS LES PUJOLS, 09101 PAMIERS",
    lat: "43.0922281290379300",
    lng: "1.6996622085571290",
    price: "349",
  },
  {
    name: "SARL SKYDIVE OCCITANIE",
    link: "http://www.skydive-flyzone.com",
    address: "PLAINE CONILHAC, 11200 LEZIGNAN CORBIERES",
    lat: "43.1735305071501050",
    lng: "2.7381206723389370",
    price: "349",
  },
  {
    name: "PARACHUTE CLUB D'AIX",
    link: "Parachute club aix",
    address: "DE LA PLAINE, 13590 MEYREUIL",
    lat: "43.5038030303007800",
    lng: "5.3716385364532470",
    price: "349",
  },
  {
    name: "SARL EUROPHENIX 17",
    link: "http://www.europhenix17.fr",
    address: "JOSEPH LELEE, 17600 MEDIS",
    lat: "45.6334411946997400",
    lng: "-0.9764850139617920",
    price: "349",
  },
  {
    name: "EP NORD FRANCHE COMTE",
    link: "http://www.paraclub-montbeliard.fr",
    address:
      "AERODROME DE COURCELLES LES MONTBELIARD, 25420 COURCELLES LES MONTBELIARD",
    lat: "47.4888324960838940",
    lng: "6.7917609214782715",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME BESANCON FRANCHE-COMTE",
    link: "http://www.parabesancon.fr",
    address: "DE L'AERODROME, 25660 LA VEZE",
    lat: "47.2069183228570800",
    lng: "6.0759437084198000",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME BRETAGNE ATLANTIQUE",
    link: "http://epba-parachutisme.sportsregions.fr",
    address: "DE LA LIBERATION, 29390 SCAER",
    lat: "48.0533700000000000",
    lng: "-3.6636250000000000",
    price: "349",
  },
  {
    name: "SKYDIVE PUJAUT ECOLE FRANCAISE DE PARACHUTISME",
    link: "http://www.skydive-pujaut.com",
    address: "AERODROME DE PUJAUT, 30131 PUJAUT",
    lat: "44.0001640949455800",
    lng: "4.7557121515274050",
    price: "349",
  },
  {
    name: "EPS DU BASSIN D'ARCACHON",
    link: "https://paraclub-arcachon.com/",
    address: ", 33260 LA TESTE DE BUCH",
    lat: "44.5982312279642500",
    lng: "-1.1156219244003296",
    price: "349",
  },
  {
    name: "SARL ESPACE CHUTE LIBRE",
    link: "http://www.parachute-gironde.fr",
    address: "AERODROME DE LA REOLE FLOUDES, 33190 LA REOLE",
    lat: "44.5663183003265700",
    lng: "-0.0544166564941406",
    price: "349",
  },
  {
    name: "ECOLE FRANCAISE DE PARACHUTISME LE BLANC",
    link: "http://www.efpleblanc.com",
    address: "AERODROME, 36300 LE BLANC",
    lat: "46.6257925409057600",
    lng: "1.0874143327941965",
    price: "349",
  },
  {
    name: "CENTRE ECOLE PARACHUTISME DE GRENOBLE",
    link: "https://www.parachutisme38.fr",
    address: "DE ST HILAIRE, 38590 ST ETIENNE DE ST GEOIRS",
    lat: "45.3664122590131400",
    lng: "5.3273525834083560",
    price: "349",
  },
  {
    name: "SARL OJB PARACHUTISME",
    link: "http://www.ojbpara.com",
    address: "ANDREE DUPEYRON, 40200 MIMIZAN",
    lat: "44.1482452809368000",
    lng: "-1.1632376349487004",
    price: "349",
  },
  {
    name: "ASPL/CEP DE SAINT ETIENNE SAINT GALMIER",
    link: "http://www.para42.fr/",
    address: "ANDRE MALRAUX, 42000 ST ETIENNE",
    lat: "45.6059770000000000",
    lng: "4.3039260000000000",
    price: "349",
  },
  {
    name: "PARA CLUB DU PUY",
    link: "http://paraclubdupuy.free.fr",
    address: "AEROPORT PUY LOUDES, 43320 CHASPUZAC",
    lat: "45.0787861648801850",
    lng: "3.7602746486663820",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME DU CENTRE ORLEANS LOIRET",
    link: "http://www.parachutisme-orleans.net",
    address: "AEROPORT DU LOIRET, 45550 ST DENIS DE L HOTEL",
    lat: "47.9022536853690540",
    lng: "2.1681797504425050",
    price: "349",
  },
  {
    name: "CENTRE ECOLE DE PARACHUTISME DE CAHORS",
    link: "http://www.parachutisme.com",
    address: "AERODROME DE CAHORS LALBENQUE, 46230 CIEURAC",
    lat: "44.3476831155295400",
    lng: "1.4782941341400146",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME D'AGEN",
    link: "http://www.agen-parachutisme.org",
    address: "AERODROME AGEN LA GARENNE, 47520 LE PASSAGE",
    lat: "44.1769643884327800",
    lng: "0.5897415967895085",
    price: "349",
  },
  {
    name: "ECOLE FRANCAISE DE PARACHUTISME DE SAUMUR",
    link: "http://www.saumur-parachutisme.com",
    address: "DES LANDES DE TERREFORT, 49400 SAUMUR",
    lat: "47.2547380000000000",
    lng: "-0.1084579999999999",
    price: "349",
  },
  {
    name: "PARACHUTISME LAVAL CENTRE ECOLE MAYENNE",
    link: "http://www.parachutismelaval.fr",
    address: "DE L'ETRONNIER, 53000 LAVAL",
    lat: "48.0297890351424300",
    lng: "-0.7444915090331960",
    price: "349",
  },
  {
    name: "EFP  NANCY LORRAINE",
    link: "http://www.paranancy.com",
    address: "AERODROME DE NANCY AZELOT, 54210 AZELOT",
    lat: "48.5943499967657640",
    lng: "6.2433457374572750",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME SPORTIF DE VANNES BRETAGNE",
    link: "http://www.parachutisme-vannes.fr",
    address: "KERSIMON, 56250 MONTERBLANC",
    lat: "47.7264209979292600",
    lng: "-2.7234506607055664",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME SPORTIF DE MOSELLE",
    link: "http://www.epsmoselle.com",
    address: ", 57232 BITCHE",
    lat: "49.1510048273236200",
    lng: "5.9350204467773440",
    price: "349",
  },
  {
    name: "SARL CENTRE DE PARACHUTISME PARIS NEVERS",
    link: "https://www.paraparisnevers.fr",
    address: ", 58470 GIMOUILLE",
    lat: "46.8595777001330500",
    lng: "3.1611695970764230",
    price: "349",
  },
  {
    name: "ECOLE FRANCAISE DE PARACHUTISME LILLE BONDUES",
    link: "http://www.parachutisme-lille.fr",
    address: "AERODROME DE LILLE MARCQ, 59910 BONDUES",
    lat: "50.6859363195305000",
    lng: "3.0782318115234375",
    price: "349",
  },
  {
    name: "C.E.R.P. DE MAUBEUGE",
    link: "http://www.skydivemaubeuge.fr",
    address: "AERODROME DE LA SALMAGNE, 59600 VIEUX RENG",
    lat: "50.3133249040751500",
    lng: "4.0337848663330080",
    price: "349",
  },
  {
    name: "ESPACE INTERCLUBS EUROPEEN DE PARACHUTISME SPORTIF",
    link: "http://www.skydivefretoy.com",
    address: "DU BOIS DE HAM, 60640 FRETOY LE CHATEAU",
    lat: "49.6657390387505660",
    lng: "2.9692697525024414",
    price: "349",
  },
  {
    name: "CENTRE ECOLE REGIONAL PARACHUTISME LENS",
    link: "http://www.lens-parachutisme.com",
    address: "AERODROME DE LENS BENIFONTAINE, 62410 BENIFONTAINE",
    lat: "50.4676121057989300",
    lng: "2.8223705291748047",
    price: "349",
  },
  {
    name: "PAU PARACHUTISME PASSION",
    link: "http://www.pau-skydive.fr",
    address: ", 64450 LASCLAVERIES",
    lat: "43.4273672551936500",
    lng: "-0.2884769439697266",
    price: "349",
  },
  {
    name: "CENTRE ECOLE PARACHUTISME DE LA BIGORRE",
    link: "https://www.parachutisme-tarbes.fr/",
    address: "AERODROME DE LALOUBERE, 65310 LALOUBERE",
    lat: "43.2173270000000000",
    lng: "0.0749090000000000",
    price: "349",
  },
  {
    name: "AIR 65",
    link: "www.air65-parachutisme.fr",
    address: "AERODROME, 65230 CASTELNAU MAGNOAC",
    lat: "43.2772209442812500",
    lng: "0.5168294906616211",
    price: "349",
  },
  {
    name: "CENTRE ECOLE REGIONAL DE PARACHUTISME ALSACE",
    link: "http://www.alsace-para.com",
    address: "DE LA MUSAU, 67100 STRASBOURG",
    lat: "48.5555211000000000",
    lng: "7.7846793999999590",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME DE LYON-CORBAS",
    link: "http://parachutisme-lyon.fr",
    address: "CLEMENT ADER, 69960 CORBAS",
    lat: "45.6582400392444540",
    lng: "4.9137715662704980",
    price: "349",
  },
  {
    name: "EURL ANGEL PARACHUTISME",
    link: "http://www.parachutisme71.com",
    address: ", 71530 CHAMPFORGEUIL",
    lat: "46.8279212397156200",
    lng: "4.8261064465575600",
    price: "349",
  },
  {
    name: "SARL SAVOIE PARACHUTISME",
    link: "http://www.savoieparachutisme.com",
    address: "AEROPORT DE CHAMBERY AIX LES BAINS, 73420 VIVIERS DU LAC",
    lat: "45.6393375335018000",
    lng: "5.8774709701538090",
    price: "349",
  },
  {
    name: "SARL AIR LIBRE PARACHUTISME",
    link: "http://www.airlibre-parachutisme.com",
    address: "AERODROME DE DIEPPE, 76550 ST AUBIN SUR SCIE",
    lat: "49.8848260298142600",
    lng: "1.0857903957366943",
    price: "349",
  },
  {
    name: "DEMENCIEL PARACHUTISME",
    link: "http://www.demencielparachutisme.com",
    address: "AERODROME NIORT MARAIS-POITEVIN, 79000 NIORT",
    lat: "46.3137860414568000",
    lng: "-0.3955039296874929",
    price: "349",
  },
  {
    name: "SARL CP PARIS PERONNE HAUTE SOMME",
    link: "http://www.parachute-peronne.fr",
    address: "DE L'EUROPE, 80201 PERONNE",
    lat: "49.8709499731788300",
    lng: "3.0409353809204730",
    price: "349",
  },
  {
    name: "ECOLE DE PARACHUTISME MIDI PYRENEES",
    link: "http://www.bouloc-skydive.com",
    address: "AERODROME CARDENAL, 82110 BOULOC",
    lat: "44.3063147699613000",
    lng: "1.0788702964782715",
    price: "349",
  },
  {
    name: "CENTRE ECOLE DE PARACHUTISME COTE D'AZUR",
    link: "https://www.parachute-cote-azur.fr",
    address: "AERODROME LE LUC LE CANNET, 83340 LE CANNET DES MAURES",
    lat: "43.3863140000000000",
    lng: "6.3758200000000000",
    price: "349",
  },
  {
    name: "PARIS JUMP",
    link: "http://www.parisjump.com",
    address: "AERODROME DE SAINT FLORENTIN CHEU, 89600 CHEU",
    lat: "47.9813619268976840",
    lng: "3.7715721130371094",
    price: "349",
  },
  {
    name: "P.C. DE BOURBON/C.E.P. DE LA REUNION",
    link: "https://www.parabourbon.fr",
    address: "DE L'AEROPORT, 97410 BASSE TERRE",
    lat: "-21.3206406603121400",
    lng: "55.4276561737060550",
    price: "349",
  },
  {
    name: "SARL CARAIBE PARACHUTISME",
    link: "http://www.caraibeparachutisme.com",
    address: "DU GOLF, 00000 ST FRANCOIS",
    lat: "16.2573135140656650",
    lng: "-61.2658371556259450",
    price: "349",
  },
  {
    name: "SAS BLOWIND",
    link: "http://www.sautparachute-paris.com/",
    address: "AERODROME DE BRIENNE LE CHATEAU, 10500 PRECY SAINT MARTIN",
    lat: "48.3931040000000000",
    lng: "4.5261540000000196",
    price: "349",
  },
  {
    name: "SEPTIEME CIEL",
    link: "http://www.septiemeciel-parachutisme.fr",
    address: "DE L'AERODROME, 22300 LANNION",
    lat: "48.7512931220786640",
    lng: "-3.4666434564056770",
    price: "349",
  },
  {
    name: "SAS ALBI PARACHUTISME",
    link: "http://www.albi-parachutisme.fr",
    address: "AERODROME LE SEQUESTRE, 81990 LE SEQUESTRE",
    lat: "43.9151220000000000",
    lng: "2.1173570000000000",
    price: "349",
  },
  {
    name: "SAS REBORN",
    link: "https://www.saut-en-parachute-merville.net/",
    address: "CENTRE D'AFFAIRES DE LORLOGERIE, 62401 BETHUNE",
    lat: "50.6274820000000000",
    lng: "2.6475330000000000",
    price: "349",
  },
  {
    name: "VERTICAL T'AIR PARACHUTISME",
    link: "http://www.verticaltair.com",
    address: "AIME BROUSTAUT, 33470 GUJAN MESTRAS",
    lat: "44.5965950000000000",
    lng: "-1.1164640000000000",
    price: "349",
  },
  {
    name: "SARL BIG'AIR PARACHUTISME",
    link: "https://bigair-parachutisme.fr",
    address: "AERODROME DE ROCHEFORT, 17620 ST AGNANT",
    lat: "45.8894110000000000",
    lng: "-0.9825040000000000",
    price: "349",
  },
];

function extractName(str) {
  var parts = str.split(/\b\d{5}\b/); // split by 5-digit number

  if (parts.length === 2) {
    var before = parts[0]
      .trim()
      .split(" ")
      .filter((word) => word.length > 0);
    var after = parts[1]
      .trim()
      .split(" ")
      .filter((word) => word.length > 0);

    if (before.length > 0) {
      return before.pop();
    } else if (after.length > 0) {
      return after[0];
    }
  }

  return null; // in case of no match
}

const VacationDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [location, setLocation] = useState(null);
  const [time, setTime] = useState(null);

  useEffect(() => {
    console.log("Product ID:", id); // Pour vérifier l'ID
    if (id) {
      // Fetch product details from the API using the ID from the URL
      axios
        .get(`https://esifly.fr/api/products/?id=${id}`)
        .then((response) => {
          const data = response.data;
          if (data && data.fields) {
            setProduct(data);
            if (
              data.fields.sub_category === "Balade aérienne" ||
              data.fields.name === "Vol Disneyland Paris"
            ) {
              setLocation("");
              setTime(data.fields.time + " min");
            } else {
              setTime(data.fields.time + " min");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
        });
    }

    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const banner = document.querySelector(".banner img");
          if (banner) {
            const scrollY = window.scrollY;
            const scale = 1 + scrollY / 1000; // Ajuster le facteur de zoom si nécessaire
            banner.style.transform = `scale(${scale})`;
          }
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id]);

  useEffect(() => {
    if (product && product.fields) {
      const { category, sub_category } = product.fields;
      if (sub_category !== "Balade aérienne") {
        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/outdoors-v12",
          center: [2.2137, 46.2276],
          zoom: 5,
        });

        map.on("load", () => {
          if (category === "Avions" || category === "Mariages") {
            avion.forEach((point) => {
              if (point.Latitude && point.Longitude) {
                const popupNode = document.createElement("div");
                popupNode.innerHTML = `
                <div class="popup-content">
                  <h3>${point.Nom}</h3>
                  <a class="popup-button">Valider</a>
                </div>
              `;

                const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                  popupNode
                );

                new mapboxgl.Marker()
                  .setLngLat([point.Longitude, point.Latitude])
                  .setPopup(popup)
                  .addTo(map);

                popupNode.querySelector("a").addEventListener("click", (e) => {
                  e.preventDefault();
                  // navigate(`/vacation-details/48/${point.Nom.split(' / ')[0]}`);
                  setLocation(point.Nom.split(" / ")[0]);
                  popup.remove();
                });
              } else {
                console.error("Invalid coordinates for point:", point);
              }
            });
          } else if (category === "Hélicoptères") {
            helicoptere.forEach((point) => {
              if (point.lat && point.lng) {
                const locationName = extractName(point.address);
                const popupNode = document.createElement("div");
                popupNode.innerHTML = `
                <div class="popup-content">
                  <h3>${locationName?.split(",")[0]}</h3>
                  <a class="popup-button">Valider</a>
                </div>
              `;

                const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                  popupNode
                );

                new mapboxgl.Marker()
                  .setLngLat([point.lng, point.lat])
                  .setPopup(popup)
                  .addTo(map);

                popupNode.querySelector("a").addEventListener("click", (e) => {
                  e.preventDefault();
                  // navigate(`/vacation-details/52/${locationName.split(' / ')[0].split(',')[0]}`);
                  setLocation(locationName.split(" / ")[0].split(",")[0]);
                  popup.remove();
                });
              } else {
                console.error("Invalid coordinates for point:", point);
              }
            });
          } else if (category === "Montgolfière") {
            montgolfiere.forEach((point) => {
              if (point.lat && point.lng) {
                const locationName = point.ville;
                const popupNode = document.createElement("div");
                popupNode.innerHTML = `
                <div class="popup-content">
                  <h3>${locationName}</h3>
                  <a class="popup-button">Valider</a>
                </div>
              `;

                const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                  popupNode
                );

                new mapboxgl.Marker()
                  .setLngLat([point.lng, point.lat])
                  .setPopup(popup)
                  .addTo(map);

                popupNode.querySelector("a").addEventListener("click", (e) => {
                  e.preventDefault();
                  // navigate(`/vacation-details/19/${locationName.split(' / ')[0]}`);
                  setLocation(locationName.split(" / ")[0]);
                  popup.remove();
                });
              } else {
                console.error("Invalid coordinates for point:", point);
              }
            });
          } else if (category === "Sport & Aventure") {
            parachute.forEach((point) => {
              if (point.lat && point.lng) {
                const locationName = extractName(point.address);
                const popupNode = document.createElement("div");
                popupNode.innerHTML = `
                <div class="popup-content">
                  <h3>${locationName?.split(",")[0]}</h3>
                  <a class="popup-button">Valider</a>
                </div>
              `;

                const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                  popupNode
                );

                new mapboxgl.Marker()
                  .setLngLat([point.lng, point.lat])
                  .setPopup(popup)
                  .addTo(map);

                popupNode.querySelector("a").addEventListener("click", (e) => {
                  e.preventDefault();
                  // navigate(`/vacation-details/27/${locationName.split(' / ')[0].split(',')[0]}`);
                  setLocation(locationName.split(" / ")[0].split(",")[0]);
                  popup.remove();
                });
              } else {
                console.error("Invalid coordinates for point:", point);
              }
            });
          } else if (category === "Clip & Film") {
            avion.forEach((point) => {
              if (point.Latitude && point.Longitude) {
                const popupNode = document.createElement("div");
                popupNode.innerHTML = `
                <div class="popup-content">
                  <h3>${point.Nom}</h3>
                  <a class="popup-button">Valider</a>
                </div>
              `;

                const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
                  popupNode
                );

                new mapboxgl.Marker()
                  .setLngLat([point.Longitude, point.Latitude])
                  .setPopup(popup)
                  .addTo(map);

                popupNode.querySelector("a").addEventListener("click", (e) => {
                  e.preventDefault();
                  // navigate(`/vacation-details/48/${point.Nom.split(' / ')[0]}`);
                  setLocation(point.Nom.split(" / ")[0]);
                  popup.remove();
                });
              } else {
                console.error("Invalid coordinates for point:", point);
              }
            });
          }
        });
      }
    }
  }, [product]);

  if (!product) {
    return "";
  }

  const { fields } = product;
  const productImage =
    fields.Photos && fields.Photos.length > 0 ? fields.Photos[0].url : "";

  return (
    <div className="scrollbar-hidden">
      <main className="details vacation-details">
        <section className="banner w-100">
          <img
            src={productImage || "../assets/images/details/banner-1.png"}
            alt="Banner"
            className="w-100 img-fluid"
          />
          <div className="page-title">
            <Link
              to={-1}
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowLeftBlack} alt="arrow" />
            </Link>
            <h3 className="main-title">{fields.name}</h3>
          </div>
        </section>

        <section className="details-body">
          <section className="d-flex align-items-center gap-8 details-title">
            <div className="flex-grow">
              <h3>{fields.name}</h3>
              <ul className="d-flex align-items-center gap-8">
                <li className="d-flex align-items-center gap-04">
                  <img src={timeMarker} alt="icon" />
                  <p>{time}</p>
                </li>
                {fields?.sub_category !== "Balade aérienne" && fields?.name !== "Vol Disneyland Paris" ? (
                  <li className="d-flex align-items-center gap-04">
                    <img src={mapMarker} alt="icon" />
                    <p>{location}</p>
                  </li>
                ) : (
                  ""
                )}
                <li className="d-flex align-items-center gap-04">
                  <img src={starYellow} alt="icon" />
                  <p>
                    <span>4.4</span> (41 Reviews)
                  </p>
                </li>
              </ul>
            </div>
            <span className="d-flex align-items-center justify-content-center rounded-full shrink-0">
              <img src={heartRedLight} alt="" />
            </span>
          </section>

          <section className="details-info pt-32 pb-16">
            <div className="title">
              <h4>Details</h4>
            </div>
            <p dangerouslySetInnerHTML={{ __html: fields.description }} />
          </section>

          {/* <section className="guide py-16">
            <div className="title">
              <h4>Tour Guide</h4>
            </div>

            <div className="d-flex gap-24 all-cards scrollbar-hidden">
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src={guide1} alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src={starYellow} alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
                <div className="content">
                  <h4>Emilia Ricardo</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src={mapBlack} alt="icon" />
                    Polynesia, French
                  </p>
                </div>
              </Link>

              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src={guide2} alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src={starYellow} alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
                <div className="content">
                  <h4>Jonsky Alexia</h4>
                  <h5>$30 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src={mapBlack} alt="icon" />
                    South America
                  </p>
                </div>
              </Link>
            </div>
          </section> */}

          {/* <section className="reviews py-16">
            <div className="title d-flex align-items-center justify-content-between">
              <h4 className="shrink-0">Review</h4>
              <Link to="/review" className="shrink-0 d-inline-block">See All</Link>
            </div>

            <div className="review-card d-flex gap-16">
              <div className="shrink-0 image overflow-hidden rounded-full">
                <img src={reviewAvatar} alt="Avatar" className="img-fluid w-100" />
              </div>

              <div className="flex-grow">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Jhone Kenoady</h4>
                  <span className="d-inline-block">23 Nov 2022</span>
                </div>
                <ul className="d-flex align-items-center gap-8">
                  <li><img src={starYellow} alt="icon" /></li>
                  <li><img src={starYellow} alt="icon" /></li>
                  <li><img src={starYellow} alt="icon" /></li>
                  <li><img src={starYellow} alt="icon" /></li>
                  <li><img src={starYellow} alt="icon" /></li>
                </ul>
                <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              </div>
            </div>
          </section> */}
          {fields?.sub_category !== "Balade aérienne" ? (
            <section className="details-location pt-16">
              <div className="title">
                <h4>Choisir un lieu</h4>
              </div>
              <div className="overflow-hidden radius-16 map">
                {/* <iframe
                title="Google Maps Los Angeles"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d846588.5514550178!2d-118.35899906676545!3d34.01855672774309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1701149305360!5m2!1sen!2sbd"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe> */}
                <div
                  id="map"
                  className="map"
                  style={{ width: "100%", height: "100vh" }}
                ></div>
              </div>
            </section>
          ) : (
            ""
          )}
        </section>

        <section className="details-footer d-flex align-items-center justify-content-between gap-8 w-100">
          <p>
            {fields.price}€ <span>/Personne</span>
          </p>
          {/* {location === null ? (
            ""
          ) : ( */}
          <Link to={`/checkout-vacation/${id}/${location || "Localisation"}`}>Continuer</Link>
          {/* )} */}
        </section>
      </main>
    </div>
  );
};

export default VacationDetails;
