import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';
import infoIcon from '../../assets/svg/i.svg';

const ForgotPasswordP = () => {
  return (
    <div className="scrollbar-hidden">
      <main className="profile-main">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Forgot Password</h3>
        </div>

        <section className="user-info forgot-password px-24">
          <blockquote className="d-flex align-items-center gap-16 p-16 radius-8">
            <img src={infoIcon} alt="icon" />
            <p>We will send the OTP code to your email for security in forgetting your password</p>
          </blockquote>

          <div className="mt-24">
            <label className="input-label">E-mail</label>
            <input type="text" placeholder="E-mail" value="example@mail.com" className="input" />
          </div>
        </section>

        <div className="submit-btn px-24 pt-24 pb-36 bottom-btn">
          <button type="button" className="btn-primary">Submit</button>
        </div>
      </main>
    </div>
  );
};

export default ForgotPasswordP;
