import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

const Security = () => {
  return (
    <div className="scrollbar-hidden">
      <main>
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Sécurité</h3>
        </div>

        <section className="msg-notifications px-24">
          <div className="border-box">
            <h5>Notifications</h5>
            <ul>
              <li className="d-flex align-items-center justify-content-between">
                <p>Face ID</p>
                <label className="toggle-switch">
                  <input type="checkbox" className="mode-switch" defaultChecked />
                  <span className="slider"></span>
                </label>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <p>Remember Password</p>
                <label className="toggle-switch">
                  <input type="checkbox" className="mode-switch" defaultChecked />
                  <span className="slider"></span>
                </label>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <p>Touch ID</p>
                <label className="toggle-switch">
                  <input type="checkbox" className="mode-switch" defaultChecked />
                  <span className="slider"></span>
                </label>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Security;
