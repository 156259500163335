import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importez useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/ticket.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import axios from 'axios';
import { AuthContext } from '../AuthProvider'; 
import { parse } from 'date-fns';
import fr from 'date-fns/locale/fr';

gsap.registerPlugin(Draggable);

const TicketBooked = () => {
  const { user } = useContext(AuthContext); 
  const [bookedTickets, setBookedTickets] = useState([]);
  const [historyTickets, setHistoryTickets] = useState([]);
  const navigate = useNavigate(); // Utilisez useNavigate

  const parseDate = (dateString) => {
    const dateWithoutAt = dateString.replace(' à ', ' ');
    const formatString = "EEEE d MMMM yyyy HH:mm";
    return parse(dateWithoutAt, formatString, new Date(), { locale: fr });
  };

  useEffect(() => {
    if (user && user.email) {
      console.log("Fetching reservations for:", user.email);
      axios.post('https://esifly.fr/api/get_reservations/index.php', { email: user.email })
        .then(response => {
          console.log("API response:", response.data);
          if (response.data.success) {
            console.log("Reservations data:", response.data.data);
            const currentDate = new Date();
            const booked = response.data.data.filter(order => parseDate(order.date) > currentDate);
            const history = response.data.data.filter(order => parseDate(order.date) <= currentDate);
            console.log("Booked Tickets after filtering:", booked);
            console.log("History Tickets after filtering:", history);
            setBookedTickets(booked);
            setHistoryTickets(history);
          } else {
            console.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching reservations:', error);
        });
    }

    $("#datepicker").datepicker();
    Draggable.create(".draggable");

    if (window.mixitup) {
      window.mixitup('.container');
    }
  }, [user]);

  const handleClick = (event) => {
    event.preventDefault();
    console.log('Link clicked');
  };

  const handleDetailClick = (token) => {
    navigate('/ticket-detail', { state: { token } });
  };

  return (
    <div className="scrollbar-hidden">
      <main className="ticket">
        <div className="page-title">
          <h3 className="main-title">Mes réservations</h3>
        </div>
        <section className="ticket-tab">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="booked-tab" data-bs-toggle="tab" data-bs-target="#booked-tab-pane" type="button" role="tab" aria-controls="booked-tab-pane" aria-selected="true">Réserver</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history-tab-pane" type="button" role="tab" aria-controls="history-tab-pane" aria-selected="false">Historique</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="booked-tab-pane" role="tabpanel" aria-labelledby="booked-tab" tabIndex="0">
              {bookedTickets.length > 0 ? bookedTickets.map((ticket, index) => (
                <div key={index} className="ticket-card radius-8">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <p>{ticket.date}</p>
                    <span>À venir</span>
                  </div>
                  <div className="card-item d-flex align-items-center gap-16 w-100">
                    <div className="image shrink-0 overflow-hidden radius-8">
                      <img src={ticket.product_image} alt="Place" className="img-fluid w-100 h-100 object-fit-cover" />
                    </div>
                    <div className="content flex-grow">
                      <h4>{ticket.product_name}</h4>
                      <p className="d-flex align-items-center gap-04 location mt-04">
                        <img src="/assets/svg/map-marker.svg" alt="icon" />
                        {ticket.city}, {ticket.country}
                      </p>
                      <p className="rating d-flex align-items-center gap-04 mt-16">
                        <img src="/assets/svg/star-yellow.svg" alt="icon" />
                        4.4 <span>(41 Reviews)</span>
                      </p>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <div>
                      <p>Total Price</p>
                      <h3>${ticket.price}</h3>
                    </div>
                    <button onClick={() => handleDetailClick(ticket.token)}>Détails</button>
                  </div>
                </div>
              )) : <p>Aucun billet</p>}
            </div>
            <div className="tab-pane fade" id="history-tab-pane" role="tabpanel" aria-labelledby="history-tab" tabIndex="0">
              {historyTickets.length > 0 ? historyTickets.map((ticket, index) => (
                <div key={index} className="ticket-card history radius-8">
                  <div className="card-title d-flex align-items-center justify-content-between">
                    <p>{ticket.date}</p>
                    <span>Fini</span>
                  </div>
                  <div className="card-item d-flex align-items-center gap-16 w-100">
                    <div className="image shrink-0 overflow-hidden radius-8">
                      <img src={ticket.product_image} alt="Place" className="img-fluid w-100 h-100 object-fit-cover" />
                    </div>
                    <div className="content flex-grow">
                      <h4>{ticket.product_name}</h4>
                      <p className="d-flex align-items-center gap-04 location mt-04">
                        <img src="/assets/svg/map-marker.svg" alt="icon" />
                        {ticket.city}, {ticket.country}
                      </p>
                      <p className="rating d-flex align-items-center gap-04 mt-16">
                        <img src="/assets/svg/star-yellow.svg" alt="icon" />
                        4.4 <span>(41 Reviews)</span>
                      </p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="top d-flex align-items-center justify-content-between">
                      <div>
                        <p>Total Price</p>
                        <h3>${ticket.price}</h3>
                      </div>
                      <button type="button" className="rating" data-bs-toggle="modal" data-bs-target="#reviewModal">Noter</button>
                    </div>
                    <a onClick={() => handleDetailClick(ticket.token)}>Détails</a>
                  </div>
                </div>
              )) : <p>Aucun historique de billet</p>}
            </div>
          </div>
        </section>
      </main>
      <div className="modal fade reviewModal bottomModal" id="reviewModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="modal-close rounded-full" data-bs-dismiss="modal" aria-label="Close">
                <img src="/assets/svg/close-black.svg" alt="Close" />
              </button>
              <h1 className="modal-title">Give a Review</h1>
            </div>
            <div className="modal-body">
              <ul className="ratingW d-flex align-items-center justify-content-center">
                {[...Array(5)].map((_, i) => (
                  <li key={i} className={i < 3 ? "on" : ""}>
                    <Link to="#" onClick={handleClick}>
                      <img src="/assets/svg/star-yellow-big.svg" className="star-yellow" alt="Star" />
                      <img src="/assets/svg/star-gray.svg" className="star-gray" alt="Star" />
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="msg">
                <h6>Detail Review</h6>
                <textarea placeholder="Give a Review"></textarea>
              </div>
              <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketBooked;
