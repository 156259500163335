import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

const UserLanguage = () => {
  return (
    <div className="scrollbar-hidden">
      <main>
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Langues</h3>
        </div>

        <section className="suggested px-24 pb-24">
          <div className="border-box">
            <h5>Langues suggérées</h5>
            <ul>
              <li>
                <label htmlFor="l-uk" className="custom-check-container language-container">
                  <input type="radio" name="user-lang" id="l-uk" defaultChecked />
                  <span className="checkmark"></span>
                  Français
                </label>
              </li>
            </ul>
          </div>
        </section>

        <section className="suggested px-24 pb-36">
          <div className="border-box">
            <h5>Autres langues</h5>
            <ul>
            <li>
                <label htmlFor="l-uk" className="custom-check-container language-container">
                  <input type="radio" name="user-lang" id="l-uk" defaultChecked />
                  <span className="checkmark"></span>
                  English (UK)
                </label>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default UserLanguage;
