import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
import avatar from '../../assets/images/profile/avatar.png';
import mapMarker from '../../assets/svg/map-marker.svg';
import editIcon from '../../assets/svg/edit.svg';
import locationIcon from '../../assets/svg/location.svg';
import workIcon from '../../assets/svg/work.svg';
import chevronRightIcon from '../../assets/svg/chevron-right.svg';
import lockCloseIcon from '../../assets/svg/lock-close.svg';
import lockOpenIcon from '../../assets/svg/lock-open.svg';
import shieldIcon from '../../assets/svg/shield.svg';
import bellBlackIcon from '../../assets/svg/bell-black.svg';
import globeIcon from '../../assets/svg/globe.svg';
import trashIcon from '../../assets/svg/trash.svg';
import shieldRoundIcon from '../../assets/svg/shield-round.svg';
import questionIcon from '../../assets/svg/question.svg';
import activityIcon from '../../assets/svg/activity.svg';
import closeIcon from '../../assets/svg/close-black.svg';
import { AuthContext } from "../../AuthProvider";

const UserProfile = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode === 'enabled') {
      setDarkMode(true);
      document.body.classList.add('dark-mode');
    }
  }, []);

  const handleDarkModeToggle = () => {
    if (darkMode) {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('darkMode', 'disabled');
    } else {
      document.body.classList.add('dark-mode');
      localStorage.setItem('darkMode', 'enabled');
    }
    setDarkMode(!darkMode);
  };

  const handleLogout = () => {
    logout();
    navigate('/signin-email');
  };

  return (
    <div className="scrollbar-hidden">
      <main className="user-profile">
        <section className="user-profile-heading d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-12">
            <div className="image rounded-full overflow-hidden shrink-0">
              <img src={user.picture ? user.picture : avatar} alt="avatar" className="img-fluid w-100 h-100 object-fit-cover" />
            </div>
            <div>
              <h3>{user?.firstName} {user?.lastName}</h3>
              <p className="d-flex align-items-center gap-04 location mt-04">
                <img src={mapMarker} alt="icon" />
                {user?.location || ''}
              </p>
            </div>
          </div>

          <Link to="/profile/user-info" className="edit-info">
            <img src={editIcon} alt="icon" />
          </Link>
        </section>

        <section className="user-personal">
          <div className="mt-32">
            <h4 className="mb-16">Informations personnelles</h4>
            <ul className="setting-list">
              <li>
                <Link to="/profile/user-address" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={locationIcon} alt="icon" />
                    <p>Mes Adresses</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li>
              {/* <li>
                <Link to="/profile/user-payment" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={workIcon} alt="icon" />
                    <p>Payment Method</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="mt-32">
            <h4 className="mb-16">Security</h4>
            <ul className="setting-list">
              {/* <li>
                <Link to="/change-password" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={lockCloseIcon} alt="icon" />
                    <p>Change Password</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li>
              <li>
                <Link to="/forgot-password" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={lockOpenIcon} alt="icon" />
                    <p>Forgot Password</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li> */}
              <li>
                <Link to="/profile/security" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={shieldIcon} alt="icon" />
                    <p>Sécurité</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li>
              <li>
                <Link to="/profile/notifications" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={bellBlackIcon} alt="icon" />
                    <p>Notifications</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="mt-32">
            <h4 className="mb-16">General</h4>
            <ul className="setting-list">
              <li>
                <Link to="/profile/user-language" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={globeIcon} alt="icon" />
                    <p>Langues</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li>
              <li>
                <Link to="#" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={trashIcon} alt="icon" />
                    <p>Vider le cache</p>
                  </div>
                  <small>88 MB</small>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mt-32">
            <h4 className="mb-16">About</h4>
            <ul className="setting-list">
              <li>
                <Link to="/profile/legal-policy" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={shieldRoundIcon} alt="icon" />
                    <p>CGU's & CGV's</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li>
              {/* <li>
                <Link to="/profile/help-support" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={questionIcon} alt="icon" />
                    <p>F.A.Q</p>
                  </div>
                  <img src={chevronRightIcon} alt="Icon" />
                </Link>
              </li> */}
              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-12 shrink-0">
                    <img src={activityIcon} alt="icon" />
                    <p className="mode-text">Dark Mode</p>
                  </div>
                  <label className="toggle-switch">
                    <input type="checkbox" className="mode-switch" id="check-mode" checked={darkMode} onChange={handleDarkModeToggle} />
                    <span className="slider"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <div className="py-32">
          <button type="button" className="btn-primary-outline" data-bs-toggle="modal" data-bs-target="#logOutModal">Déconnexion</button>
        </div>
      </main>

      <div className="modal fade logOutModal modalBg" id="logOutModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-end">
              <button type="button" className="close-btn d-flex align-items-center justify-content-center rounded-full" data-bs-dismiss="modal" aria-label="Close">
                <img src={closeIcon} alt="icon" />
              </button>
            </div>
            <div className="modal-body text-center">
              <h4 className="mb-32">Êtes-vous sûr de vouloir vous déconnecter?</h4>
              <ul>
                <li className="mb-04">
                  <button type="button" data-bs-dismiss="modal" aria-label="Close">Annuler</button>
                </li>
                <li>
                  <button type="button" className="log-out" data-bs-dismiss="modal" aria-label="Close" onClick={handleLogout}>Déconnexion</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
