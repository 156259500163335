import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';
import eyeOff from '../../assets/svg/eye-off.svg';
import eyeOn from '../../assets/svg/eye-on.svg';
import checkGreenOutline from '../../assets/svg/check-green-outline.svg';

const ChangePassword = () => {
  return (
    <div className="scrollbar-hidden">
      <main className="profile-main">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Change Password</h3>
        </div>

        <section className="change-password px-24">
          <h4 className="mb-24">The new password must be different from the current password</h4>
          <div className="mb-16">
            <label htmlFor="cpass">Password</label>
            <div className="position-relative">
              <input
                type="password"
                id="cpass"
                data-pssws-shown="false"
                placeholder="Enter your password"
                className="input-psswd input-field d-block"
              />
              <button type="button" className="eye-btn">
                <span className="eye-off">
                  <img src={eyeOff} alt="Eye Off" />
                </span>
                <span className="eye-on d-none">
                  <img src={eyeOn} alt="Eye On" />
                </span>
              </button>
            </div>
          </div>
          <ul className="mb-16">
            <li className="d-flex gap-04">
              <img src={checkGreenOutline} alt="icon" />
              <p>There must be at least 8 characters</p>
            </li>
            <li className="d-flex gap-04">
              <img src={checkGreenOutline} alt="icon" />
              <p>There must be a unique code like @!#</p>
            </li>
          </ul>
          <div>
            <label htmlFor="ccpass">Confirm Password</label>
            <div className="position-relative">
              <input
                type="password"
                id="ccpass"
                data-pssws-shown="false"
                placeholder="Confirm your password"
                className="input-psswd input-field d-block"
              />
              <button type="button" className="eye-btn">
                <span className="eye-off">
                  <img src={eyeOff} alt="Eye Off" />
                </span>
                <span className="eye-on d-none">
                  <img src={eyeOn} alt="Eye On" />
                </span>
              </button>
            </div>
          </div>
        </section>

        <div className="submit-btn px-24 pt-24 pb-36 bottom-btn">
          <button type="button" className="btn-primary">Submit</button>
        </div>
      </main>
    </div>
  );
};

export default ChangePassword;
