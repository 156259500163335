import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/auth.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

const Signin = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup('.container');
    }

  }, []);

  return (
    <div className="scrollbar-hidden">
      <main className="auth-main">
        {/* menu, side-menu start */}
        <section className="wrapper dz-mode">
          {/* menu */}
          <div className="menu">
            <button className="toggle-btn">
              <img src="/assets/svg/menu/burger-white.svg" alt="" className="icon" />
            </button>
            <div className="btn-grp d-flex align-items-center gap-16">
              <label htmlFor="mode-change" className="mode-change d-flex align-items-center justify-content-center">
                <input type="checkbox" id="mode-change" />
                <img src="/assets/svg/menu/sun-white.svg" alt="icon" className="sun" />
                <img src="/assets/svg/menu/moon-white.svg" alt="icon" className="moon" />
              </label>
              <Link to="/profile/user-profile">
                <img src="/assets/svg/menu/profile-white.svg" alt="icon" />
              </Link>
            </div>
          </div>
          <div className="m-menu__overlay"></div>
          {/* main menu */}
          <div className="m-menu">
            <div className="m-menu__header">
              <button className="m-menu__close">
                <img src="/assets/svg/menu/close-white.svg" alt="icon" />
              </button>
              <div className="menu-user">
                <img src="/assets/images/profile/avatar.png" alt="avatar" />
                <div>
                  <Link to="#!">angela mayer</Link>
                  <h3>
                    Verified user · Membership
                  </h3>
                </div>
              </div>
            </div>
            <ul>
              <li>
                <h2 className="menu-title">menu</h2>
              </li>

              <li>
                <Link to="/">
                  <div className="d-flex align-items-center gap-16">
                    <span className="icon">
                      <img src="/assets/svg/menu/pie-white.svg" alt="" />
                    </span>
                    overview
                  </div>
                  <img src="/assets/svg/menu/chevron-right-black.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/page">
                  <div className="d-flex align-items-center gap-16">
                    <span className="icon">
                      <img src="/assets/svg/menu/page-white.svg" alt="" />
                    </span>
                    pages
                  </div>
                  <img src="/assets/svg/menu/chevron-right-black.svg" alt="" />
                </Link>
              </li>
              <li>
                <h2 className="menu-title">others</h2>
              </li>

              <li>
                <label className="a-label__chevron" htmlFor="item-4">
                  <span className="d-flex align-items-center gap-16">
                    <span className="icon">
                      <img src="/assets/svg/menu/grid-white.svg" alt="" />
                    </span>
                    components
                  </span>
                  <img src="/assets/svg/menu/chevron-right-black.svg" alt="" />
                </label>
                <input type="checkbox" id="item-4" name="item-4" className="m-menu__checkbox" />
                <div className="m-menu">
                  <div className="m-menu__header">
                    <label className="m-menu__toggle" htmlFor="item-4">
                      <img src="/assets/svg/menu/back-white.svg" alt="" />
                    </label>
                    <span className="m-menu__header-title">components</span>
                  </div>
                  <ul>
                    <li>
                      <Link to="/components/splash-screen">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          splash screen
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <label className="a-label__chevron" htmlFor="item-5">
                  <span className="d-flex align-items-center gap-16">
                    <span className="icon">
                      <img src="/assets/svg/menu/gear-white.svg" alt="" />
                    </span>
                    settings
                  </span>
                  <img src="/assets/svg/menu/chevron-right-black.svg" alt="" />
                </label>
                <input type="checkbox" id="item-5" name="item-5" className="m-menu__checkbox" />
                <div className="m-menu">
                  <div className="m-menu__header">
                    <label className="m-menu__toggle" htmlFor="item-5">
                      <img src="/assets/svg/menu/back-white.svg" alt="" />
                    </label>
                    <span className="m-menu__header-title">settings</span>
                  </div>
                  <ul>
                    <li>
                      <Link to="/profile/user-address">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          My Address
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/user-payment">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          Payment Method
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/change-password">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          Change Password
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/forgot-password">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          Forgot Password
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/security">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          Security
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/user-language">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          Language
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/notifications">
                        <div className="d-flex align-items-center gap-16">
                          <span className="icon">
                            <img src="/assets/svg/menu/box-white.svg" alt="icon" />
                          </span>
                          Notifications
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="dz-switch">
                <div className="a-label__chevron">
                  <div className="d-flex align-items-center gap-16">
                    <span className="icon">
                      <img src="/assets/svg/menu/moon-white.svg" alt="" />
                    </span>
                    switch to dark mode
                  </div>
                  <label className="toggle-switch" htmlFor="enableMode">
                    <input type="checkbox" id="enableMode" className="mode-switch" />
                    <span className="slider"></span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          {/* end main menu */}
        </section>
        {/* menu, side-menu end */}

        {/* signin start */}
        <section className="auth signin">
          <div className="heading">
            <h2>Hi, Welcome Back!</h2>
            <p>Lorem ipsum dolor sit amet</p>
          </div>
      
          <div className="form-area auth-form">
            <form action="#">
              <div>
                <label htmlFor="lemail1">Email</label>
                <input type="email" id="lemail1" placeholder="Enter your email address" className="input-field" />
              </div>
              <Link to="/signin-email" className="btn-primary">Continue with Email</Link>
            </form>
      
            <div className="divider d-flex align-items-center justify-content-center gap-12">
              <span className="d-inline-block"></span>
              <small className="d-inline-block">Or continue with</small>
              <span className="d-inline-block"></span>
            </div>
      
            <div className="d-flex flex-column gap-16">
              <button type="button" className="social-btn" data-bs-toggle="modal" data-bs-target="#loginSuccess">
                <img src="/assets/svg/icon-google.svg" alt="Google" />
                Continue with Google
              </button>
              <button type="button" className="social-btn apple" data-bs-toggle="modal" data-bs-target="#loginSuccess">
                <img src="/assets/svg/icon-apple.svg" alt="Apple" />
                Continue with Apple
              </button>
            </div>
      
            <h6>Don’t have an account? <Link to="/signup">Sign Up</Link></h6>
          </div>
        </section>
        {/* signin end */}
      </main>

      {/* modal start */}
      <div className="modal fade loginSuccessModal modalBg" id="loginSuccess" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src="/assets/svg/check-green.svg" alt="Check" />
              <h3>You have logged in successfully</h3>
              <p className="mb-32">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              <Link to="/" className="btn-primary">Continue</Link>
            </div>
          </div>
        </div>
      </div>
      {/* modal end */}
    </div>
  );
};

export default Signin;
