import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/home.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const TourGuide = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup('.container');
    }

  }, []);

  return (
    <div className="scrollbar-hidden">
      <main className="tour-guide">
        {/* page-title */}
        <div className="page-title">
        <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
          <img src={arrowLeftBlack} alt="arrow" />
        </Link>
          <h3 className="main-title">Tour Guide</h3>
        </div>

        <section className="guide px-24 pb-24">
          <ul>
            {/* guide card 1 */}
            <li>
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src="/assets/images/home/guide-1.png" alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
        
                <div className="content">
                  <h4>Alenzo Endera</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    Polynesia, French 
                  </p>
                </div>
              </Link>
            </li>

            {/* guide card 2 */}
            <li>
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src="/assets/images/home/guide-2.png" alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
        
                <div className="content">
                  <h4>Jhone Kenoady</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    Polynesia, French 
                  </p>
                </div>
              </Link>
            </li>

            {/* guide card 3 */}
            <li>
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src="/assets/images/home/guide-3.png" alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
        
                <div className="content">
                  <h4>Emilia Ricardo</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    Polynesia, French 
                  </p>
                </div>
              </Link>
            </li>

            {/* guide card 4 */}
            <li>
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src="/assets/images/home/guide-4.png" alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
        
                <div className="content">
                  <h4>Alexa Bigford</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    Polynesia, French 
                  </p>
                </div>
              </Link>
            </li>

            {/* guide card 5 */}
            <li>
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src="/assets/images/home/guide-1.png" alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
        
                <div className="content">
                  <h4>Alenzo Endera</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    Polynesia, French 
                  </p>
                </div>
              </Link>
            </li>

            {/* guide card 6 */}
            <li>
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0">
                <div className="image position-relative shrink-0">
                  <img src="/assets/images/home/guide-2.png" alt="guide" className="guide-img object-fit-cover img-fluid radius-12" />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
        
                <div className="content">
                  <h4>Jhone Kenoady</h4>
                  <h5>$25 (1 Day)</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    Polynesia, French 
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default TourGuide;
