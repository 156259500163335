import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "../../assets/css/jquery.datetimepicker.css";
import "../../assets/css/common.css";
import "../../assets/css/animations.css";
import "../../assets/css/welcome.css";
import "../../assets/css/auth.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const SelectLanguage = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup(".container");
    }

  }, []);

  return (
    <div className="scrollbar-hidden">
      <main>
        {/* select-lang start */}
        <section className="auth signin-email select-lang">
          <div className="page-title">
            <Link
              to={-1}
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowLeftBlack} alt="arrow" />
            </Link>
          </div>

          <div className="heading">
            <h2>Select your Language</h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>

          <div className="auth-form">
            <form action="#">
              <div>
                <label htmlFor="languange">Languange</label>
                <div className="position-relative">
                  <input
                    type="text"
                    id="languange"
                    placeholder="Select languange"
                    readOnly
                    className="input-field"
                    data-bs-toggle="modal"
                    data-bs-target="#languageModal"
                  />
                  <img
                    src="/assets/svg/arrow-ios-downward.svg"
                    alt="Arrow"
                    className="arrow"
                  />
                </div>
              </div>
              <Link to="/" className="btn-primary">
                Continue
              </Link>
            </form>
          </div>
        </section>
        {/* select-lang end */}
      </main>

      {/* modal start */}
      <div
        className="modal fade languageModal"
        id="languageModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="back-btn modal-close rounded-full"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/svg/close-black.svg" alt="Close" />
              </button>
              <h1 className="modal-title">Select a Language</h1>
            </div>
            <div className="modal-body">
              <ul>
                <li>
                  <label htmlFor="englishuk" className="lang-container">
                    <input
                      type="radio"
                      id="englishuk"
                      name="language"
                      defaultChecked="checked"
                    />
                    English (UK)
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="english" className="lang-container">
                    <input type="radio" name="language" id="english" />
                    English
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="bahasa" className="lang-container">
                    <input type="radio" name="language" id="bahasa" />
                    Bahasa Indonesia
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="chineses" className="lang-container">
                    <input type="radio" name="language" id="chineses" />
                    Chineses
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="croatian" className="lang-container">
                    <input type="radio" name="language" id="croatian" />
                    Croatian
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="czech" className="lang-container">
                    <input type="radio" name="language" id="czech" />
                    Czech
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="danish" className="lang-container">
                    <input type="radio" name="language" id="danish" />
                    Danish
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="filipino" className="lang-container">
                    <input type="radio" name="language" id="filipino" />
                    Filipino
                    <span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label htmlFor="finland" className="lang-container">
                    <input type="radio" name="language" id="finland" />
                    Finland
                    <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* modal end */}
    </div>
  );
};

export default SelectLanguage;
