import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';
import plusSquare from '../../assets/svg/plus-square.svg';
import { AuthContext } from "../../AuthProvider";

const UserAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetch(`https://esifly.fr/api/user-addresses/index.php?email=${user.email}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setAddresses(data.addresses);
        } else {
          console.error('Error fetching addresses:', data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [user.id]);

  return (
    <div className="scrollbar-hidden">
      <main className="profile-main user-address">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Mes Adresses</h3>
          <Link to="/profile/add-address" className="plus-btn d-flex align-items-center justify-content-center ms-auto">
            <img src={plusSquare} alt="icon" />
          </Link>
        </div>

        <section className="all-address p-24">
          {addresses.map(address => (
            <div className="address-card mt-16" key={address.id}>
              <label className="custom-check-container address-container">
                <input type="radio" name="address" />
                <span className="checkmark"></span>
                <small className="text d-block">
                  <small className="address-card-title d-block">{address.full_name}</small>
                  <small className="address-card-phn pt-04 pb-8 d-block">+1 234 567 890</small>
                  <small className="address-card-phn d-block">{address.detail_address} <br /> {address.city}, {address.country}</small>
                </small>
              </label>
              <button type="button" className="change-add">Change Address</button>
            </div>
          ))}
        </section>

        <div className="select-btn bottom-btn px-24 pt-24 pb-36">
          <button type="button" className="btn-primary">Select Address</button>
        </div>
      </main>
    </div>
  );
};

export default UserAddress;
