import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/home.css';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import axios from 'axios';
import { AuthContext } from '../AuthProvider'; // Import AuthContext

gsap.registerPlugin(Draggable);

const Home = () => {
  const [products, setProducts] = useState([]);
  const [budgetProducts, setBudgetProducts] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { user } = useContext(AuthContext); // Get user from context

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    // Fetch products from the API
    axios.get('https://esifly.fr/api/products/')
      .then(response => {
        const records = response.data.records;
        setProducts(shuffleArray(records).slice(0, 8)); // Set 8 random products for "Frequently visited"
        const sortedByPrice = records.sort((a, b) => parseFloat(a.fields.price) - parseFloat(b.fields.price));
        setBudgetProducts(sortedByPrice.slice(0, 2)); // Set the 2 cheapest products for "On Budget Tour"
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });

    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup('.container');
    }

  }, []);

  // Function to navigate to Explore with category
  const handleNavigate = (category) => {
    navigate('/explore', { state: { category } });
  };

  return (
    <div className="scrollbar-hidden">
      <main className="home">
        {/* Info */}
        <section className="info d-flex align-items-start justify-content-between pb-12">
          <div className="d-flex align-items-center justify-content-between gap-14">
            <div className="image shrink-0 rounded-full overflow-hidden">
              <img src={user?.picture ? user?.picture : "/assets/images/home/logo.png"} alt="avatar" className="w-100 h-100 object-fit-cover" />
            </div>
            <div>
              <h3>
                {user ? `${user.firstName ? user.firstName : ""}${user.firstName && user.lastName ? " " : ""}${user.lastName ? user.lastName : ""}` : "E S I F L Y"}
              </h3>
              <p className="d-flex align-items-center gap-04">
                <img src="/assets/svg/map-marker.svg" alt="icon" />
                France
              </p>
            </div>
          </div>

          <ul className="d-flex align-items-center gap-16">
          <li>
            <a href="tel:0140765810" className="d-flex align-items-center justify-content-center rounded-full position-relative">
              <img src="/assets/svg/voice-call.svg" alt="icon" />
              <span className="dot"></span>
            </a>
          </li>
            {/* <li>
              <Link to="/" className="d-flex align-items-center justify-content-center rounded-full position-relative">
                <img src="/assets/svg/message-square-dots.svg" alt="icon" />
                <span className="dot"></span>
              </Link>
            </li> */}
          </ul>
        </section>

        {/* Search */}
        <section className="search py-12">
          <form action="#">
            <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24">
              <img src="/assets/svg/search.svg" alt="search" className="shrink-0" />
              <input type="search" className="input-search input-field" placeholder="Recherche..." />
              <div className="filter shrink-0">
                {/* <button type="button" className="d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#filterModal"> */}
                <button type="button" className="d-flex align-items-center" >
                  <img src="/assets/svg/filter-black.svg" alt="filter" />
                </button>
              </div>
            </div>
          </form>
        </section>

        {/* Service */}
        <section className="service py-12">
          <div onClick={() => handleNavigate('Avions')}>
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/avion.png" alt="avion" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Avion</figcaption>
            </figure>
          </div>

          <div onClick={() => handleNavigate('Hélicoptères')}>
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/helicoptere.png" alt="helicoptere" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Hélicoptère</figcaption>
            </figure>
          </div>

          <div onClick={() => handleNavigate('Montgolfière')}>
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/montgolfiere.png" alt="montgolfiere" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Montgolfière</figcaption>
            </figure>
          </div>

          <div onClick={() => handleNavigate('Mariages')}>
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/mariage.png" alt="montgolfiere" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Mariage</figcaption>
            </figure>
          </div>

          <div onClick={() => handleNavigate('Sport & Aventure')}>
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/parachute.png" alt="montgolfiere" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Sport & Aventure</figcaption>
            </figure>
          </div>

          <div onClick={() => handleNavigate('Clip & Film')}>
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/tournage.png" alt="montgolfiere" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Tournage</figcaption>
            </figure>
          </div>

          {/* <div data-bs-toggle="modal" data-bs-target="#serviceModal">
            <figure className="item text-center">
              <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                <img src="/assets/images/home/category.png" alt="category" className="img-fluid backface-hidden" />
              </div>
              <figcaption>Voir plus</figcaption>
            </figure>
          </div> */}
        </section>

        {/* Visited */}
        <section className="visited py-12">
          <div className="title d-flex align-items-center justify-content-between">
            <h2 className="shrink-0">Fréquemment visité</h2>
            <div className="custom-pagination visited-pagination swiper-pagination"></div>
          </div>

          <Swiper
            className="swiper visited-swiper"
            modules={[Pagination]}
            spaceBetween={8} // Adjust space between slides
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true, el: '.custom-pagination.visited-pagination' }}
          >
            {products.reduce((acc, product, index, arr) => {
              if (index % 2 === 0) {
                acc.push(
                  <SwiperSlide className="swiper-slide place-card" key={index}>
                    <div className="d-flex" style={{ gap: '16px' }}> {/* Add gap between product cards */}
                      <ProductCard product={arr[index]} />
                      {arr[index + 1] && <ProductCard product={arr[index + 1]} />}
                    </div>
                  </SwiperSlide>
                );
              }
              return acc;
            }, [])}
          </Swiper>
        </section>

        {/* Guide */}
        {/* <section className="guide py-12">
          <div className="title d-flex align-items-center justify-content-between">
            <h2 className="shrink-0">Bon Cadeau</h2>
            <Link to="/tour-guide" className="shrink-0 d-inline-block">
              Tout voir
            </Link>
          </div>

          <div className="d-flex gap-24 all-cards scrollbar-hidden">
            {[1, 2].map((guide) => (
              <Link to="/profile/guide-profile" className="d-flex gap-16 item w-fit shrink-0" key={guide}>
                <div className="image position-relative shrink-0">
                  <img
                    src={`/assets/images/home/guide-${guide}.png`}
                    alt="guide"
                    className="guide-img object-fit-cover img-fluid radius-12"
                  />
                  <div className="rating d-flex align-items-center gap-04 w-fit">
                    <img src="/assets/svg/star-yellow.svg" alt="Star" />
                    <span className="d-inline-block">4.0</span>
                  </div>
                </div>
                <div className="content">
                  <h4>{guide === 1 ? 'Helico Pass' : 'Avion Pass'}</h4>
                  <h5>{guide === 1 ? '249,90€ (1 Vol)' : '149,90€ (1 Vol)'}</h5>
                  <p className="d-flex align-items-center gap-8 location">
                    <img src="/assets/svg/map-black.svg" alt="icon" />
                    {guide === 1 ? 'France & Dom-Tom' : 'France & Dom-Tom'}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </section> */}

        {/* Budget */}
        <section className="budget pt-12">
          <div className="title d-flex align-items-center justify-content-between">
            <h2 className="shrink-0">Petit budget</h2>
            <Link to="/hotels" className="shrink-0 d-inline-block">
              Tout voir
            </Link>
          </div>

          <ul>
            {budgetProducts.map((product) => (
              <li key={product.id}>
                <Link to={`/vacation-details/${product.id}`} className="d-flex align-items-center gap-12">
                  <div className="image shrink-0 overflow-hidden radius-8">
                    <img
                      src={product.fields.Photos && product.fields.Photos.length > 0 ? product.fields.Photos[0].url : 'https://media.smartbox.com/pim/10000021629561219234741.jpg'}
                      alt={product.fields.name}
                      className="img-fluid w-100 h-100 object-fit-cover"
                      style={{ width: '156px', height: '150px', objectFit: 'cover' }}
                    />
                  </div>
                  <div className="content shrink-0 d-flex align-items-center gap-12 justify-content-between flex-grow">
                    <div>
                      <h4>{product.fields.name}</h4>
                      <h5>{`${product.fields.passenger_max} passager${product.fields.passenger_max > 1 ? 's' : ''}`}</h5> {/* This is a placeholder */}
                      <p className="d-flex align-items-center gap-8 location">
                        <img src="/assets/svg/map-marker.svg" alt="icon" />
                        {/* {product.fields.departments[0]} */}
                        France
                      </p>
                    </div>
                    <p className="price">
                      <span>{`${product.fields.price}€`}</span>/Pers.
                    </p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </main>

      {/* Service Modal */}
      <div className="modal fade serviceModal bottomModal modalBg" id="serviceModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="modal-close rounded-full"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/svg/close-black.svg" alt="Close" />
              </button>
              <h1 className="modal-title">Nos catégories</h1>
            </div>
            <div className="modal-body">
              {['avion', 'helicoptere', 'montgolfiere', 'parachute', 'mariage', 'tournage', 'mariage', 'cadeau'].map((service, index) => (
                <Link to={`/service-location/${service}`} key={index}>
                  <figure className="item text-center">
                    <div className="image rounded-full d-flex align-items-center justify-content-center m-auto">
                      <img
                        src={`/assets/images/home/${service}.png`}
                        alt={service}
                        className="img-fluid backface-hidden"
                      />
                    </div>
                    <figcaption>{service.charAt(0).toUpperCase() + service.slice(1)}</figcaption>
                  </figure>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Filter Modal */}
      <div className="modal fade filterModal bottomModal" id="filterModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="modal-close rounded-full"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/svg/close-black.svg" alt="Close" />
              </button>
              <h1 className="modal-title">Filter</h1>
            </div>
            <div className="modal-body">
              <div className="pb-12">
                <h4 className="content-title">Price Range</h4>
                <div className="filter-range">
                  <div id="price-slider-range"></div>
                  <div className="price-range w-100 d-flex align-items-center justify-content-between">
                    <input type="text" id="amount1" />
                    <input type="text" id="amount2" />
                  </div>
                </div>
              </div>

              <div className="py-12">
                <h4 className="content-title">Popular Filters</h4>
                <ul className="popular-filters">
                  {[
                    { id: 'hotel', label: 'Hotels (340)' },
                    { id: 'pool', label: 'Swimming Pool (340)', checked: true },
                    { id: 'stars', label: '5 stars (100)' },
                    { id: 'bathroom', label: 'Private Bathroom (200)' },
                    { id: 'breakfast', label: 'Breakfast Included (115)' },
                    { id: 'kitchen', label: 'Kitchen (10)' }
                  ].map((filter) => (
                    <li key={filter.id}>
                      <label htmlFor={filter.id} className="filter-label">
                        <input type="checkbox" id={filter.id} defaultChecked={filter.checked} />
                        {filter.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="py-12">
                <h4 className="content-title">Star Rating</h4>
                <ul className="star-rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <li key={star}>
                      <label htmlFor={`star${star}`} className="filter-label">
                        <input type="radio" name="star" id={`star${star}`} defaultChecked={star === 4} />
                        {Array(star)
                          .fill()
                          .map((_, i) => (
                            <img src="/assets/svg/star-yellow.svg" alt="star" key={i} />
                          ))}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="py-12">
                <Link to="/search-result" className="btn-primary apply-filter-btn">
                  Apply Filter
                </Link>
              </div>

              <div className="pt-12">
                <button type="button" className="clear-all-btn" data-bs-dismiss="modal" aria-label="Close">
                  Clear All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Create a ProductCard component to display individual products
const ProductCard = ({ product }) => (
  <div className="product-item" style={{ width: 'calc(50% - 8px)', marginRight: '8px' }}>
    <Link to={`/vacation-details/${product.id}`}>
      <div className="image position-relative">
        <img
          src={product.fields.Photos && product.fields.Photos.length > 0 ? product.fields.Photos[0].url : 'https://media.smartbox.com/pim/10000021629561219234741.jpg'}
          alt={product.fields.name}
          className="img-fluid w-100 overflow-hidden radius-8"
          style={{ width: '183px', height: '175px', objectFit: 'cover' }}
        />
        <span className="d-flex align-items-center justify-content-center rounded-full">
          <img src="/assets/svg/heart-black.svg" alt="icon" />
        </span>
      </div>
      <div className="content">
        <h4>{product.fields.name}</h4>
        {/* <p className="d-flex align-items-center gap-04 location">
          <img src="/assets/svg/map-marker.svg" alt="icon" />
          France
        </p> */}
        <div className="price d-flex align-items-center justify-content-between">
          <h3>{`${product.fields.price}€`}</h3>
          <p className="d-flex align-items-center gap-04">
            <img src="/assets/svg/star-yellow.svg" alt="icon" />
            {(Math.random() * 2 + 3).toFixed(1)} <span>({Math.floor(Math.random() * 25)})</span> {/* Random reviews count */}
          </p>
        </div>
      </div>
    </Link>
  </div>
);

export default Home;
