export const fetchGoogleProfile = async (accessToken, login, navigate) => {
  try {
    const res = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!res.ok) {
      throw new Error(`Google API error: ${res.statusText}`);
    }

    const profile = await res.json();
    console.log("Google Profile:", profile);

    // Provide a fallback value for family_name if it is undefined
    const firstName = profile.given_name || profile.name.split(' ')[0];
    const lastName = profile.family_name || profile.name.split(' ')[1] || '';

    // Validate profile data
    if (!firstName || !profile.email) {
      console.error("Missing profile fields:", {
        given_name: firstName,
        family_name: lastName,
        email: profile.email,
      });
      throw new Error("Incomplete Google profile data");
    }

    const response = await fetch("https://esifly.fr/api/signup/index.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: profile.email,
        picture: profile.picture,
      }),
    });

    if (!response.ok) {
      throw new Error(`Server API error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Server Response:", data);

    if (data.success) {
      login({
        email: profile.email,
        firstName: firstName,
        lastName: lastName,
        picture: profile.picture,
      });
      navigate("/");
    } else if (data.message === 'User already exists.') {
      login({
        email: profile.email,
        firstName: firstName,
        lastName: lastName,
        picture: profile.picture,
      });
      navigate("/");
    } else {
      console.error(data.message);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
