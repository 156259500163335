import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "../../assets/css/jquery.datetimepicker.css";
import "../../assets/css/common.css";
import "../../assets/css/animations.css";
import "../../assets/css/welcome.css";
import "../../assets/css/auth.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const EnterOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state.email;
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    $("#datepicker").datepicker();
    Draggable.create(".draggable");

    if (window.mixitup) {
      window.mixitup(".container");
    }
  }, []);

  const handleOtpInput = (e) => {
    const input = e.target;
    setOtp(prev => {
      const newOtp = prev.substring(0, input.dataset.index) + input.value + prev.substring(input.dataset.index + 1);
      if (input.value && input.dataset.next) {
        document.querySelector(`input[name=${input.dataset.next}]`).focus();
      } else if (!input.value && input.dataset.previous) {
        document.querySelector(`input[name=${input.dataset.previous}]`).focus();
      }
      return newOtp;
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://esifly.fr/cors.php?url=https://esifly.fr/api/otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp, action: 'verify' }),
      });

      const data = await response.json();
      if (data.success) {
        navigate('/');
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="scrollbar-hidden">
      <main>
        {/* otp start */}
        <section className="auth signin-email enter-otp">
          <div className="page-title">
            <Link
              to={-1}
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowLeftBlack} alt="arrow" />
            </Link>
          </div>

          <div className="heading">
            <h2>Enter OTP</h2>
            <p>
              We have just sent you 4 digit code via your email{" "}
              <span>{email}</span>
            </p>
          </div>

          <div className="auth-form">
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="digit-group">
              <input
                type="text"
                id="digit-1"
                name="digit-1"
                data-next="digit-2"
                data-index="0"
                onChange={handleOtpInput}
                autoFocus
              />
              <input
                type="text"
                id="digit-2"
                name="digit-2"
                data-next="digit-3"
                data-previous="digit-1"
                data-index="1"
                onChange={handleOtpInput}
              />
              <input
                type="text"
                id="digit-3"
                name="digit-3"
                data-next="digit-4"
                data-previous="digit-2"
                data-index="2"
                onChange={handleOtpInput}
              />
              <input
                type="text"
                id="digit-4"
                name="digit-4"
                data-previous="digit-3"
                data-index="3"
                onChange={handleOtpInput}
              />
            </div>
            <button
              type="button"
              className="btn-primary"
              onClick={handleSubmit}
            >
              Continue
            </button>
            <h6>
              Didn’t receive code? <button type="button">Resend Code</button>
            </h6>
          </div>
        </section>
        {/* otp end */}
      </main>

      {/* modal start */}
      <div
        className="modal fade loginSuccessModal modalBg"
        id="loginSuccess"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src="/assets/svg/check-green.svg" alt="Check" />
              <h3>You have logged in successfully</h3>
              <p className="mb-32">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <Link to="/" className="btn-primary">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* modal end */}
    </div>
  );
};

export default EnterOtp;
