import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom"; // Importez useNavigate
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "../assets/css/jquery.datetimepicker.css";
import "../assets/css/common.css";
import "../assets/css/animations.css";
import "../assets/css/welcome.css";
import "../assets/css/ticket.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import axios from "axios";
import { AuthContext } from "../AuthProvider";
import { parse } from "date-fns";
import fr from "date-fns/locale/fr";

gsap.registerPlugin(Draggable);

const Wishlist = () => {
  const { user } = useContext(AuthContext);
  const [bookedTickets, setBookedTickets] = useState([]);
  const [historyTickets, setHistoryTickets] = useState([]);
  const navigate = useNavigate(); // Utilisez useNavigate

  const parseDate = (dateString) => {
    const dateWithoutAt = dateString.replace(" à ", " ");
    const formatString = "EEEE d MMMM yyyy HH:mm";
    return parse(dateWithoutAt, formatString, new Date(), { locale: fr });
  };

  useEffect(() => {
    if (user && user.email) {
      console.log("Fetching reservations for:", user.email);
      axios
        .post("https://esifly.fr/api/get_reservations/index.php", {
          email: user.email,
        })
        .then((response) => {
          console.log("API response:", response.data);
          if (response.data.success) {
            console.log("Reservations data:", response.data.data);
            const currentDate = new Date();
            const booked = response.data.data.filter(
              (order) => parseDate(order.date) > currentDate
            );
            const history = response.data.data.filter(
              (order) => parseDate(order.date) <= currentDate
            );
            console.log("Booked Tickets after filtering:", booked);
            console.log("History Tickets after filtering:", history);
            setBookedTickets(booked);
            setHistoryTickets(history);
          } else {
            console.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    }

    $("#datepicker").datepicker();
    Draggable.create(".draggable");

    if (window.mixitup) {
      window.mixitup(".container");
    }
  }, [user]);

  const handleClick = (event) => {
    event.preventDefault();
    console.log("Link clicked");
  };

  const handleDetailClick = (token) => {
    navigate("/ticket-detail", { state: { token } });
  };

  return (
    // <div className="scrollbar-hidden">
    //   <main className="ticket">
    //     <div className="page-title">
    //       <h3 className="main-title">Location hélicoptère</h3>
    //     </div>
    //     <section className="ticket-tab">
    //       <ul className="nav nav-tabs" id="myTab" role="tablist">
    //         <li className="nav-item" role="presentation">
    //           <button
    //             className="nav-link active"
    //             id="booked-tab"
    //             data-bs-toggle="tab"
    //             data-bs-target="#booked-tab-pane"
    //             type="button"
    //             role="tab"
    //             aria-controls="booked-tab-pane"
    //             aria-selected="true"
    //           >
    //             Transport
    //           </button>
    //         </li>
    //         <li className="nav-item" role="presentation">
    //           <button
    //             className="nav-link"
    //             id="history-tab"
    //             data-bs-toggle="tab"
    //             data-bs-target="#history-tab-pane"
    //             type="button"
    //             role="tab"
    //             aria-controls="history-tab-pane"
    //             aria-selected="false"
    //           >
    //             Mariage & Évènement
    //           </button>
    //         </li>
    //       </ul>
    //       <div className="tab-content" id="myTabContent">
    //         <div
    //           className="tab-pane fade show active"
    //           id="booked-tab-pane"
    //           role="tabpanel"
    //           aria-labelledby="booked-tab"
    //           tabIndex="0"
    //         >
    //           {/* search start */}
    //           <section className="search pb-12">
    //             <form action="#">
    //               {/* depart */}
    //               <div className="search pb-24">
    //                   <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#selectLocationModal">
    //                     <img
    //                       src="/assets/svg/map-marker.svg"
    //                       alt="search"
    //                       className="shrink-0"
    //                     />
    //                     <input
    //                       type="search"
    //                       className="input-search input-field"
    //                       placeholder="Départ de..."
    //                     />
    //                   </div>
    //                 </div>

    //                 {/* arrivée */}
    //                 <div className="search pb-24">
    //                   <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#selectLocationModal">
    //                     <img
    //                       src="/assets/svg/map-marker.svg"
    //                       alt="search"
    //                       className="shrink-0"
    //                     />
    //                     <input
    //                       type="search"
    //                       className="input-search input-field"
    //                       placeholder="Arrivée à..."
    //                     />
    //                   </div>
    //                 </div>
    //               <div className="grid">
                    

    //                 {/* date aller */}
    //                 <label
    //                   htmlFor="sdate"
    //                   className="w-100 d-flex align-items-center gap-8 radius-24"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#selectDateModal"
    //                 >
    //                   <input
    //                     type="text"
    //                     id="sdate"
    //                     placeholder="Date aller"
    //                     className="input-field"
    //                   />
    //                   <span className="icon shrink-0">
    //                     <img src="/assets/svg/calendar.svg" alt="calendar" />
    //                   </span>
    //                 </label>

    //                 {/* date retour */}
    //                 <label
    //                   htmlFor="sdate"
    //                   className="w-100 d-flex align-items-center gap-8 radius-24"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#selectDateModal"
    //                 >
    //                   <input
    //                     type="text"
    //                     id="sdate"
    //                     placeholder="Date retour"
    //                     className="input-field"
    //                   />
    //                   <span className="icon shrink-0">
    //                     <img src="/assets/svg/calendar.svg" alt="calendar" />
    //                   </span>
    //                 </label>

    //                 {/* prenom */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="firstname"
    //                     placeholder="Prénom"
    //                     className="input-field"
    //                   />
    //                 </label>

    //                 {/* nom */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="lastname"
    //                     placeholder="Nom"
    //                     className="input-field"
    //                   />
    //                 </label>

    //                 {/* mail */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="email"
    //                     placeholder="Adresse e-mail"
    //                     className="input-field"
    //                   />
    //                 </label>

    //                 {/* tel */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="tel"
    //                     placeholder="Téléphone"
    //                     className="input-field"
    //                   />
    //                 </label>
    //               </div>
    //             </form>
    //           </section>
    //         </div>
    //         <div
    //           className="tab-pane fade"
    //           id="history-tab-pane"
    //           role="tabpanel"
    //           aria-labelledby="history-tab"
    //           tabIndex="0"
    //         >
    //           {/* search start */}
    //           <section className="search pb-12">
    //             <form action="#">
    //               {/* depart */}
    //               <div className="search pb-24">
    //                   <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#selectLocationModal">
    //                     <img
    //                       src="/assets/svg/map-marker.svg"
    //                       alt="search"
    //                       className="shrink-0"
    //                     />
    //                     <input
    //                       type="search"
    //                       className="input-search input-field"
    //                       placeholder="Départ de..."
    //                     />
    //                   </div>
    //                 </div>

    //                 {/* arrivée */}
    //                 <div className="search pb-24">
    //                   <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#selectLocationModal">
    //                     <img
    //                       src="/assets/svg/map-marker.svg"
    //                       alt="search"
    //                       className="shrink-0"
    //                     />
    //                     <input
    //                       type="search"
    //                       className="input-search input-field"
    //                       placeholder="Arrivée à..."
    //                     />
    //                   </div>
    //                 </div>
    //               <div className="grid">
                    

    //                 {/* date aller */}
    //                 <label
    //                   htmlFor="sdate"
    //                   className="w-100 d-flex align-items-center gap-8 radius-24"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#selectDateModal"
    //                 >
    //                   <input
    //                     type="text"
    //                     id="sdate"
    //                     placeholder="Date aller"
    //                     className="input-field"
    //                   />
    //                   <span className="icon shrink-0">
    //                     <img src="/assets/svg/calendar.svg" alt="calendar" />
    //                   </span>
    //                 </label>

    //                 {/* date retour */}
    //                 <label
    //                   htmlFor="sdate"
    //                   className="w-100 d-flex align-items-center gap-8 radius-24"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#selectDateModal"
    //                 >
    //                   <input
    //                     type="text"
    //                     id="sdate"
    //                     placeholder="Date retour"
    //                     className="input-field"
    //                   />
    //                   <span className="icon shrink-0">
    //                     <img src="/assets/svg/calendar.svg" alt="calendar" />
    //                   </span>
    //                 </label>

    //                 {/* prenom */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="firstname"
    //                     placeholder="Prénom"
    //                     className="input-field"
    //                   />
    //                 </label>

    //                 {/* nom */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="lastname"
    //                     placeholder="Nom"
    //                     className="input-field"
    //                   />
    //                 </label>

    //                 {/* mail */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="email"
    //                     placeholder="Adresse e-mail"
    //                     className="input-field"
    //                   />
    //                 </label>

    //                 {/* tel */}
    //                 <label className="w-100 d-flex align-items-center gap-8 radius-24">
    //                   <input
    //                     type="text"
    //                     id="tel"
    //                     placeholder="Téléphone"
    //                     className="input-field"
    //                   />
    //                 </label>
    //               </div>
    //             </form>
    //           </section>
    //         </div>
    //       </div>
    //     </section>
    //   </main>
    //   {/* location-modal start */}
    //   <div
    //     className="modal fade selectLocationModal modalBg"
    //     id="selectLocationModal"
    //     aria-hidden="true"
    //     tabIndex="-1"
    //   >
    //     <div className="modal-dialog modal-dialog-centered">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <button
    //             type="button"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //             className="d-flex align-items-center justify-content-center"
    //           >
    //             <img src="/assets/svg/close-black.svg" alt="close" />
    //           </button>
    //           <h1 className="modal-title" id="selectLocationModalLabel">
    //             Location
    //           </h1>
    //         </div>
    //         <div className="modal-body">
    //           <form action="#">
    //             <div className="grid">
    //               <label
    //                 htmlFor="locSearch"
    //                 className="d-flex align-items-center gap-8 radius-24"
    //               >
    //                 <img
    //                   src="/assets/svg/search.svg"
    //                   alt="search"
    //                   className="shrink-0"
    //                 />
    //                 <input
    //                   type="text"
    //                   placeholder="Search location"
    //                   className="flex-grow"
    //                   id="locSearch"
    //                 />
    //               </label>
    //               <button type="button" className="radius-20">
    //                 Send
    //               </button>
    //             </div>
    //           </form>

    //           <ul className="loc-list scrollbar-hidden">
    //             <li className="d-flex align-items-center gap-12">
    //               <span className="d-flex align-items-center justify-content-center shrink-0 rounded-full">
    //                 <img src="/assets/svg/map-black.svg" alt="icon" />
    //               </span>
    //               <div>
    //                 <h5>Encinitas</h5>
    //                 <p>260-C North El Camino Real</p>
    //               </div>
    //             </li>
    //             <li className="d-flex align-items-center gap-12">
    //               <span className="d-flex align-items-center justify-content-center shrink-0 rounded-full">
    //                 <img src="/assets/svg/map-black.svg" alt="icon" />
    //               </span>
    //               <div>
    //                 <h5>San Marcos</h5>
    //                 <p>591 Grand Avenue </p>
    //               </div>
    //             </li>
    //             <li className="d-flex align-items-center gap-12">
    //               <span className="d-flex align-items-center justify-content-center shrink-0 rounded-full">
    //                 <img src="/assets/svg/map-black.svg" alt="icon" />
    //               </span>
    //               <div>
    //                 <h5>Roseville</h5>
    //                 <p>1186 Roseville Pkwy</p>
    //               </div>
    //             </li>
    //             <li className="d-flex align-items-center gap-12">
    //               <span className="d-flex align-items-center justify-content-center shrink-0 rounded-full">
    //                 <img src="/assets/svg/map-black.svg" alt="icon" />
    //               </span>
    //               <div>
    //                 <h5>San Ramon</h5>
    //                 <p>3141 Crow Canyon Place</p>
    //               </div>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* location-modal end */}

    //   {/* date-modal start */}
    //   <div
    //     className="modal fade selectDateModal dateModal modalBg"
    //     id="selectDateModal"
    //     tabIndex="-1"
    //     aria-labelledby="selectDateModalLabel"
    //     aria-hidden="true"
    //   >
    //     <div className="modal-dialog modal-dialog-centered">
    //       <div className="modal-content">
    //         <div className="modal-header justify-content-center">
    //           <h1 className="modal-title" id="selectDateModalLabel">
    //             Date
    //           </h1>
    //         </div>
    //         <div className="modal-body">
    //           <input type="text" id="selectDateInput" />

    //           <div className="btns d-flex align-items-center gap-16">
    //             <button
    //               type="button"
    //               className="radius-20 w-50 cancel-btn"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               Cancel
    //             </button>
    //             <button
    //               type="button"
    //               className="radius-20 w-50 apply-btn"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               Apply
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* date-modal end */}
    //   <div
    //     className="modal fade reviewModal bottomModal"
    //     id="reviewModal"
    //     tabIndex="-1"
    //     aria-hidden="true"
    //   >
    //     <div className="modal-dialog">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <button
    //             type="button"
    //             className="modal-close rounded-full"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //           >
    //             <img src="/assets/svg/close-black.svg" alt="Close" />
    //           </button>
    //           <h1 className="modal-title">Give a Review</h1>
    //         </div>
    //         <div className="modal-body">
    //           <ul className="ratingW d-flex align-items-center justify-content-center">
    //             {[...Array(5)].map((_, i) => (
    //               <li key={i} className={i < 3 ? "on" : ""}>
    //                 <Link to="#" onClick={handleClick}>
    //                   <img
    //                     src="/assets/svg/star-yellow-big.svg"
    //                     className="star-yellow"
    //                     alt="Star"
    //                   />
    //                   <img
    //                     src="/assets/svg/star-gray.svg"
    //                     className="star-gray"
    //                     alt="Star"
    //                   />
    //                 </Link>
    //               </li>
    //             ))}
    //           </ul>
    //           <div className="msg">
    //             <h6>Detail Review</h6>
    //             <textarea placeholder="Give a Review"></textarea>
    //           </div>
    //           <button
    //             type="button"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //             className="btn-primary"
    //           >
    //             Submit
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
};

export default Wishlist;
