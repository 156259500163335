import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/auth.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const CreateNewPassword = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup('.container');
    }

  }, []);

  const togglePasswordVisibility = (event) => {
    const inputField = event.currentTarget.previousElementSibling;
    const isPasswordShown = inputField.getAttribute('data-pssws-shown') === 'true';
    inputField.setAttribute('data-pssws-shown', !isPasswordShown);
    inputField.type = isPasswordShown ? 'password' : 'text';
    event.currentTarget.querySelector('.eye-off').classList.toggle('d-none');
    event.currentTarget.querySelector('.eye-on').classList.toggle('d-none');
  };

  return (
    <div className="scrollbar-hidden">
      <main>
        {/* create-pass start */}
        <section className="auth signin-email create-pass">
          <div className="page-title text-center">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          </div>
      
          <div className="heading">
            <h2>Create a New Password</h2>
            <p>Enter your new password</p>
          </div>
      
          <div className="auth-form">
            <form action="#">
              <div className="d-flex flex-column gap-16">
                <div>
                  <label htmlFor="cpass">Password</label>
                  <div className="position-relative">
                    <input type="password" id="cpass" data-pssws-shown="false" placeholder="Enter your password" className="input-psswd input-field d-block" />
                    <button type="button" className="eye-btn" onClick={togglePasswordVisibility}>
                      <span className="eye-off">
                        <img src="/assets/svg/eye-off.svg" alt="Eye Off" />
                      </span>
                      <span className="eye-on d-none">
                        <img src="/assets/svg/eye-on.svg" alt="Eye On" />
                      </span>
                    </button>
                  </div>
                </div>
                <div>
                  <label htmlFor="ccpass">Confirm Password</label>
                  <div className="position-relative">
                    <input type="password" id="ccpass" data-pssws-shown="false" placeholder="Confirm your password" className="input-psswd input-field d-block" />
                    <button type="button" className="eye-btn" onClick={togglePasswordVisibility}>
                      <span className="eye-off">
                        <img src="/assets/svg/eye-off.svg" alt="Eye Off" />
                      </span>
                      <span className="eye-on d-none">
                        <img src="/assets/svg/eye-on.svg" alt="Eye On" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <Link to="/select-language" className="btn-primary">Continue</Link>
            </form>
          </div>
        </section>
        {/* create-pass end */}
      </main>
    </div>
  );
};

export default CreateNewPassword;
