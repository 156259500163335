import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/home.css';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import axios from 'axios';
import arrowLeftBlack from '../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const Hotels = () => {
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    // Fetch hotels from the API
    axios.get('https://esifly.fr/api/products/')
      .then(response => {
        const records = response.data.records;
        const sortedByPrice = records.sort((a, b) => parseFloat(a.fields.price) - parseFloat(b.fields.price));
        setHotels(sortedByPrice.slice(0, 7)); // Set the 7 cheapest hotels
      })
      .catch(error => {
        console.error('Error fetching hotels:', error);
      });

    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

  }, []);

  return (
    <div className="scrollbar-hidden">
      <main className="tour-guide">
        {/* page-title */}
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Petit budget</h3>
        </div>

        <section className="budget px-24 pb-24">
          <ul>
            {hotels.map((hotel, index) => (
              <li key={hotel.id}>
                <Link to={`/vacation-details/${hotel.id}`} className="d-flex align-items-center gap-12">
                  <div className="image shrink-0 overflow-hidden radius-8">
                    <img
                      src={hotel.fields.Photos && hotel.fields.Photos.length > 0 ? hotel.fields.Photos[0].url : 'https://media.smartbox.com/pim/10000021629561219234741.jpg'}
                      alt={hotel.fields.name}
                      className="img-fluid w-100 h-100 object-fit-cover"
                    />
                  </div>
                  <div className="content shrink-0 d-flex align-items-center gap-12 justify-content-between flex-grow">
                    <div>
                      <h4>{hotel.fields.name}</h4>
                      <h5>{`${hotel.fields.passenger_max} passager${hotel.fields.passenger_max > 1 ? 's max.' : ' max.'}`}</h5> {/* This is a placeholder */}
                      <p className="d-flex align-items-center gap-8 location">
                        <img src="/assets/svg/map-marker.svg" alt="icon" />
                        France
                      </p>
                    </div>
                    <p className="price">
                      <span>{`${hotel.fields.price}€`}</span>/Pers.
                    </p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Hotels;
