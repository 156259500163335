import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/datetimepicker.css';
import '../assets/css/booking.css';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination } from 'swiper/modules';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const BookHotel = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#hcindate").datepicker();
    $("#hcoutdate").datepicker();
    $("#checkInDateInput").datepicker();
    $("#checkOutDateInput").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup('.container');
    }


  }, []);

  return (
    <div className="scrollbar-hidden">
      <main className="booking-main book-hotel">
        {/* page-title */}
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Book Hotel</h3>
        </div>

        <div className="details-body">
          {/* customer-info start */}
          <section className="customer-info pb-12">
            <div className="title mb-16">
              <h4>Customer Info</h4>
            </div>
  
            <ul>
              <li className="d-flex align-items-center justify-content-between">
                <p>Name</p>
                <p>Andy Lexian</p>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <p>Email</p>
                <p>example@mail.com</p>
              </li>
            </ul>
          </section>
          {/* customer-info end */}
  
          {/* order-info start */}
          <section className="order-info py-12">
            <div className="title mb-16">
              <h4>Order Info</h4>
            </div>
  
            {/* order item */}
            <div className="item d-flex align-items-center gap-16 w-100">
              <div className="image shrink-0 overflow-hidden radius-8">
                <img src="/assets/images/booking/loc-2.png" alt="Place" className="img-fluid w-100 h-100 object-fit-cover" />
              </div>
  
              <div className="content flex-grow">
                <h4>The Lalit New Delhi</h4>
                <p className="d-flex align-items-center gap-04 location mt-04">
                  <img src="/assets/svg/map-marker.svg" alt="icon" />
                  Uttar Pradesh, India 
                </p>
                <p className="rating d-flex align-items-center gap-04 mt-16">
                  <img src="/assets/svg/star-yellow.svg" alt="icon" />
                  4.4 <span>(41)</span>
                </p>
              </div>
            </div>

            {/* room-type */}
            <div className="room-type mt-16 d-flex align-items-center justify-content-between">
              <p>Type Room</p>
              <p>Presidential Suite</p>
            </div>
          </section>
          {/* order-info end */}
  
          {/* facilities start */}
          <section className="facilities py-12">
            {/* title */}
            <div className="title d-flex align-items-center justify-content-between">
              <h4 className="shrink-0">Common Facilities</h4>
              <button type="button" className="shrink-0 d-inline-block" data-bs-toggle="modal" data-bs-target="#serviceModal">See All</button>
            </div>
  
            <div className="grid gap-24">
              {/* item 1 */}
              <div className="item text-center">
                <div className="icon d-flex align-items-center justify-content-center rounded-full">
                  <img src="/assets/svg/wind.svg" alt="icon" />
                </div>
                <p>Ac</p>
              </div>
  
              {/* item 2 */}
              <div className="item text-center">
                <div className="icon d-flex align-items-center justify-content-center rounded-full">
                  <img src="/assets/svg/building.svg" alt="icon" />
                </div>
                <p>Restaurant</p>
              </div>
  
              {/* item 3 */}
              <div className="item text-center">
                <div className="icon d-flex align-items-center justify-content-center rounded-full">
                  <img src="/assets/svg/water.svg" alt="icon" />
                </div>
                <p>Swimming Pool</p>
              </div>
  
              {/* item 4 */}
              <div className="item text-center">
                <div className="icon d-flex align-items-center justify-content-center rounded-full">
                  <img src="/assets/svg/24-support.svg" alt="icon" />
                </div>
                <p>24-Hours Front Desk</p>
              </div>
            </div>
          </section>
          {/* facilities end */}
  
          {/* search start */}
          <section className="search pt-12">
            {/* title */}
            <div className="title d-flex align-items-center justify-content-between">
              <h4 className="shrink-0">Stay time</h4>
            </div>
  
            <form action="#">
              <div className="grid">
                {/* Check in */}
                <div>
                  <p className="pb-8">Check in</p>
                  <label htmlFor="hcindate" className="w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#checkInModal">
                    <input type="text" id="hcindate" placeholder="Date" className="input-field" />
                    <span className="icon shrink-0">
                      <img src="/assets/svg/calendar.svg" alt="calendar" />
                    </span>
                  </label>
                </div>
  
                {/* Check Out */}
                <div>
                  <p className="pb-8">Check Out</p>
                  <label htmlFor="hcoutdate" className="w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#checkOutModal">
                    <input type="text" id="hcoutdate" placeholder="Date" className="input-field" />
                    <span className="icon shrink-0">
                      <img src="/assets/svg/calendar.svg" alt="calendar" />
                    </span>
                  </label>
                </div>
              </div>
            </form>
          </section>
          {/* search end */}
  
          {/* pay-btn start */}
          <div className="pay-btn mt-64">
            <Link to="/checkout-hotel" className="btn-primary">Continue</Link>
          </div>
        </div>
      </main>

      {/* checkin date-modal start */}
      <div className="modal fade checkInModal dateModal modalBg" id="checkInModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1 className="modal-title" id="checkInModalLabel">Date Check In</h1>
            </div>
            <div className="modal-body">
              <input type="text" id="checkInDateInput" />
              <div className="btns d-flex align-items-center gap-16">
                <button type="button" className="radius-20 w-50 cancel-btn" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                <button type="button" className="radius-20 w-50 apply-btn" data-bs-dismiss="modal" aria-label="Close">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* checkin date-modal end */}

      {/* checkout date-modal start */}
      <div className="modal fade checkOutModal dateModal modalBg" id="checkOutModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1 className="modal-title" id="checkOutModalLabel">Date Check Out</h1>
            </div>
            <div className="modal-body">
              <input type="text" id="checkOutDateInput" />
              <div className="btns d-flex align-items-center gap-16">
                <button type="button" className="radius-20 w-50 cancel-btn" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                <button type="button" className="radius-20 w-50 apply-btn" data-bs-dismiss="modal" aria-label="Close">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* checkout date-modal end */}

      {/* service modal start */}
      <div className="modal fade serviceModal bottomModal modalBg" id="serviceModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="modal-close rounded-full" data-bs-dismiss="modal" aria-label="Close">
                <img src="/assets/svg/close-black.svg" alt="Close" />
              </button>
              <h1 className="modal-title">All Facilities</h1>
            </div>
            <div className="modal-body">
              <div className="facilities">
                <div className="grid gap-24">
                  {/* item 1 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/wind.svg" alt="icon" />
                    </div>
                    <p>Ac</p>
                  </div>
  
                  {/* item 2 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/building.svg" alt="icon" />
                    </div>
                    <p>Restaurant</p>
                  </div>
  
                  {/* item 3 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/water.svg" alt="icon" />
                    </div>
                    <p>Swimming Pool</p>
                  </div>
  
                  {/* item 4 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/24-support.svg" alt="icon" />
                    </div>
                    <p>24-Hours Front Desk</p>
                  </div>
  
                  {/* item 5 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/building.svg" alt="icon" />
                    </div>
                    <p>Modern Room</p>
                  </div>
  
                  {/* item 6 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/24-support.svg" alt="icon" />
                    </div>
                    <p>24-Hours Security</p>
                  </div>
  
                  {/* item 7 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/water.svg" alt="icon" />
                    </div>
                    <p>Beautiful View</p>
                  </div>
  
                  {/* item 8 */}
                  <div className="item text-center">
                    <div className="icon d-flex align-items-center justify-content-center rounded-full">
                      <img src="/assets/svg/wind.svg" alt="icon" />
                    </div>
                    <p>Open Space</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service modal end */}
    </div>
  );
};

export default BookHotel;
