import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/booking.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../assets/svg/arrow-left-black.svg';
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { format } from 'date-fns';
import { AuthContext } from '../AuthProvider';
import Autocomplete from 'react-autocomplete';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

gsap.registerPlugin(Draggable);

const frenchLocale = {
  months: [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ],
  weekDays: [
    {
      name: "Lundi",
      short: "L",
    },
    {
      name: "Mardi",
      short: "M",
    },
    {
      name: "Mercredi",
      short: "M",
    },
    {
      name: "Jeudi",
      short: "J",
    },
    {
      name: "Vendredi",
      short: "V",
    },
    {
      name: "Samedi",
      short: "S",
    },
    {
      name: "Dimanche",
      short: "D",
    },
  ],
  weekStartingIndex: 0,
  getToday: (gregorianTodayObject) => {
    return gregorianTodayObject;
  },
  toNativeDate: (date) => {
    return new Date(date.year, date.month - 1, date.day - 1);
  },
  getMonthLength: (date) => {
    return new Date(date.year, date.month, 0).getDate();
  },
  transformDigit: (digit) => {
    return digit;
  },
  nextMonth: "Mois suivant",
  previousMonth: "Mois précédent",
  openMonthSelector: "Ouvrir le sélecteur de mois",
  openYearSelector: "Ouvrir le sélecteur d'année",
  closeMonthSelector: "Fermer le sélecteur de mois",
  closeYearSelector: "Fermer le sélecteur d'année",
  defaultPlaceholder: "Sélectionner...",
  digitSeparator: ",",
  yearLetterSkip: 0,
  isRtl: false,
};

const CheckoutVacation = () => {
  const { id, location } = useParams();
  const { user } = useContext(AuthContext);
  const [product, setProduct] = useState(null);
  const [productDetails, setProductDetails] = useState({
    categorie: '',
    product_name: '',
    product_price: 0,
    product_departement: '',
    psp: '',
    booked: 0,
    booked_society: '',
    marge: 0
  });
  const [numPeople, setNumPeople] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [selectedDay, setSelectedDay] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  });
  const [selectedTime, setSelectedTime] = useState("12:00");
  const [passengerDetails, setPassengerDetails] = useState([{ nom: '', prenom: '', poids: '' }]);
  const [customerDetails, setCustomerDetails] = useState({
    nom: user?.lastName || '',
    prenom: user?.firstName || '',
    email: user?.email || '',
    pays: '',
    codePostal: '',
    telephone: '',
    adresse: '',
  });
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('paypall');
  const [paypalAvailable, setPaypalAvailable] = useState(!!(window.paypal && window.paypal.Buttons));
  const [isProcessing, setIsProcessing] = useState(false);
  const [promoEntered, setPromoEntered] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://esifly.fr/api/products/?id=${id}`);
        const productData = response.data.fields;
        setProduct(response.data);
        setTotalPrice(parseFloat(response.data.fields.price).toFixed(2));

        setProductDetails({
          categorie: productData.categorie || '',
          product_name: productData.name || '',
          product_price: parseFloat(productData.price) || 0,
          product_departement: productData.product_departement || '',
          psp: productData.psp || '',
          booked: productData.booked || 0,
          booked_society: productData.booked_society || '',
          marge: parseFloat(productData.marge) || 0
        });
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };
    fetchProduct();
    Draggable.create(".draggable");
    if (window.mixitup) {
      window.mixitup('.container');
    }

    const checkPaypalAvailability = setTimeout(() => {
      setPaypalAvailable(!!(window.paypal && window.paypal.Buttons));
    }, 5000);

    return () => clearTimeout(checkPaypalAvailability);
  }, [id]);

  const handleNumPeopleChange = (e) => {
    const value = parseInt(e.target.value);
    setNumPeople(value);
    if (product) {
      const price = parseFloat(product.fields.price);
      setTotalPrice(parseFloat(value * price * (1 - discount / 100)).toFixed(2));
      const newPassengerDetails = Array.from({ length: value }, () => ({ nom: '', prenom: '', poids: '' }));
      setPassengerDetails(newPassengerDetails);
    }
  };

  const handlePassengerDetailsChange = (index, field, value) => {
    const newPassengerDetails = [...passengerDetails];
    newPassengerDetails[index][field] = value;
    setPassengerDetails(newPassengerDetails);
  };

  const handleApplyDate = () => {
    const formattedDate = format(new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day), 'dd/MM/yyyy');
    const formattedDateTime = `${formattedDate} ${selectedTime}`;
    document.getElementById('vdate').value = formattedDateTime;
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  const applyPromoCode = () => {
    const promoCodeValue = promoCode.trim().toUpperCase();

    if (promoCodeValue === "FLY15" && !promoEntered) {
      setPromoEntered(true);
      const discountValue = 15; 
      setDiscount(discountValue);
      setTotalPrice((prevTotal) => parseFloat((prevTotal * (1 - discountValue / 100)).toFixed(2)));
    }
  };

  const handleCustomerDetailsChange = (field, value) => {
    setCustomerDetails({ ...customerDetails, [field]: value });
  };

  const handlePaymentSuccess = async (paymentId) => {
    const payload = {
      customerDetails: {
        nom: customerDetails.nom,
        prenom: customerDetails.prenom,
        email: customerDetails.email,
        adresse: customerDetails.adresse,
        codePostal: customerDetails.codePostal,
        ville: customerDetails.ville,
        pays: customerDetails.pays,
        etat: customerDetails.etat,
        telephone: customerDetails.telephone,
      },
      date: document.getElementById('vdate').value,
      price: totalPrice,
      poids: passengerDetails.reduce((total, p) => total + parseFloat(p.poids || 0), 0),
      coupon_code: promoCode,
      passengers: passengerDetails,
      payment_id: paymentId,
      payment_method: paymentMethod,
      product_details: productDetails
    };

    try {
      const response = await axios.post('https://esifly.fr/api/orders/index.php', payload);
      if (response.data.success) {
        setIsProcessing(false);
        const token = response.data.token;
        navigate('/ticket-detail', { state: { token } });
      } else {
        setIsProcessing(false);
      }
    } catch (error) {
      setIsProcessing(false);
      console.error('Error submitting order:', error);
    }
  };

  const fetchAddressSuggestions = async (input) => {
    if (input.length < 2) return;
    try {
      const response = await axios.get(`https://esifly.fr/cors.php?url=https://api.wingly.io/api/v1/addresses/autocomplete?input=${encodeURIComponent(input)}`);
      setAddressSuggestions(response.data.map((item) => ({
        label: item.name,
        value: item.name,
        place_id: item.place_id,
      })));
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const calculateMarginPrice = () => {
    if (productDetails.marge && numPeople) {
      return (numPeople * productDetails.marge).toFixed(2);
    }
    return 0;
  };

  const calculateOnSiteRemaining = () => {
    if (productDetails.marge && totalPrice && numPeople) {
      const marginTotal = numPeople * productDetails.marge;
      const totalAfterDiscount = totalPrice;
      return (totalAfterDiscount - marginTotal).toFixed(2);
    }
    return 0;
  };

  const calculateMargeToPay = () => {
    if (productDetails.marge && productDetails.product_price && numPeople) {
      const marginTotal = numPeople * productDetails.marge;
      return (marginTotal).toFixed(2);
    }
    return 0;
  };

  const handleStripePaymentClick = async () => {
    const payload = {
      price: paymentMethod === 'stripeOnSite' ? calculateMargeToPay() : totalPrice,
      product_name: paymentMethod === 'stripeOnSite' ? product?.fields.name + " - Restant à payer sur place " + calculateOnSiteRemaining() + "€" : product?.fields.name
    };

    try {
      const response = await axios.post('https://esifly.fr/api/orders/createPayment.php', payload);
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        console.error('Error creating Stripe payment link:', response.data);
      }
    } catch (error) {
      console.error('Error creating Stripe payment link:', error);
    }
  };

  return (
    <div className="scrollbar-hidden">
      <main className="booking-main checkout-vacation">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Billeterie en ligne</h3>
        </div>
        <section className="order-info px-24 pt-12 pb-24">
          <div className="title mb-16">
            <h4>Activité Choisie :</h4>
          </div>
          <div className="item d-flex align-items-center gap-16 w-100">
            <div className="image shrink-0 overflow-hidden radius-8">
              <img src={product?.fields.Photos[0]?.url} alt="Place" className="img-fluid w-100 h-100 object-fit-cover" />
            </div>
            <div className="content flex-grow">
              <h4>{product?.fields.name}</h4>
              <p className="d-flex align-items-center gap-04 location mt-04">
                <img src="/assets/svg/map-marker.svg" alt="icon" />
                {location}
              </p>
              <p className="rating d-flex align-items-center gap-04 mt-16">
                <img src="/assets/svg/star-yellow.svg" alt="icon" />
                4.4 <span>(41)</span>
              </p>
            </div>
          </div>

          <div className="mt-16">
            <h6 className="mb-8">Prénom</h6>
            <input
              type="text"
              value={customerDetails.prenom}
              onChange={(e) => handleCustomerDetailsChange('prenom', e.target.value)}
              placeholder="Prénom"
              className="input-field"
            />
          </div>

          <div className="mt-16">
            <h6 className="mb-8">Nom</h6>
            <input
              type="text"
              value={customerDetails.nom}
              onChange={(e) => handleCustomerDetailsChange('nom', e.target.value)}
              placeholder="Nom"
              className="input-field"
            />
          </div>

          <div className="mt-16">
            <h6 className="mb-8">Email</h6>
            <input
              type="text"
              value={customerDetails.email}
              onChange={(e) => handleCustomerDetailsChange('email', e.target.value)}
              placeholder="Email"
              className="input-field"
            />
          </div>

          <div className="mt-16" style={{ display: 'none' }}>
            <h6 className="mb-8">Pays</h6>
            <input
              type="text"
              value={customerDetails.pays}
              onChange={(e) => handleCustomerDetailsChange('pays', e.target.value)}
              placeholder="Pays"
              className="input-field"
            />
          </div>

          <div className="mt-16" style={{ display: 'none' }}>
            <h6 className="mb-8">Code Postal</h6>
            <input
              type="number"
              value={customerDetails.codePostal}
              onChange={(e) => handleCustomerDetailsChange('codePostal', e.target.value)}
              onInput={(e) => {
                if (e.target.value.length > 5) {
                  e.target.value = e.target.value.slice(0, 5);
                }
              }}
              placeholder="Code Postal"
              className="input-field"
              max="99999"
              maxLength="5"
            />
          </div>

          <div className="mt-16">
            <h6 className="mb-8">Téléphone</h6>
            <input
              type="number"
              value={customerDetails.telephone}
              onChange={(e) => handleCustomerDetailsChange('telephone', e.target.value)}
              onInput={(e) => {
                if (e.target.value.length > 10) {
                  e.target.value = e.target.value.slice(0, 10);
                }
              }}
              placeholder="0612131415"
              className="input-field"
              max="9999999999"
              maxLength="10"
            />
          </div>

          <div className="mt-16">
            <h6 className="mb-8">Adresse</h6>
            <Autocomplete
              getItemValue={(item) => item.value}
              items={addressSuggestions}
              renderItem={(item, isHighlighted) =>
                <div key={item.place_id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                  {item.label}
                </div>
              }
              value={customerDetails.adresse}
              onChange={(e) => {
                handleCustomerDetailsChange('adresse', e.target.value);
                fetchAddressSuggestions(e.target.value);
              }}
              onSelect={(val) => handleCustomerDetailsChange('adresse', val)}
              inputProps={{ placeholder: 'Adresse', className: 'input-field' }}
              wrapperStyle={{ display: 'block' }}
            />
          </div>

          <div className="search mt-16">
            <h6 className="mb-8">Date et Heure</h6>
            <label htmlFor="vdate" className="w-100 d-flex align-items-center gap-8 radius-24" data-bs-toggle="modal" data-bs-target="#visitDateModal">
              <input type="text" id="vdate" placeholder="Date et Heure" className="input-field" />
              <span className="icon shrink-0">
                <img src="/assets/svg/calendar.svg" alt="calendar" />
              </span>
            </label>
          </div>
          <div className="mt-16">
            <h6 className="mb-8">Nombre de passager</h6>
            <input
              type="number"
              value={numPeople}
              onChange={handleNumPeopleChange}
              placeholder="Nombre de passagers"
              className="input-field"
            />
          </div>
          {Array.from({ length: numPeople }).map((_, index) => (
            <div key={index} className="passenger-details mt-16">
              <h6 className="mb-8">Détails du passager {index + 1}</h6>
              <div className="d-flex flex-column gap-16">
                <div className="mt-16">
                  <input
                    type="text"
                    placeholder="Prénom"
                    className="input-field"
                    value={passengerDetails[index]?.prenom}
                    onChange={(e) => handlePassengerDetailsChange(index, 'prenom', e.target.value)}
                  />
                </div>
                <div className="mt-16">
                  <input
                    type="text"
                    placeholder="Nom"
                    className="input-field"
                    value={passengerDetails[index]?.nom}
                    onChange={(e) => handlePassengerDetailsChange(index, 'nom', e.target.value)}
                  />
                </div>
                <div className="mt-16">
                  <input
                    type="number"
                    placeholder="Poids"
                    className="input-field"
                    value={passengerDetails[index]?.poids}
                    onChange={(e) => handlePassengerDetailsChange(index, 'poids', e.target.value)}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="price border-b d-flex align-items-center justify-content-between pt-16 pb-24">
            <p>Prix</p>
            <p><span>{product?.fields.price}€</span> / Personne</p>
          </div>
          <div className="price d-flex align-items-center justify-content-between pt-24">
            <p>Total</p>
            <p><span>{totalPrice}€</span></p>
          </div>
          <div className="promo-code py-24">
            <div className="sub-title mb-16">
              <h4>Code Promo</h4>
            </div>
            <div className="d-flex align-items-center gap-8 input-area">
              <input type="text" value={promoCode} onChange={handlePromoCodeChange} placeholder="Votre code" className="flex-grow" />
              <button type="button" className="shrink-0" onClick={applyPromoCode}>Appliquer</button>
            </div>
          </div>
          {promoCode !== '' && (
            <div className="price border-b pb-24 promo-price d-flex align-items-center justify-content-between">
              <p>Promo</p>
              <p><span>-{(totalPrice * discount / 100).toFixed(2)}€</span></p>
            </div>
          )}
          <div className="price d-flex align-items-center justify-content-between pt-24">
            <p>Total à payer</p>
            <p><span>{parseFloat(totalPrice).toFixed(2)}€</span></p>
          </div>
          
          <div className="payment-method pt-24">
            <div className="sub-title mb-16">
              <h4>Méthode de paiement - <i>livraison immédiate du billet par mail</i></h4>
            </div>
            <ul>
              <li className="mb-16">
                <label htmlFor="paypall" className="pay-card-container d-flex align-items-center gap-20">
                  <img src="/assets/images/booking/paypal.png" alt="Paypal" />
                  <span>Paypal</span>
                  <input
                    type="radio"
                    name="pay-card"
                    id="paypall"
                    checked={paymentMethod === 'paypall'}
                    onChange={() => setPaymentMethod('paypall')}
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
              <li className="mb-16">
                <label htmlFor="stripe" className="pay-card-container d-flex align-items-center gap-20">
                  <img src="/assets/images/booking/stripe.png" alt="Stripe" />
                  <span>Carte bancaire</span>
                  <input
                    type="radio"
                    name="pay-card"
                    id="stripe"
                    checked={paymentMethod === 'stripe'}
                    onChange={() => setPaymentMethod('stripe')}
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
              {productDetails.marge > 0 && (
                <li>
                  <label htmlFor="stripeOnSite" className="pay-card-container d-flex align-items-center gap-20">
                    <img src="/assets/images/booking/stripe.png" alt="Stripe" />
                    <span>Paiement sur place</span>
                    <input
                      type="radio"
                      name="pay-card"
                      id="stripeOnSite"
                      checked={paymentMethod === 'stripeOnSite'}
                      onChange={() => setPaymentMethod('stripeOnSite')}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              )}
            </ul>
          </div>
          {paymentMethod === 'stripeOnSite' && (
          <div className="price d-flex align-items-center justify-content-between pt-24">
            <p>Total à payer aujourd'hui</p>
            <p><span>{calculateMargeToPay()}€</span></p>
          </div>
          )}
          {paymentMethod === 'stripeOnSite' && (
            
            <div className="price d-flex align-items-center justify-content-between pt-24">
              <p>Restant à payer sur place</p>
              <p><span>{calculateOnSiteRemaining()}€</span></p>
            </div>
          )}
          <div className="pay-btn mt-64">
            {paymentMethod === 'stripe' && (
              <button onClick={handleStripePaymentClick} className="stripe-button" disabled={isProcessing}>
                {isProcessing ? 'Traitement en cours...' : `Continuer`}
              </button>
            )}
            {paymentMethod === 'stripeOnSite' && (
              <button onClick={handleStripePaymentClick} className="stripe-button" disabled={isProcessing}>
                {isProcessing ? 'Traitement en cours...' : `Continuer`}
              </button>
            )}
            {paymentMethod === 'paypall' && (
              <PayPalScriptProvider options={{ "client-id": "AbSwLn9JwawtcS3Art-dVWeLRGKHZi6jZWI2tvhRsmTpwpbAo0rsukImr-NawDZdEJmvDoBEq90w4_X3", components: "buttons" }}>
                {paypalAvailable && window.paypal && window.paypal.Buttons && (
                  <PayPalPayment totalPrice={totalPrice} handlePaymentSuccess={handlePaymentSuccess} />
                )}
                {!paypalAvailable && <div></div>}
              </PayPalScriptProvider>
            )}
          </div>
        </section>
      </main>
      <div className="modal fade visitDateModal dateModal modalBg" id="visitDateModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1 className="modal-title" id="visitDateModalLabel">Choisissez une date</h1>
            </div>
            <div className="modal-body">
              <Calendar
                value={selectedDay}
                onChange={setSelectedDay}
                locale={frenchLocale}
                calendarClassName="custom-calendar"
                calendarTodayClassName="custom-today-day"
                shouldHighlightWeekends
                colorPrimary="#2196F3"
              />
              <div className="mt-16">
                <h6 className="mb-8">Choisissez l'heure</h6>
                <input
                  type="time"
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  className="input-field"
                />
              </div>
              <div className="btns d-flex align-items-center gap-16 mt-3">
                <button type="button" className="radius-20 w-50 cancel-btn" data-bs-dismiss="modal" aria-label="Close">Annuler</button>
                <button type="button" className="radius-20 w-50 apply-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleApplyDate}>Valider</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade orderSuccessModal modalBg " id="orderSuccessModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src="/assets/images/booking/thumsup.png" alt="" />
              <h3 className="pt-30">Order Successful</h3>
              <p className="pt-8 pb-30">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              <Link to="/ticket-booked" className="btn-primary">Continue</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PayPalPayment = ({ totalPrice, handlePaymentSuccess }) => {
  if (!window.paypal || !window.paypal.Buttons) {
    return <div></div>;
  }
  return (
    <PayPalButtons
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: totalPrice
            },
          }],
        });
      }}
      onApprove={(data, actions) => {
        return actions.order.capture().then(details => {
          handlePaymentSuccess(details.id);
        });
      }}
    />
  );
};

export default CheckoutVacation;
