import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';
import editWhite from '../../assets/svg/edit-white.svg';
import calendarBlue from '../../assets/svg/calendar-blue.svg';
import cameraFillBlack from '../../assets/svg/camera-fill-black.svg';
import fileFillBlack from '../../assets/svg/file-fill-black.svg';
import trashFillRed from '../../assets/svg/trash-fill-red.svg';
import { AuthContext } from "../../AuthProvider";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { format } from 'date-fns';

const frenchLocale = {
  months: [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ],
  weekDays: [
    {
      name: "Lundi",
      short: "L",
    },
    {
      name: "Mardi",
      short: "M",
    },
    {
      name: "Mercredi",
      short: "M",
    },
    {
      name: "Jeudi",
      short: "J",
    },
    {
      name: "Vendredi",
      short: "V",
    },
    {
      name: "Samedi",
      short: "S",
    },
    {
      name: "Dimanche",
      short: "D",
    },
  ],
  weekStartingIndex: 0,
  getToday: (gregorianTodayObject) => {
    return gregorianTodayObject;
  },
  toNativeDate: (date) => {
    return new Date(date.year, date.month - 1, date.day);
  },
  getMonthLength: (date) => {
    return new Date(date.year, date.month, 0).getDate();
  },
  transformDigit: (digit) => {
    return digit;
  },
  nextMonth: "Mois suivant",
  previousMonth: "Mois précédent",
  openMonthSelector: "Ouvrir le sélecteur de mois",
  openYearSelector: "Ouvrir le sélecteur d'année",
  closeMonthSelector: "Fermer le sélecteur de mois",
  closeYearSelector: "Fermer le sélecteur d'année",
  defaultPlaceholder: "Sélectionner...",
  digitSeparator: ",",
  yearLetterSkip: 0,
  isRtl: false,
};

const UserInfo = () => {
  const { user } = useContext(AuthContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState({ day: 1, month: 1, year: 2000 });
  const [gender, setGender] = useState('');
  const [location, setLocation] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = () => {
    fetch(`https://esifly.fr/api/user-info/index.php?email=${user.email}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setFirstName(data.user.first_name);
          setLastName(data.user.last_name);
          setEmail(data.user.email);
          setDateOfBirth(data.user.date_of_birth ? {
            year: new Date(data.user.date_of_birth).getFullYear(),
            month: new Date(data.user.date_of_birth).getMonth() + 1,
            day: new Date(data.user.date_of_birth).getDate()
          } : { year: 2000, month: 1, day: 1 });
          setGender(data.user.gender);
          setLocation(data.user.location);
        }
      })
      .catch(error => {
        console.error('Error fetching user info:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userInfo = {
      firstName,
      lastName,
      email,
      dateOfBirth: format(new Date(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day), 'yyyy-MM-dd'),
      gender,
      location
    };

    fetch('https://esifly.fr/api/user-info/index.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userInfo)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('User info updated successfully');
        navigate('/profile/user-profile');
      } else {
        console.error('Error updating user info:', data.message);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="scrollbar-hidden">
      <main>
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Mon Profil</h3>
        </div>

        <section className="profile-image py-16">
          <div className="image">
            <img src={user.picture ? user.picture : "../../assets/images/profile/avatar.png"} alt="avatar" className="w-100 h-100 object-fit-cover img-fluid rounded-full" />
            <button type="button" className="d-flex align-items-center justify-content-center rounded-full" data-bs-toggle="modal" data-bs-target="#editImageModal">
              <img src={editWhite} alt="icon" />
            </button>
          </div>
        </section>

        <section className="user-info px-24">
          <form onSubmit={handleSubmit}>
            <div className="mb-16">
              <label className="input-label">Prénom</label>
              <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input" />
            </div>
            <div className="mb-16">
              <label className="input-label">Nom</label>
              <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input" />
            </div>
            <div className="mb-16">
              <label className="input-label">E-mail</label>
              <input type="email" placeholder="E-mail" readOnly value={email} onChange={(e) => setEmail(e.target.value)} className="input" />
            </div>
            <div className="mb-16">
              <label className="input-label">Date de Naissance</label>
              <div className="dob position-relative d-flex align-items-center">
                <input type="text" placeholder="Date of Birth" className="input" id="dobdate" value={format(new Date(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day), 'yyyy/MM/dd')} />
                <button type="button" data-bs-toggle="modal" data-bs-target="#dateOfBirthModal">
                  <img src={calendarBlue} alt="icon" />
                </button>
              </div>
            </div>

            <div className="mb-16">
              <label className="input-label">Genre</label>
              <div className="grid">
                <label htmlFor="male" className="custom-check-container gender-container">
                  <input type="radio" name="gender" id="male" value="Male" checked={gender === 'Male'} onChange={() => setGender('Male')} />
                  Homme
                  <span className="checkmark"></span>
                </label>
                <label htmlFor="female" className="custom-check-container gender-container">
                  <input type="radio" name="gender" id="female" value="Female" checked={gender === 'Female'} onChange={() => setGender('Female')} />
                  Femme
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div>
              <label className="input-label">Adresse</label>
              <textarea id="myTextarea" placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} className="input"></textarea>
            </div>

            <div className="save-btn mt-64 px-24 mb-32">
              <button type="submit" className="btn-primary">Sauvegarder</button>
            </div>
          </form>
        </section>
      </main>

      <div className="modal fade dateOfBirthModal dateModal modalBg" id="dateOfBirthModal" tabIndex="-1" aria-labelledby="dateOfBirthModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1 className="modal-title" id="dateOfBirthModalLabel">Date de Naissance</h1>
            </div>
            <div className="modal-body">
              <Calendar
                value={dateOfBirth}
                onChange={setDateOfBirth}
                locale={frenchLocale}
                calendarClassName="custom-calendar"
                calendarTodayClassName="custom-today-day"
                shouldHighlightWeekends
                colorPrimary="#2196F3"
              />
              <div className="btns d-flex align-items-center gap-16 mt-3">
                <button type="button" className="radius-20 w-50 cancel-btn" data-bs-dismiss="modal" aria-label="Close">Annuler</button>
                <button type="button" className="radius-20 w-50 apply-btn" data-bs-dismiss="modal" aria-label="Close">Appliquer</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade editImageModal modalBg" id="editImageModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1>Changer votre photo</h1>
            </div>
            <div className="modal-body">
              <ul>
                <li>
                  <button type="button" className="d-flex align-items-center gap-16 radius-8 action-btn">
                    <img src={cameraFillBlack} alt="icon" className="shrink-0" />
                    Prendre une photo
                  </button>
                </li>
                <li>
                  <label htmlFor="choose-file" className="d-block w-100">
                    <input type="file" id="choose-file" />
                    <span className="d-flex align-items-center gap-16 radius-8 action-btn">
                      <img src={fileFillBlack} alt="icon" className="shrink-0" />
                      Galerie
                    </span>
                  </label>
                </li>
                <li>
                  <button type="button" className="d-flex align-items-center gap-16 radius-8 action-btn delete">
                    <img src={trashFillRed} alt="icon" className="shrink-0" />
                    Supprimer
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
