import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';
import plusOutline from '../../assets/svg/plus-outline.svg';
import visaImg from '../../assets/images/profile/visa.png';
import masterImg from '../../assets/images/profile/master.png';

const UserPayment = () => {
  return (
    <div className="scrollbar-hidden">
      <main className="profile-main user-payment">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">My Payment</h3>
          <Link to="/add-card" className="plus-btn d-flex align-items-center justify-content-center ms-auto rounded-full">
            <img src={plusOutline} alt="icon" />
          </Link>
        </div>

        <section className="payment-method px-24">
          <label htmlFor="visa-card" className="custom-check-container payment-container pt-16">
            <input type="radio" name="payment" id="visa-card" defaultChecked />
            <span className="checkmark"></span>
            <span className="d-flex gap-12">
              <span className="icon shrink-0 rounded-full d-flex align-items-center justify-content-center">
                <img src={visaImg} alt="card" className="img-fluid" />
              </span>
              <span className="d-block text flex-grow pb-16">
                <small className="d-block payment-method-card-title">BCA (Bank Central Asia)</small>
                <small className="d-block pt-04 pb-8 payment-method-card-num">•••• •••• •••• 12345</small>
                <small className="d-block payment-method-card-num">Brooklyn Simmons</small>
              </span>
            </span>
          </label>

          <label htmlFor="master-card" className="custom-check-container payment-container pt-16">
            <input type="radio" name="payment" id="master-card" />
            <span className="checkmark"></span>
            <span className="d-flex gap-12">
              <span className="icon shrink-0 rounded-full d-flex align-items-center justify-content-center">
                <img src={masterImg} alt="card" className="img-fluid" />
              </span>
              <span className="d-block text flex-grow pb-16">
                <small className="d-block payment-method-card-title">BCA (Bank Central Asia)</small>
                <small className="d-block pt-04 pb-8 payment-method-card-num">•••• •••• •••• 12345</small>
                <small className="d-block payment-method-card-num">Brooklyn Simmons</small>
              </span>
            </span>
          </label>
        </section>

        <div className="select-btn bottom-btn px-24 pt-24 pb-36">
          <button type="button" className="btn-primary">Select Payment</button>
        </div>
      </main>
    </div>
  );
};

export default UserPayment;
