import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/details.css';

import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../assets/svg/arrow-left-black.svg';


gsap.registerPlugin(Draggable);

const Review = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup('.container');
    }

  }, []);

  return (
    <div className="scrollbar-hidden">
      <main>
        {/* page-title */}
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Review</h3>
        </div>

        <section className="reviews px-24 pb-24">
          {/* review card 1 */}
          <div className="review-card d-flex gap-16">
            <div className="shrink-0 image overflow-hidden rounded-full">
              <img src="/assets/images/details/avatar-2.png" alt="Avatar" className="img-fluid w-100" />
            </div>

            <div className="flex-grow">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Jhone Kenoady</h4>
                <span className="d-inline-block">23 Nov 2022</span>
              </div>
              <ul className="d-flex align-items-center gap-8">
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
              </ul>
              <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
              <ul className="d-flex flex-wrap gap-8 mt-16">
                <li>
                  <div className="review-image overflow-hidden radius-8">
                    <img src="/assets/images/details/img-1.png" alt="Review" />
                  </div>
                </li>
                <li>
                  <div className="review-image overflow-hidden radius-8">
                    <img src="/assets/images/details/img-2.png" alt="Review" />
                  </div>
                </li>
                <li>
                  <div className="review-image overflow-hidden radius-8">
                    <img src="/assets/images/details/img-3.png" alt="Review" />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* review card 2 */}
          <div className="review-card d-flex gap-16">
            <div className="shrink-0 image overflow-hidden rounded-full">
              <img src="/assets/images/details/avatar-3.png" alt="Avatar" className="img-fluid w-100" />
            </div>

            <div className="flex-grow">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Jhone Kenoady</h4>
                <span className="d-inline-block">23 Nov 2022</span>
              </div>
              <ul className="d-flex align-items-center gap-8">
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
              </ul>
              <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
            </div>
          </div>

          {/* review card 3 */}
          <div className="review-card d-flex gap-16">
            <div className="shrink-0 image overflow-hidden rounded-full">
              <img src="/assets/images/details/avatar-4.png" alt="Avatar" className="img-fluid w-100" />
            </div>

            <div className="flex-grow">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Jhone Kenoady</h4>
                <span className="d-inline-block">23 Nov 2022</span>
              </div>
              <ul className="d-flex align-items-center gap-8">
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
              </ul>
              <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
            </div>
          </div>

          {/* review card 4 */}
          <div className="review-card d-flex gap-16">
            <div className="shrink-0 image overflow-hidden rounded-full">
              <img src="/assets/images/details/avatar-5.png" alt="Avatar" className="img-fluid w-100" />
            </div>

            <div className="flex-grow">
              <div className="d-flex align-items-center justify-content-between">
                <h4>Jhone Kenoady</h4>
                <span className="d-inline-block">23 Nov 2022</span>
              </div>
              <ul className="d-flex align-items-center gap-8">
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
                <li><img src="/assets/svg/star-yellow.svg" alt="icon" /></li>
              </ul>
              <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
              <ul className="d-flex flex-wrap gap-8 mt-16">
                <li>
                  <div className="review-image overflow-hidden radius-8">
                    <img src="/assets/images/details/img-4.png" alt="Review" />
                  </div>
                </li>
                <li>
                  <div className="review-image overflow-hidden radius-8">
                    <img src="/assets/images/details/img-5.png" alt="Review" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Review;
