import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import arrowLeftBlack from "../../assets/svg/arrow-left-black.svg";
import { AuthContext } from "../../AuthProvider";
import { fetchGoogleProfile } from "../../authUtils"; // Adjust the path as needed

gsap.registerPlugin(Draggable);

const SignupEmail = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    $("#datepicker").datepicker();
    Draggable.create(".draggable");

    if (window.mixitup) {
      window.mixitup(".container");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    fetch("https://esifly.fr/api/signup/index.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          login({
            email,
            firstName,
            lastName,
            picture: "https://esifly.fr/avatar.png",
          });
          navigate("/signin-email");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An error occurred during signup");
      });
  };

  const handleGoogleSignup = () => {
    const clientId = "332451636851-d96nu12voutnabb1m9i3nhqt38as2edt.apps.googleusercontent.com";
    const redirectUri = "https://esi-fly.com/oauth-callback"; // Change to your actual redirect URI
    const scope = "profile email";
    const responseType = "token";

    const googleOAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}`;

    // Open a new window for Google OAuth login
    const authWindow = window.open(googleOAuthUrl, "googleOAuth", "width=500,height=600");

    // Listen for messages from the popup window
    window.addEventListener("message", (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { accessToken } = event.data;
      if (accessToken) {
        fetchGoogleProfile(accessToken, login, navigate);
        authWindow.close();
      }
    });
  };

  return (
    <div className="scrollbar-hidden">
      <main>
        <section className="auth signin-email">
          <div className="page-title">
            <Link
              to="/signin-email"
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowLeftBlack} alt="arrow" />
            </Link>
            <h3 className="main-title">S'inscrire</h3>
          </div>

          <div className="heading">
            <h2>Compléter les informations</h2>
            <p>Bienvenue chez ESIFLY</p>
          </div>
          <div className="auth-form">
            <form onSubmit={handleSubmit}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="d-flex flex-column gap-16">
                <div>
                  <label htmlFor="fname">Prénom</label>
                  <input
                    type="text"
                    id="fname"
                    placeholder="Entrer votre prénom"
                    className="input-field d-block"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="lname">Nom</label>
                  <input
                    type="text"
                    id="lname"
                    placeholder="Entrer votre nom"
                    className="input-field d-block"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="remail2">Email</label>
                  <input
                    type="email"
                    id="remail2"
                    placeholder="Entrer votre email"
                    className="input-field d-block"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="rpass">Mot de passe</label>
                  <div className="position-relative">
                    <input
                      type="password"
                      id="rpass"
                      data-pssws-shown="false"
                      placeholder="Entrer votre mot de passe"
                      className="input-psswd input-field d-block"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="button" className="eye-btn">
                      <span className="eye-off">
                        <img src="../../assets/svg/eye-off.svg" alt="Eye Off" />
                      </span>
                      <span className="eye-on d-none">
                        <img src="../../assets/svg/eye-on.svg" alt="Eye Off" />
                      </span>
                    </button>
                  </div>
                </div>
                <div>
                  <label htmlFor="rcpass">Confirmation mot de passe</label>
                  <div className="position-relative">
                    <input
                      type="password"
                      id="rcpass"
                      data-pssws-shown="false"
                      placeholder="Confirmation mot de passe"
                      className="input-psswd input-field d-block"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button type="button" className="eye-btn">
                      <span className="eye-off">
                        <img src="../../assets/svg/eye-off.svg" alt="Eye Off" />
                      </span>
                      <span className="eye-on d-none">
                        <img src="../../assets/svg/eye-on.svg" alt="Eye Off" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn-primary">
                S'inscrire
              </button>
            </form>

            <div className="divider d-flex align-items-center justify-content-center gap-12">
              <span className="d-inline-block"></span>
              <small className="d-inline-block">Ou</small>
              <span className="d-inline-block"></span>
            </div>

            <div className="d-flex flex-column gap-16">
              <button className="social-btn" onClick={handleGoogleSignup}>
                <img src="/assets/svg/icon-google.svg" alt="Google" />
                Continuer avec Google
              </button>
            </div>

            <h6>
              Vous avez déjà un compte? <Link to="/signin-email">Se connecter</Link>
            </h6>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SignupEmail;
