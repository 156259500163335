import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';
import arrowIosBack from '../../assets/svg/arrow-ios-back.svg';
import { AuthContext } from "../../AuthProvider";

const AddAddress = () => {
  const [fullName, setFullName] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const addressData = {
      email: user.email,
      full_name: fullName,
      country,
      city,
      state,
      zip_code: zipCode,
      detail_address: detailAddress
    };

    fetch('https://esifly.fr/api/user-addresses/index.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(addressData)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        navigate('/profile/user-address');
      } else {
        console.error('Error adding address:', data.message);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="scrollbar-hidden">
      <main>
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">New Address</h3>
        </div>

        <section className="add-address p-24 pt-0">
          <form onSubmit={handleSubmit}>
            <div className="mb-24">
              <label>Full Name</label>
              <input type="text" placeholder="Enter Full Name / Home / Office " className="input-field" value={fullName} onChange={(e) => setFullName(e.target.value)} />
            </div>
            <div className="mb-24">
              <label>Country</label>
              <div className="position-relative">
                <img src={arrowIosBack} alt="icon" className="icon" />
                <select className="input-field" value={country} onChange={(e) => setCountry(e.target.value)}>
                  <option value="">Select Country</option>
                  <option value="America">America</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Canada">Canada</option>
                  <option value="India">India</option>
                  <option value="London">London</option>
                </select>
              </div>
            </div>
            <div className="mb-24">
              <label>City</label>
              <div className="position-relative">
                <img src={arrowIosBack} alt="icon" className="icon" />
                <select className="input-field" value={city} onChange={(e) => setCity(e.target.value)}>
                  <option value="">Select City</option>
                  <option value="Dhaka">Dhaka</option>
                  <option value="Los Angeles">Los Angeles</option>
                  <option value="Wichita">Wichita</option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Philadelphia">Philadelphia</option>
                </select>
              </div>
            </div>
            <div className="mb-24">
              <label>State</label>
              <div className="position-relative">
                <img src={arrowIosBack} alt="icon" className="icon" />
                <select className="input-field" value={state} onChange={(e) => setState(e.target.value)}>
                  <option value="">Select State</option>
                  <option value="Montgomery">Montgomery</option>
                  <option value="Anchorage">Anchorage</option>
                  <option value="Chicago">Chicago</option>
                  <option value="Louisville">Louisville</option>
                  <option value="Detroit">Detroit</option>
                </select>
              </div>
            </div>
            <div className="mb-24">
              <label>Zip Code</label>
              <input type="text" placeholder="Enter Zip Code" className="input-field" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
            </div>
            <div className="mb-32">
              <label>Detail Address</label>
              <textarea placeholder="Enter Your Address" className="input-field" value={detailAddress} onChange={(e) => setDetailAddress(e.target.value)}></textarea>
            </div>
            <div>
              <button type="submit" className="btn-primary">Save Address</button>
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};

export default AddAddress;
