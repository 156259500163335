import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "../../assets/css/jquery.datetimepicker.css";
import "../../assets/css/common.css";
import "../../assets/css/animations.css";
import "../../assets/css/welcome.css";
import "../../assets/css/profile.css";
// import $ from 'jquery';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from "../../assets/svg/arrow-left-black.svg";

const LegalPolicy = () => {
  return (
    <div className="scrollbar-hidden">
      <main>
        <div className="page-title">
          <Link
            to={-1}
            className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
          >
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">CGU's & CGV's</h3>
        </div>

        <section className="terms-policies px-24">
          <div className="mb-16">
            <h3 className="mb-8">Achat des billets</h3>
            <p>
              Les billets peuvent être achetés en ligne, par téléphone, ou en
              personne. Ces conditions sont contractées par l’acheteur mais le
              billet peut être transmis à quelqu’un d’autre dans la mesure où
              nous en sommes informés. Nous considérons dès lors que le
              possesseur du billet accepte nos conditions générales de vente et
              conditions générales de transport inscrites au dos du billet.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Achat en ligne</h3>
            <p>
              Les billets peuvent être achetés en ligne sur notre site Internet
              : www.esifly.fr. Afin de passer commande, le souscripteur devra
              remplir le formulaire électronique, puis lire et accepter les
              conditions générales de vente. Le Client pourra choisir de payer
              par carte bancaire, chèque ou virement bancaire. Dans le cas d’un
              règlement par chèque, le Client devra nous faire parvenir son
              règlement par courrier. La commande sera considérée comme valide
              après réception, encaissement et vérification du règlement auprès
              de l’établissement bancaire (délai estimé 10 jours)
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Droit de rétractation</h3>
            <p>
              Conformément à l’article{" "}
              <a href="https://www.legifrance.gouv.fr/affichCodeArticle.do?idArticle=LEGIARTI000032226820&amp;cidTexte=LEGITEXT000006069565&amp;dateTexte=20160701">
                L. 221-28 3° du code de la consommation&nbsp;
              </a>
              :{" "}
              <em>
                « le droit de rétractation ne peut être exercé pour les contrats
                … de fournitures de biens confectionnés selon les spécifications
                du consommateur ou nettement personnalisés »
              </em>
              . Les bons cadeaux étant personnalisés puisque le nom et le prénom
              du bénéficiaire y figurent et qu’un message personnalisé peut y
              être ajouté, le droit de rétractation ne s’applique donc pas.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Prestations et tarifs</h3>
            <p>
              La prestation proposée inclut l’accueil et le transfert des
              passagers, la préparation du vol, un vol libre en ballon à air
              chaud ainsi que le toast de l’aérostier (offert). Nos tarifs
              incluent la TVA ainsi que l’assurance responsabilité civile
              transport. Nos tarifs peuvent être modifiés et mis à jour sur
              notre site Internet à tout moment et sans préavis.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Paiement</h3>
            <p>
              Le paiement s’effectue en ligne par carte bancaire, paypal ou
              virement bancaire. Pour la clientèle individuelle : le paiement
              total doit avoir été effectué au minimum 10 jours avant la date de
              vol prévue. Pour les groupes (à partir de 6 personnes), la
              réservation pourra faire l’objet d’un versement d’un acompte de
              50%, le solde devant être réglé minimum 10 jours avant le vol. Le
              nombre définitif de convives ou participants arrêté 10 jours avant
              le vol sera facturé dans son intégralité.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Validité du billet</h3>
            <p>
              Sauf indication contraire, nos billets ont une durée de validité
              de 12 mois à compter de leur date d’émission. Passé ce délai, le
              billet expirera de façon automatique et ne pourra en aucun cas
              faire l’objet d’une demande de remboursement.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Billet remboursable</h3>
            <p>
              Cette option ne peut être souscrite séparément de la commande
              initiale du billet. Elle permet -sous réserve que le billet soit
              encore valide, non utilisé et remboursable- d’obtenir sans
              justificatif le remboursement du billet déduction faite des frais
              de gestion (30% du billet).
            </p>
            <p>
              Afin d’obtenir le remboursement du billet, le Client devra nous
              retourner l’original du billet accompagné d’un courrier signé
              attestant ne pas avoir consommé le billet et en demandant le
              remboursement. A réception du courrier, le Client recevra un
              remboursement sous 15 jours ouvrés. Cette option ne s’applique
              plus dans le cas d’une annulation dans les 72 heures précédent une
              réservation pour un vol ou en cas de non-présentation à un
              rendez-vous.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Réservation</h3>
            <p>
              Ce billet vous permet de voler au choix sur l’une des bases
              indiquées sur votre billet. Chaque vol doit être réservé à
              l’avance via notre site internet, application mobile, par
              téléphone ou email. Toutefois, nous pouvons être amenés à ajouter
              ou supprimer des bases d’envol. Nous vous invitons donc à vous
              rendre sur notre site www.esifly.fr pour prendre connaissance des
              bases disponibles lors de votre réservation.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Confirmation du vol</h3>
            <p>
              Les passagers doivent impérativement s’assurer que le vol est
              maintenu en appelant le numéro de réservation indiqué sur le
              billet entre 12 et 24 heures avant le vol afin de se faire
              communiquer le lieu et l’heure précise du rendez-vous. En cas de
              non-confirmation de la part du Client, le billet sera considéré
              comme consommé et le vol ne pourra pas être reporté.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Annulation ou report</h3>
            <p>
              Nous nous réservons le droit de reporter un vol dans les cas
              suivants : mauvaises conditions météorologiques, conditions de
              sécurité insatisfaisantes, nombre de passagers insuffisant. Dans
              l’un ou l’autre de ces cas, une nouvelle date sera fixée d’un
              commun accord entre les parties. En aucun cas, nous ne pourrons
              être tenu responsables des dépenses engagées par les passagers
              pour se rendre sur le lieu de rendez-vous ou pour son hébergement.
              Dans le cas où ESIFLY et / ou ses partenaires sont tenus de se
              rendre sur un site distant (vols groupe), les frais engagés pour
              le transport de tous types d'équipement et de l’équipage ainsi que
              pour la restauration (buffet champetre etc...) ne seront en aucun
              cas remboursés. Au cas où le Client souhaiterait reporter le vol,
              nous lui demandons de nous prévenir au moins 72 heures à l’avance.
              En cas d’annulation dans les 72 heures précédent le vol, le billet
              sera considéré comme consommé.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Non présentation (no show)</h3>
            <p>
              Le Client est invité à respecter scrupuleusement l’horaire de
              rendez-vous qui lui a été communiqué lors de la confirmation du
              vol. Tout retard ou non-présentation du Client sur le lieu de
              rendez-vous à l’heure précise fixée par le coordinateur entrainera
              l’annulation automatique du billet et de la réservation. Le client
              ne pourra alors prétendre à aucun remboursement ni indemnité,
              quelque soit le motif.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Conditions médicales</h3>
            <p>
              Les passagers doivent être en mesure d’enjamber la nacelle par
              leurs propres moyens, de se tenir en position debout pendant au
              moins une 1h30 et de supporter un atterrissage nacelle couchée
              genoux pliés pour les vols en montgolfiere. Nous demandons aux
              personnes âgées de plus de 70 ans ainsi qu’à toute personne
              soufrant d’un problème médical particulier de prendre avis auprès
              de leur médecin, et de demander un certificat médical
              (questionnaire téléchargeable à l’adresse suivante:{" "}
              <a href="https://www.ecologie.gouv.fr/sites/default/files/documents/Formulaire_pour_demande_de_certificat_medical_aptitude.pdf">
                Questionnaire Médicale
              </a>
              :). Sauf dérogation, nous n’acceptons pas de faire voler les
              enfants de moins de 2 ans, les femmes enceintes ou toute personne
              souffrant d’un problème médical sérieux. Tout enfant de moins de
              12 ans devra être accompagné d’un adulte. A tout moment, le pilote
              se réserve le droit de refuser d’embarquer toute personne sous
              influence de drogues ou d’alcool ou souffrant d’une inaptitude
              physique ou mentale contraire à l’activité et qui serait constatée
              sur place. Dans tous les cas, le billet sera considéré comme
              consommé.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Conditions de vol</h3>
            <p>
              Le vol étant soumis aux aléas météorologiques et aérologiques,
              nous ne garantissons aucun circuit, parcours ou survol d’un site
              particulier. Le pilote se réserve le droit de modifier pour des
              raisons de sécurité ou d’agrément du vol, le lieu et l’heure du
              rendez-vous et tout ce qui concerne le vol. Aucune prestation ne
              sera remboursée si le vol a duré au moins 40 minutes. Si la durée
              du vol est inférieure à 40 minutes, le Client pourra prétendre à
              une indemnisation correspondant au prorata de la durée de vol
              non-réalisée.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Règlement à bord</h3>
            <p>
              Pendant le vol, le pilote est seul maître à bord. Il peut décider
              à tout moment d’interrompre le vol si la sécurité du vol est
              compromise. Les passagers devront impérativement respecter les
              consignes énoncées par le pilote, notamment lors des phases de
              décollage et d’atterrissage. Le pilote se réserve par ailleurs le
              droit de débarquer tout passager dont le comportement présenterait
              un danger pour le bon déroulement du vol, et ce sans que ce
              dernier puisse prétendre à aucune indemnisation que ce soit.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Tenue vestimentaire</h3>
            <p>
              Nous vous conseillons d’adopter une tenue confortable ou de
              randonnée (vêtements de préférence en coton ou en matière
              naturelle). Chaussures pratiques (pas de talon aiguille) et
              pantalon sont recommandés pour les dames.Nous recommandons aussi
              le port d’une casquette ou d’un couvre-chef pour les personnes de
              grande taille.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Sous-traitance</h3>
            <p>
              Le vol pourra être opéré par la société ESIFLY ou par un de ses
              Partenaires agréé. Les sociétés Partenaires sont toutes agréées
              par l’Aviation Civile et détentrices d’un Certificat de Transport
              Aérien.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Responsabilité</h3>
            <p>
              Le Client est informé et reconnaît que la société ESIFLY peut
              intervenir en qualité d’apporteur d’affaires pour le compte de
              prestataires partenaires agréés par l’Aviation Civile. Sur
              demande, nous pouvons fournir les coordonnées du prestataire
              partenaire.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">
              Disposition de la loi informatique et libertés
            </h3>
            <p>
              Conformément à la loi relative à l’informatique, aux fichiers et
              aux libertés du 6 janvier 1978, les données à caractère personnel
              relatives aux acheteurs pourront faire l’objet d’un traitement
              automatisé. De même, conformément à la loi du 6 janvier 1978, les
              utilisateurs disposent d’un droit d’accès de rectification et de
              suppression des données les concernant. Les informations
              collectées sont nécessaires au traitement des inscriptions et à
              celui des commandes. Certaines informations sont obligatoires. A
              défaut toute commande sera impossible. Les informations ne seront
              ni revendues ni échangées avec des entreprises tiers.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Assurances</h3>
            <p>
              Chaque passager est assuré par le prestataire partenaire au titre
              de la responsabilité civile liée au transport aérien à titre
              onéreux, en conformité avec la réglementation européenne du 30
              avril 2005 qui définit les nouvelles exigences en matière
              d’assurance applicable aux exploitants d’aéronefs. La société
              ESIFLY et ses partenaires dégagent toute responsabilité concernant
              les effets personnels fragiles ou de valeur ainsi que les dommages
              causés aux vêtements. Il est de votre responsabilité de prendre
              une assurance supplémentaire si vous le jugez nécessaire.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">Convention de Varsovie</h3>
            <p>
              Les passagers s’embarquant pour un voyage sont avisés que les
              dispositions du traité connu sous le nom de Convention de Varsovie
              peuvent être applicables à l’ensemble de leur voyage. La
              responsabilité du transporteur vis-à-vis des passagers en cas de
              décès ou de dommages corporels est limitée dans tous les cas à une
              somme équivalant à 100 000DTS avec un maximum de 114 400 euros par
              passager. Une protection complémentaire peut être obtenue en
              contractant une assurance auprès d’une Compagnie d’Assurance. Une
              telle assurance n’est sujette à aucune limitation de la
              responsabilité du transporteur aux termes de la Convention de
              Varsovie. Pour tous renseignements complémentaires veuillez
              consulter votre transporteur ou votre Compagnie d’Assurance.
            </p>
          </div>

          <div className="mb-16">
            <h3 className="mb-8">ESIFLY</h3>
            <h3 className="mb-8"></h3>
            <p><strong>Statut juridique:</strong> SASU (SIRET: 497 933 408)</p>
            <p><strong>Adresse:</strong> 34 avenue des Champs-Elysées, 75008 Paris</p>
            <p><strong>Tel:</strong> +33 (0)1 40 76 58 10</p>
            <p><strong>Mail:</strong> contact@esifly.fr</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LegalPolicy;
