import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import arrowLeftBlack from "../../assets/svg/arrow-left-black.svg";
import { AuthContext } from "../../AuthProvider";
import { fetchGoogleProfile } from "../../authUtils"; // Adjust the path as needed

gsap.registerPlugin(Draggable);

const SigninEmail = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    $("#datepicker").datepicker();
    Draggable.create(".draggable");

    if (window.mixitup) {
      window.mixitup(".container");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }

    fetch("https://esifly.fr/api/signin/index.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          login({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            picture: data.picture,
          });
          navigate("/");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An error occurred during signin");
      });
  };

  const handleGoogleLogin = () => {
    const clientId = "332451636851-d96nu12voutnabb1m9i3nhqt38as2edt.apps.googleusercontent.com";
    const redirectUri = "https://esi-fly.com/oauth-callback"; // Change to your actual redirect URI
    const scope = "profile email";
    const responseType = "token";

    const googleOAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}`;

    // Open a new window for Google OAuth login
    const authWindow = window.open(googleOAuthUrl, "googleOAuth", "width=500,height=600");

    // Listen for messages from the popup window
    window.addEventListener("message", (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { accessToken } = event.data;
      if (accessToken) {
        fetchGoogleProfile(accessToken, login, navigate);
        authWindow.close();
      }
    });
  };

  return (
    <div className="scrollbar-hidden">
      <main>
        <section className="auth signin-email">
          <div className="page-title text-center">
            <Link
              to="/"
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowLeftBlack} alt="arrow" />
            </Link>
            <h3 className="main-title">Se Connecter</h3>
          </div>
          <div className="auth-form">
            <form onSubmit={handleSubmit}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="d-flex flex-column gap-16">
                <div>
                  <label htmlFor="lemail2">Email</label>
                  <input
                    type="email"
                    id="lemail2"
                    placeholder="Entrer votre adresse mail"
                    className="input-field d-block"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="lpass">Mot de passe</label>
                  <div className="position-relative">
                    <input
                      type="password"
                      id="lpass"
                      data-pssws-shown="false"
                      placeholder="Entrer votre mot de passe"
                      className="input-psswd input-field d-block"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="button" className="eye-btn">
                      <span className="eye-off">
                        <img src="/assets/svg/eye-off.svg" alt="Eye Off" />
                      </span>
                      <span className="eye-on d-none">
                        <img src="/assets/svg/eye-on.svg" alt="Eye Off" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-8">
                    <input
                      type="checkbox"
                      id="lremember"
                      className="input-field-checkbox d-block"
                    />
                    <label htmlFor="lremember" className="checkbox-label">
                      Se souvenir de moi
                    </label>
                  </div>
                  <Link
                    to="/forgot-password"
                    className="fs-14 fw-600 forgot-pass d-inline-block"
                  >
                    Mot de passe oublié
                  </Link>
                </div>
              </div>
              <button type="submit" className="btn-primary">
                Se Connecter
              </button>
            </form>

            <div className="divider d-flex align-items-center justify-content-center gap-12">
              <span className="d-inline-block"></span>
              <small className="d-inline-block">Ou</small>
              <span className="d-inline-block"></span>
            </div>

            <div className="d-flex flex-column gap-16">
              <button className="social-btn" onClick={handleGoogleLogin}>
                <img src="/assets/svg/icon-google.svg" alt="Google" />
                Continuer avec Google
              </button>
            </div>

            <h6>
              Vous n'avez pas de compte ?{" "}
              <Link to="/signup-email">S'inscrire</Link>
            </h6>
          </div>
        </section>
      </main>

      <div
        className="modal fade loginSuccessModal modalBg"
        id="loginSuccess"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src="/assets/svg/check-green.svg" alt="Check" />
              <h3>You have logged in successfully</h3>
              <p className="mb-32">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <Link to="/" className="btn-primary">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninEmail;
