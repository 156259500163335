import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/ticket.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import axios from 'axios';
import arrowLeftBlack from '../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const TicketDetail = () => {
  const [ticket, setTicket] = useState(null);
  const location = useLocation();
  const { token } = location.state || {};

  useEffect(() => {
    if (token) {
      axios.post('https://esifly.fr/api/get_ticket_detail/index.php', { token })
        .then(response => {
          if (response.data.success) {
            setTicket(response.data.data);
          } else {
            console.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching ticket detail:', error);
        });
    }

    $("#datepicker").datepicker();
    Draggable.create(".draggable");

    if (window.mixitup) {
      window.mixitup('.container');
    }
  }, [token]);

  const extractPercentage = (couponCode) => {
    if (!couponCode) return null;
    const match = couponCode.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  };

  const calculateOriginalPrice = (price, percentage) => {
    return (price / (1 - (percentage / 100))).toFixed(2);
  };

  const couponPercentage = ticket ? extractPercentage(ticket.coupon_code) : null;
  const originalPrice = couponPercentage ? calculateOriginalPrice(ticket.price, couponPercentage) : ticket ? ticket.price : 0;
  const discount = couponPercentage ? (originalPrice - ticket.price).toFixed(2) : 0;
  const totalPay = ticket ? ticket.price : 0;

  const downloadPDF = async () => {
    if (!ticket) return;

    try {
      const response = await axios.post('http://localhost:3001/generate-pdf', { ticket }, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ticket.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  if (!ticket) {
    return <p>Loading...</p>;
  }

  const productNameParts = ticket.product_name.split('à');
  const productName = productNameParts[0].trim();
  const locationText = productNameParts[1] ? productNameParts[1].trim() : `${ticket.city}, ${ticket.country}`;



  return (
    <div className="scrollbar-hidden">
      <main className="ticket-detail">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Details Billet</h3>
        </div>

        <div className="details-body">
          <div className="invoice-number d-flex align-items-center justify-content-between pb-20">
            <p>{ticket.token}</p>
            <span>{new Date(ticket.date) > new Date() ? "A venir" : "Fini"}</span>
          </div>

          <section className="order-card py-12">
            <div className="item d-flex align-items-center gap-16 w-100">
              <div className="image shrink-0 overflow-hidden radius-8">
                <img src={ticket.product_image} alt="Place" className="img-fluid w-100 h-100 object-fit-cover" />
              </div>

              <div className="content flex-grow">
                <h4>{productName}</h4>
                <p className="d-flex align-items-center gap-04 location mt-04">
                  <img src="/assets/svg/map-marker.svg" alt="icon" />
                  {locationText}
                </p>
                <p className="rating d-flex align-items-center gap-04 mt-16">
                  <img src="/assets/svg/star-yellow.svg" alt="icon" />
                  4.4 <span>(41)</span>
                </p>
              </div>
            </div>
          </section>

          <section className="customer-info py-12">
            <div className="title mb-16">
              <h4>Informations Client</h4>
            </div>

            <ul>
              <li className="d-flex align-items-center justify-content-between">
                <p>Nom</p>
                <p>{ticket.firstname} {ticket.lastname}</p>
              </li>
              <li className="d-flex align-items-center justify-content-between">
                <p>Email</p>
                <p>{ticket.email}</p>
              </li>
              {/* <li className="d-flex align-items-center justify-content-between">
                <p>Payment</p>
                <p>Mastercard</p>
              </li> */}
              <li className="d-flex align-items-center justify-content-between">
                <p>Statut</p>
                <p className={new Date(ticket.date) > new Date() ? "success" : "finished"}>
                  {new Date(ticket.date) > new Date() ? "Success" : "Finished"}
                </p>
              </li>
            </ul>
          </section>

          <div className="price border-t d-flex align-items-center justify-content-between pt-24 pb-12">
            <p>Total</p>
            <p><span>{originalPrice}€</span></p>
          </div>

          <div className="price d-flex align-items-center justify-content-between py-12">
            <p>Code Promo</p>
            <p><span>{ticket.coupon_code || "N/A"}</span></p>
          </div>

          {couponPercentage && (
            <div className="price promo-price border-b pb-24 promo-price d-flex align-items-center justify-content-between pt-12 pb-24">
              <p>Promo</p>
              <p><span>{`- ${discount}€`}</span></p>
            </div>
          )}

          <div className="price d-flex align-items-center justify-content-between pt-24">
            <p>Total Payé</p>
            <p><span>{totalPay}€</span></p>
          </div>

          <div className="download-btn mt-64">
            <button type="button" className="btn-primary" onClick={downloadPDF}>Télécharger PDF</button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TicketDetail;
