import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../../assets/css/jquery.datetimepicker.css';
import '../../assets/css/common.css';
import '../../assets/css/animations.css';
import '../../assets/css/welcome.css';
import '../../assets/css/profile.css';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { gsap } from 'gsap';
// import { Draggable } from 'gsap/Draggable';
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

const AddCard = () => {
  return (
    <div className="scrollbar-hidden">
      <main className="profile-main">
        <div className="page-title">
          <Link to={-1} className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full">
            <img src={arrowLeftBlack} alt="arrow" />
          </Link>
          <h3 className="main-title">Add New Card</h3>
        </div>

        <section className="add-card p-24 pt-0">
          <form action="#">
            <div className="mb-24">
              <label>Card Number</label>
              <input type="text" placeholder="Enter Card Number" className="input-field" />
            </div>
            <div className="mb-24">
              <label>Card Holder Name</label>
              <input type="text" placeholder="Enter Holder Name" className="input-field" />
            </div>
            <div className="grid">
              <div>
                <label>Expired</label>
                <input type="text" placeholder="MM/YY" className="input-field" />
              </div>
              <div>
                <label>CVC Code</label>
                <input type="text" placeholder="CVC" className="input-field" />
              </div>
            </div>
          </form>
        </section>

        <div className="add-card-btn px-24 pt-24 pb-36 bottom-btn">
          <button type="button" className="btn-primary" disabled>Add Card</button>
        </div>
      </main>
    </div>
  );
};

export default AddCard;
