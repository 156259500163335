import React, { useEffect, useContext, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import ProtectedRoute from "./ProtectedRoute";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import BookHotel from "./pages/BookHotel";
import CheckoutHotel from "./pages/CheckoutHotel";
import CheckoutVacation from "./pages/CheckoutVacation";
import Explore from "./pages/Explore";
import Hotels from "./pages/Hotels";
import HotelDetails from "./pages/HotelDetails";
import Review from "./pages/Review";
import SearchResult from "./pages/SearchResult";
import ServiceLocation from "./pages/ServiceLocation";
import TicketBooked from "./pages/TicketBooked";
import TicketDetail from "./pages/TicketDetail";
import TourGuide from "./pages/TourGuide";
import CreateNewPassword from "./pages/auth/CreateNewPassword";
import EnterOtp from "./pages/auth/EnterOtp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import SelectLanguage from "./pages/auth/SelectLanguage";
import Signin from "./pages/auth/Signin";
import SigninEmail from "./pages/auth/SigninEmail";
import Signup from "./pages/auth/Signup";
import SignupEmail from "./pages/auth/SignupEmail";
import AddAddress from "./pages/profile/AddAddress";
import AddCard from "./pages/profile/AddCard";
import ChangePassword from "./pages/profile/ChangePassword";
import ForgotPasswordP from "./pages/profile/ForgotPassword";
import GuideProfile from "./pages/profile/GuideProfile";
import HelpSupport from "./pages/profile/HelpSupport";
import LegalPolicy from "./pages/profile/LegalPolicy";
import Security from "./pages/profile/Security";
import UserAddress from "./pages/profile/UserAddress";
import UserInfo from "./pages/profile/UserInfo";
import UserLanguage from "./pages/profile/UserLanguage";
import UserPayment from "./pages/profile/UserPayment";
import UserProfile from "./pages/profile/UserProfile";
import VacationDetails from "./pages/VacationDetails";
import Wishlist from "./pages/Wishlist";
import Navbar from "./component/Navbar";
import OAuthCallback from "./OAuthCallback";
import { fetchGoogleProfile } from "./authUtils"; // Adjust the path as needed

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const hasInteractedRef = useRef(false);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode === 'enabled') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }

    const checkScreenSizeAndRedirect = () => {
      if (window.innerWidth > 1024 && !hasInteractedRef.current) {
        // Redirect to a new URL
        window.location.href = 'https://esifly.fr';
      }
    };

    const handleInteraction = () => {
      if (!hasInteractedRef.current) {
        hasInteractedRef.current = true;
        checkScreenSizeAndRedirect();
      }
    };

    // Attach event listeners
    window.addEventListener('click', handleInteraction);
    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('keydown', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
      window.addEventListener('touchstart', handleInteraction);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    const modals = document.querySelectorAll(".modal.show");
    modals.forEach((modal) => {
      modal.classList.remove("show");
      modal.setAttribute("aria-hidden", "true");
      modal.setAttribute("style", "display: none");
    });
    const modalBackdrops = document.querySelectorAll(".modal-backdrop");
    modalBackdrops.forEach((backdrop) => backdrop.remove());
  }, [location.pathname]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { accessToken } = event.data;
      if (accessToken) {
        fetchGoogleProfile(accessToken, login, navigate);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [login, navigate]);

  const includedPaths = [
    "/",
    "/explore",
    "/wishlist",
    "/ticket-booked",
    "/profile/user-profile",
  ];
  const shouldShowNavbar = includedPaths.includes(location.pathname);

  return (
    <GoogleOAuthProvider clientId="332451636851-d96nu12voutnabb1m9i3nhqt38as2edt.apps.googleusercontent.com">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book-hotel" element={<BookHotel />} />
        <Route path="/checkout-hotel" element={<CheckoutHotel />} />
        <Route path="/checkout-vacation/:id/:location" element={<CheckoutVacation />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/hotel-details" element={<HotelDetails />} />
        {/* <Route path="/notification" element={<Notification />} /> */}
        <Route path="/review" element={<Review />} />
        <Route path="/search-result" element={<SearchResult />} />
        <Route path="/service-location/:name" element={<ServiceLocation />} />
        <Route
          path="/ticket-booked"
          element={<ProtectedRoute element={<TicketBooked />} />}
        />
        <Route path="/ticket-detail" element={<TicketDetail />} />
        <Route path="/tour-guide" element={<TourGuide />} />
        <Route path="/vacation-details/:id" element={<VacationDetails />} />
        <Route
          path="/vacation-details/:id/:name"
          element={<VacationDetails />}
        />
        <Route
          path="/wishlist"
          element={<ProtectedRoute element={<Wishlist />} />}
        />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/enter-otp" element={<EnterOtp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/select-language" element={<SelectLanguage />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signin-email" element={<SigninEmail />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-email" element={<SignupEmail />} />
        <Route path="/oauth-callback" element={<OAuthCallback />} /> {/* Add this route */}
        {/* <Route path="/chat" element={<Chat />} /> */}
        {/* <Route path="/message" element={<Message />} /> */}
        <Route path="/profile/add-address" element={<AddAddress />} />
        <Route path="/profile/add-card" element={<AddCard />} />
        <Route path="/profile/change-password" element={<ChangePassword />} />
        <Route
          path="/profile/forgot-password"
          element={<ForgotPasswordP />}
        />
        <Route path="/profile/guide-profile" element={<GuideProfile />} />
        <Route path="/profile/help-support" element={<HelpSupport />} />
        <Route path="/profile/legal-policy" element={<LegalPolicy />} />
        {/* <Route path="/profile/notifications" element={<Notifications />} /> */}
        <Route path="/profile/security" element={<Security />} />
        <Route path="/profile/user-address" element={<UserAddress />} />
        <Route path="/profile/user-info" element={<UserInfo />} />
        <Route path="/profile/user-language" element={<UserLanguage />} />
        <Route path="/profile/user-payment" element={<UserPayment />} />
        <Route
          path="/profile/user-profile"
          element={<ProtectedRoute element={<UserProfile />} />}
        />
      </Routes>
      {shouldShowNavbar && <Navbar />}
    </GoogleOAuthProvider>
  );
}

export default App;
