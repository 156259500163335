import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/dialog";
import "../../assets/css/jquery.datetimepicker.css";
import "../../assets/css/common.css";
import "../../assets/css/animations.css";
import "../../assets/css/welcome.css";
import "../../assets/css/auth.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import arrowLeftBlack from '../../assets/svg/arrow-left-black.svg';

gsap.registerPlugin(Draggable);

const ForgotPassword = () => {
  useEffect(() => {
    // Initialisation de jQuery UI datepicker
    $("#datepicker").datepicker();

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Exemple d'initialisation pour MixItUp
    if (window.mixitup) {
      window.mixitup(".container");
    }

  }, []);

  return (
    <div className="scrollbar-hidden">
      <main>
        {/* forgot-pass start */}
        <section className="auth signin-email forgot-pass">
          <div className="page-title">
            <Link
              to={-1}
              className="back-btn back-page-btn d-flex align-items-center justify-content-center rounded-full"
            >
              <img src={arrowLeftBlack} alt="arrow" />
            </Link>
          </div>

          <div className="heading">
            <h2>Forgot Password</h2>
            <p>Recover your account password</p>
          </div>

          <div className="auth-form">
            <form action="#">
              <div>
                <label htmlFor="femail">Email</label>
                <input
                  type="email"
                  id="femail"
                  placeholder="Enter your email"
                  className="input-field"
                />
              </div>
              <Link to="/create-new-password" className="btn-primary">
                Continue
              </Link>
            </form>
          </div>
        </section>
        {/* forgot-pass end */}
      </main>
    </div>
  );
};

export default ForgotPassword;
