import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Range } from "react-range";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../assets/css/jquery.datetimepicker.css";
import "../assets/css/common.css";
import "../assets/css/animations.css";
import "../assets/css/welcome.css";
import "../assets/css/explore.css";
import "../assets/css/transitions.css";

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import mixitup from "mixitup";
// import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
// import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
// import { format } from 'date-fns';

gsap.registerPlugin(Draggable);

// const frenchLocale = {
//   months: [
//     "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
//     "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
//   ],
//   weekDays: [
//     {
//       name: "Lundi",
//       short: "L",
//     },
//     {
//       name: "Mardi",
//       short: "M",
//     },
//     {
//       name: "Mercredi",
//       short: "M",
//     },
//     {
//       name: "Jeudi",
//       short: "J",
//     },
//     {
//       name: "Vendredi",
//       short: "V",
//     },
//     {
//       name: "Samedi",
//       short: "S",
//     },
//     {
//       name: "Dimanche",
//       short: "D",
//     },
//   ],
//   weekStartingIndex: 0,
//   getToday: (gregorianTodayObject) => {
//     return gregorianTodayObject;
//   },
//   toNativeDate: (date) => {
//     return new Date(date.year, date.month - 1, date.day);
//   },
//   getMonthLength: (date) => {
//     return new Date(date.year, date.month, 0).getDate();
//   },
//   transformDigit: (digit) => {
//     return digit;
//   },
//   nextMonth: "Mois suivant",
//   previousMonth: "Mois précédent",
//   openMonthSelector: "Ouvrir le sélecteur de mois",
//   openYearSelector: "Ouvrir le sélecteur d'année",
//   closeMonthSelector: "Fermer le sélecteur de mois",
//   closeYearSelector: "Fermer le sélecteur d'année",
//   defaultPlaceholder: "Sélectionner...",
//   digitSeparator: ",",
//   yearLetterSkip: 0,
//   isRtl: false,
// };

// const locationData = [
//   {
//     category: "Avions",
//     name: "LOGNES / Emerainville",
//     latitude: 48.8230555555556,
//     longitude: 2.62388888888889,
//     clubs: [
//       {
//         name: "Plane Air Club",
//         address: "Aérodrome de Lognes-Emerainville, 77185 LOGNES",
//         price1: 308,
//         price2: 600,
//         link: "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=04163",
//         phone: "01.60.05.44.36"
//       }
//     ]
//   },
//   {
//     category: "helicoptere",
//     name: "LE PLESSIS-BELLEVILLE",
//     latitude: 49.11,
//     longitude: 2.73805555555556,
//     clubs: [
//       {
//         name: "Aéroclub Air France Nord",
//         address: "Aérodrome du Plessis Belleville, 60950 ERMENONVILLE",
//         price1: 275,
//         price2: 534,
//         link: "https://www.enviedepiloter.fr/commander-un-passeport/?code_fna=03035",
//         phone: "03.44.60.53.49"
//       }
//     ]
//   }
// ];

// const regionPostalCodes = {
//   "Île-de-France": ["75", "77", "78", "91", "92", "93", "94", "95"],
//   // Add other regions and their respective postal code prefixes
// };

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Explore = () => {
  const location = useLocation();
  const query = useQuery();
  const queryCategoryRaw = query.get("category");
  const queryCategory = queryCategoryRaw
    ? decodeURIComponent(queryCategoryRaw)
    : "";
  const stateCategory = location.state?.category
    ? decodeURIComponent(location.state?.category)
    : "";
  let initialCategory = queryCategory || stateCategory || "all";

  // Modifier la catégorie initiale selon les conditions spécifiées
  if (initialCategory === "Sport ") {
    initialCategory += "& Aventure";
  } else if (initialCategory === "Clip ") {
    initialCategory += "& Film";
  }
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(initialCategory);
  const [searchTerm, setSearchTerm] = useState("");
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [passengers, setPassengers] = useState(1);
  const [starRating, setStarRating] = useState(5);
  const nodeRefs = useRef([]);

  useEffect(() => {
    // Fetch all products from the API
    axios
      .get("https://esifly.fr/api/products/")
      .then((response) => {
        const records = response.data.records;
        setProducts(records);
        // Extract unique categories from the products
        const uniqueCategories = [
          ...new Set(records.map((product) => product.fields.category)),
        ];
        setCategories(uniqueCategories);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    // Initialisation de GSAP Draggable
    Draggable.create(".draggable");

    // Initialisation de MixItUp
    if (mixitup) {
      mixitup("#place-cards");
    }
  }, []);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePassengersChange = (e) => {
    setPassengers(e.target.value);
  };

  const handleStarRatingChange = (rating) => {
    setStarRating(rating);
  };

  // Trier les produits par prix croissant
  const sortProductsByPrice = (products) => {
    return products.sort((a, b) => {
      const priceA = parseFloat(a.fields.price);
      const priceB = parseFloat(b.fields.price);
      return priceA - priceB;
    });
  };

  // Filtrer et trier les produits
  const filteredProducts = sortProductsByPrice(
    products.filter((product) => {
      const fields = product.fields;
      const searchText = searchTerm.toLowerCase();
      const price = parseFloat(fields.price);
      return (
        (selectedFilter === "all" || fields.category === selectedFilter) &&
        price >= priceRange[0] &&
        price <= priceRange[1] &&
        fields.passenger_max >= passengers &&
        (fields.category.toLowerCase().includes(searchText) ||
          fields.sub_category.toLowerCase().includes(searchText) ||
          fields.name.toLowerCase().includes(searchText) ||
          fields.name_specific.toLowerCase().includes(searchText) ||
          fields.search_keywords.toLowerCase().includes(searchText) ||
          fields.price.toString().includes(searchText) ||
          fields.label_1.toLowerCase().includes(searchText) ||
          fields.label_2.toLowerCase().includes(searchText) ||
          JSON.parse(fields.departments).some((department) =>
            department.toLowerCase().includes(searchText)
          ))
      );
    })
  );

  return (
    <div className="scrollbar-hidden">
      <main className="explore">
        {/* search start */}
        <section className="search pb-12">
          <form action="#">
            <div className="search pb-24">
              <div className="form-inner w-100 d-flex align-items-center gap-8 radius-24">
                <img
                  src="/assets/svg/search.svg"
                  alt="search"
                  className="shrink-0"
                />
                <input
                  type="search"
                  className="input-search input-field"
                  placeholder="Recherche..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="filter shrink-0">
                  <button
                    type="button"
                    className="d-flex align-items-center"
                    data-bs-toggle="modal"
                    data-bs-target="#filterModal"
                  >
                    <img src="/assets/svg/filter-black.svg" alt="filter" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>

        <section className="all-place py-12">
          <ul className="place-filter-btns d-flex align-items-center tab-list gap-12 w-100 scrollbar-hidden">
            <li className={selectedFilter === "all" ? "active" : ""}>
              <button
                type="button"
                onClick={() => handleFilterClick("all")}
                data-filter="all"
              >
                Tous
              </button>
            </li>
            {categories.map((category) => (
              <li
                key={category}
                className={selectedFilter === category ? "active" : ""}
              >
                <button
                  type="button"
                  onClick={() => handleFilterClick(category)}
                  data-filter={`.${category.toLowerCase()}`}
                >
                  {category}
                </button>
              </li>
            ))}
          </ul>

          <TransitionGroup id="place-cards" className="grid">
            {filteredProducts.map((product, index) => (
              <CSSTransition
                key={product.id}
                timeout={500}
                classNames="product"
                nodeRef={nodeRefs.current[index]}
              >
                <div
                  className={`place-card mix ${product.fields.category.toLowerCase()}`}
                  ref={(el) => (nodeRefs.current[index] = el)}
                >
                  <Link to={`/vacation-details/${product.id}`}>
                    <div className="image position-relative">
                      <img
                        src={
                          product.fields.Photos &&
                          product.fields.Photos.length > 0
                            ? product.fields.Photos[0].url
                            : "/assets/images/default.png"
                        }
                        alt={product.fields.name}
                        className="img-fluid w-100 overflow-hidden radius-8"
                        style={{
                          width: "183px",
                          height: "176px",
                          objectFit: "cover",
                        }}
                      />
                      <span className="d-flex align-items-center justify-content-center rounded-full">
                        <img src="/assets/svg/heart-black.svg" alt="icon" />
                      </span>
                    </div>
                    <div className="content">
                      <h4>{product.fields.name}</h4>
                      <div className="price d-flex align-items-center justify-content-between">
                        <h3>{product.fields.price}€</h3>
                        <p className="d-flex align-items-center gap-04">
                          <img src="/assets/svg/star-yellow.svg" alt="icon" />
                          4.4 <span>(51)</span>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </section>
      </main>

      <div
        className="modal fade filterModal bottomModal"
        id="filterModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="modal-close rounded-full"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/svg/close-black.svg" alt="Close" />
              </button>
              <h1 className="modal-title">Filtres</h1>
            </div>
            <div className="modal-body">
              <div className="pb-12">
                <h4 className="content-title">Prix</h4>
                <div className="filter-range">
                  <Range
                    step={10}
                    min={0}
                    max={10000}
                    values={priceRange}
                    onChange={(values) => setPriceRange(values)}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "6px",
                          background: "#2196F3",
                          margin: "20px 0",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }, index) => (
                      <div
                        {...props}
                        key={index}
                        style={{
                          ...props.style,
                          height: "20px",
                          width: "20px",
                          backgroundColor: "#2196F3",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  />
                  <div className="price-range w-100 d-flex align-items-center justify-content-between">
                    <input
                      type="text"
                      id="amount1"
                      value={priceRange[0]}
                      readOnly
                    />
                    <input
                      type="text"
                      id="amount2"
                      value={priceRange[1]}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="py-12">
                <h4 className="content-title">Nombre de Passagers</h4>
                <input
                  type="number"
                  className="input-field"
                  value={passengers}
                  onChange={handlePassengersChange}
                  min="1"
                  max="100"
                  style={{borderRadius: "24px"}}
                />
              </div>

              <div className="py-12">
                <h4 className="content-title">Note</h4>
                <ul className="star-rating">
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <li key={rating}>
                      <label htmlFor={`star${rating}`} className="filter-label">
                        <input
                          type="radio"
                          name="star"
                          id={`star${rating}`}
                          checked={starRating === rating}
                          onChange={() => handleStarRatingChange(rating)}
                        />
                        {[...Array(rating)].map((_, i) => (
                          <img
                            key={i}
                            src="/assets/svg/star-yellow.svg"
                            alt="star"
                          />
                        ))}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="py-12">
                <button
                  type="button"
                  className="btn-primary apply-filter-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    console.log("Filtres appliqués");
                  }}
                >
                  Appliquer
                </button>
              </div>

              <div className="pt-12">
                <button
                  type="button"
                  className="clear-all-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setPriceRange([0, 10000]);
                    setPassengers(1);
                    setStarRating(5);
                  }}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
