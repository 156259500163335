import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import '../assets/css/jquery.datetimepicker.css';
import '../assets/css/common.css';
import '../assets/css/animations.css';
import '../assets/css/welcome.css';
import '../assets/css/explore.css';
import homeIcon from '../assets/svg/bottom-nav/home.svg';
import homeActiveIcon from '../assets/svg/bottom-nav/home-active.svg';
import categoryIcon from '../assets/svg/bottom-nav/category.svg';
import categoryActiveIcon from '../assets/svg/bottom-nav/category-active.svg';
import ticketIcon from '../assets/svg/bottom-nav/ticket.svg';
import ticketActiveIcon from '../assets/svg/bottom-nav/ticket-active.svg';
import heartIcon from '../assets/svg/bottom-nav/heart.svg';
import heartActiveIcon from '../assets/svg/bottom-nav/heart-active.svg';
import profileIcon from '../assets/svg/bottom-nav/profile.svg';
import profileActiveIcon from '../assets/svg/bottom-nav/profile-active.svg';

const Navbar = () => {
  const location = useLocation();

  return (
    <footer className="bottom-nav">
      <ul className="d-flex align-items-center justify-content-around w-100 h-100">
        <li>
          <Link to="/">
            <img src={location.pathname === "/" ? homeActiveIcon : homeIcon} alt="home" />
          </Link>
        </li>
        <li>
          <Link to="/explore">
            <img src={location.pathname === "/explore" ? categoryActiveIcon : categoryIcon} alt="category" />
          </Link>
        </li>
        <li>
          <Link to="/ticket-booked">
            <img src={location.pathname === "/ticket-booked" ? ticketActiveIcon : ticketIcon} alt="ticket" />
          </Link>
        </li>
        <li>
          <Link to="/wishlist">
            <img src={location.pathname === "/wishlist" ? heartActiveIcon : heartIcon} alt="heart" />
          </Link>
        </li>
        <li>
          <Link to="/profile/user-profile">
            <img src={location.pathname === "/profile/user-profile" ? profileActiveIcon : profileIcon} alt="profile" />
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default Navbar;
